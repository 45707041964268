// Component : Section Partners Intro
.section-partners-intro {
    padding: 80px 0 40px;
    padding-top: 0;
    p {
        font-size: 14px;
        line-height: 2;
        color: #12282d;
    }

    .section__entry {
        margin-bottom: 40px;
    }

    .section__actions {
        text-align: center;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-partners-intro {
        padding: 140px 0 70px;
        padding-top: 0;
        p {
            font-size: 16px;
            line-height: 1.88;
        }

        .section__entry {
            margin-bottom: 80px;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-partners-intro {
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-partners-intro {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-partners-intro {
    }
}
