// Component : section-results
.section-results {
    .intro-small {
        margin: 8px 0 40px;
        width: 100%;
    }

    .section__head {
        margin-bottom: 24px;
    }

    .link-sort {
        position: relative;
        color: #12282d;
        text-decoration: none;
        display: inline-block;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.14;
        padding-right: 30px;
        text-transform: uppercase;

        &:after {
            position: absolute;
            top: 50%;
            right: 0;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-color: #12282d transparent transparent transparent;
            transform: translateY(-50%);
        }
    }

    .section__actions {
        margin: 40px 0 80px;
    }

    &--alt {
        padding-top: 40px;
        .link-sort {
            font-size: 14px;
        }
    }
}

.intro-small {
    min-height: 177px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-size: cover;
    background-position: center center;

    h2 {
        font-size: 28px;
        line-height: 1.18;
        font-weight: 700;
        margin-bottom: 15px;
        color: white;
    }

    p {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
    }

    a{
        display: block;
        width: 100%;
        height: 100%;
        color: white;
    }

    .intro__content {
        position: relative;
        width: 70%;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        padding: 0 10px;
        margin: 0 auto;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-results {
        &--alt {
            padding-top: 0px;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-results {
        .intro-small {
            margin: 48px 0 80px;
        }

        .link-sort {
            font-size: 14px;
        }

        &--alt {
            .section__actions {
                margin-bottom: 140px;
            }
        }
    }

    .intro-small {
        height: 191px;

        h2 {
            font-size: 48px;
            line-height: 1.33;
            margin-bottom: 23px;
        }

        p {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.2;
        }

        .intro__content {
            width: 100%;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-results {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-results {
    }
}
