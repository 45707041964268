// Component : Section News
.section-news {
	overflow: hidden;
	.section__body {
		margin: 0 -10px;
		padding-left: 15px;
	}
	.article-excerpt{
		p:not(.title) {
			font-size: 13px;
			font-weight: 600;
			line-height: 1.54;
		}
	}
}

.is-blog {
	.section-news {
		margin-bottom: 140px;
	}
}

.section-news--latest {
	padding-bottom: 80px;
	.section__head {
		margin: 80px 0 32px;
	}

	.slick-dots {
		button {
			background: #e5e5e5;
			background-clip: content-box;
		}

		.slick-active {
			button {
				background: #8dced9;
				background-clip: content-box;
			}
		}
	}
}

.js-slider-articles {
	padding-bottom: 82px;
	.slick-slide {
		padding: 0!important;
	}

	.slider__slide {
		width: 290px !important;
		max-width: calc(100vw - 70px) !important;
		box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);
		background: #ffffff;
		margin: 0 10px;
	}

	.slick-list {
		overflow: visible;
	}
}

.article-excerpt {
	.article__label{
		height: 24px;
		padding: 5px 10px;
		color: #007286;
		font-size: 11px;
		text-transform: uppercase;
		background: white;
		border-radius: 30px;
		display: inline-block;
		position: absolute;
		top: 16px;
		left: 16px;
	}
	h6, h3, h2, .title {
		font-size: 16px;
		line-height: 1.5;
		color: #12282d;
		font-weight: 700;
		margin: 0 0 14px;
		transition: .4s;

		a {
			display: block;
			color: inherit;
			text-decoration: none;
			transition: color 0.3s;

			&:hover {
				color: #007286;
			}
		}
	}

	h3{
		min-height: 50px;
	}

	p {
		color: #8b9ca0;
		font-size: 13px;
		font-weight: 600;
		line-height: 1.54;
		margin: 0;
		position: relative;

	}

	.article__image {
		position: relative;
		height: 153px;
		overflow: hidden;
		background-size: cover;
		background-position: center;
		&__image{
			position: absolute;
			top: 0;
			background-size: cover;
			background-position: center center;
			transition: .4s;
			height: 100%;
			width: 100%;
			pointer-events: none;
		}
	}

	.article__content {
		padding: 20px 24px 24px;
		min-height: 220px;
	}

	.article__meta {
		display: flex;
		align-items: center;
		margin-bottom: 13px;

		span.line {
			display: block;
			width: 33px;
			height: 1px;
			background: #8b9ca0;
			margin: 0 13px;
			position: relative;
			top: -14px;

		}

		strong {
			color: #8b9ca0;
			font-size: 13px;
			line-height: 1.15;
			font-weight: 600;
			min-height: 43px;
			text-transform: uppercase;
			a{
				color: #8b9ca0;
			}
		}
		a{
			color: #8b9ca0;
		}
	}

	.list-tags-small {
		position: absolute;
		top: 24px;
		left: 28px;
		right: 28px;
		z-index: 9;
	}

	&--with-shadow {
		box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.section-news {
		.section__head {
			margin-bottom: 64px;
		}

		.section__body {
			padding-left: 0;
		}
		.js-slider-articles{
			overflow: visible;
		}
	}
	.js-slider-articles {
		padding-bottom: 64px;
		max-width: 600px;
		margin: 0 auto;

		.slick-slide {
			padding: 0 10px;
		}

		.slider__slide {
			width: 284px !important;
			max-width: 284px !important;
			margin: 0 auto;
			margin: 0 10px;
		}
	}

	.article-excerpt {
		.article__image {
			height: 150px;
		}

		.article__content {
			min-height: 215px;
		}
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.section-news {
		.section__head {
			margin-bottom: 81px;
		}

		.section__body {
			padding-left: 0;
		}

		.slick-dots{
			display: none;
		}
		.article-excerpt {
			h6, h3, h2, .title {

				&:after {
					content: '';
					height: 2px;
					width: 1px;
					background: #FFCA67;
					display: block;
					opacity: 0;
					transition: .4s;
				}
			}
			&:hover {
				.article__image__image {
					transform: scale(1.2);
					transition: .4s;
				}
				h6, h3, h2, .title {

					&:after {
						opacity: 1;
						width: 70px;
						transition: .4s;
					}
				}
			}
		}
	}

	.article-excerpt {
		h6, h3, h2, .title {
			&:after {
				content: '';
				height: 2px;
				width: 1px;
				background: #FFCA67;
				display: block;
				opacity: 0;
				transition: .4s;
			}
		}
		&:hover {
			.article__image__image {
				transform: scale(1.2);
				transition: .4s;
			}
			h6, h3, h2, .title {
				&:after {
					opacity: 1;
					width: 70px;
					transition: .4s;
				}
			}
		}
	}

	.section-news--latest {
		padding-bottom: 140px;
		.section__head {
			margin-bottom: 71px;
		}
	}

	.js-slider-articles {
		max-width: 100%;
		padding-bottom: 80px;

		.slick-slide {
			padding: 0 15px;
		}

		.slider__slide {
			width: 359px !important;
			max-width: 31vw !important;
		}
		/* HTRF - Simple push 6 - muultiple video slider
		.slick-track,
		.slick-list {
			display: flex !important;
			justify-content: center;
		}
		/*.slick-track {
			transform: none !important;
		}*/
		.slick-dots{
			bottom: -40px;
			.catalog-product-view &{
				bottom: 35px;
			}
		}
	}
	.article-excerpt {
		h6, h2, h3, .title {
			margin-bottom: 9px;
			line-height: 1.5;
		}
		.article__image {
			height: 190px;
			a{
				display: block;
				height: 100%;
				width: 100%;
			}
		}
		.article__content {
			padding: 27px 33px 10px;
			min-height: 209px;
		}
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
