// Component : Video Section
.video-section {
    .video-cover{
        position: absolute;
        height: 433px;
        height: 100%;
        top: 0;
        width: 100%;
        z-index: 1;
        background-repeat: no-repeat;
        right: -1px;
        background-size: cover;
        &:hover{
            cursor: pointer;
        }
    }
    .video__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .responsive-embed {
        padding-bottom: 56%;
      }
    }
}
.video-wrapper {
    position: relative;
    height: 0;
    width: 100%;
    padding-top: 56.25%;
    margin-bottom: 35px;
    // overflow: hidden;

    &:before,
    &:after {
        z-index: -1;
        position: absolute;
        content: '';
        bottom: 15px;
        left: 10px;
        width: 50%;
        top: 80%;
        max-width: 300px;
        background: #777;

        box-shadow: 0 15px 10px #777;
        transform: rotate(-3deg);
    }
    &:after {
        transform: rotate(3deg);
        right: 10px;
        left: auto;
    }

    .video,
    .video-iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .btn-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        text-align: center;

        strong,
        span {
            color: #fff;
            display: block;
        }

        strong {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 3px;
        }

        span {
            font-size: 12px;
            line-height: 1.17;
            margin-bottom: 5px;
            max-height: 110px;
            overflow: auto;
        }

        svg {
            width: 26px;
            height: 26px;
        }
    }

    &.show-controls {
        .btn-play {
            opacity: 1;
            visibility: visible;
        }
    }

    &-no-shadow {
        &:before,
        &:after {
            display: none;
        }
    }
}

.video-with-decoration {
    .video-wrapper {
        position: relative;

        .btn-play {
            text-align: center;
            width: 100%;
            max-width: 65%;
        }
    }

    .video-wrapper-decoration {
        position: absolute;
        bottom: -13px;
        display: none;
        pointer-events: none;
        &--left {
            z-index: -1;
            right: 100%;
            margin-right: -30px;
            pointer-events: none;
        }
        &--right {
            z-index: 2;
            left: 100%;
            margin-left: -16px;
            pointer-events: none;
        }

        &--left2 {
            right: 100%;
            margin-right: 7px;

            margin-right: -12px;
            pointer-events: none;
            z-index: 9;
        }
        &--right2 {
            left: 100%;
            margin-left: -28px;
            margin-left: -48px;
            pointer-events: none;
        }

        &--right4 {
            left: 100%;
            margin-left: -168px;
            bottom: -56px;
            pointer-events: none;
        }

        &-size1 {
            pointer-events: none;
            svg {
                width: 54px;
                height: 130px;

                width: 104px;
                height: 180px;

            }
        }
        &-size2 {
            pointer-events: none;
            svg {
                width: 130px;
                height: 181px;
            }
        }
        &-size3 {
            pointer-events: none;
            svg {
                width: 90px;
                height: 129px;
            }
        }

        &-size4 {
            pointer-events: none;
            svg {
                width: 270px;
                height: 396px;
            }
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .video-with-decoration {
        .video-wrapper-decoration {
            display: block;
        }
    }
    .video-wrapper{
        .btn-play{
            span{
                max-height: 150px;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .video-wrapper{
        .btn-play{
            span{
                max-height: 210px;
            }
        }
    }
    .video-with-decoration {
        .video-wrapper-decoration {
            bottom: -42px;

            &--left {
                z-index: -1;
                right: 100%;
                margin-right: -120px;
            }
            &--right {
                z-index: 2;
                left: 100%;
                margin-left: -30px;
            }
            &--left2 {
                right: 100%;
                margin-right: -40px;
                z-index: 9;
            }
            &--right2 {
                left: 100%;
                margin-left: -105px;
            }

            &--right4 {
                left: 100%;
                margin-left: -168px;
                bottom: -56px;
            }

            &-size1 {
                bottom: -152px;
                svg {
                    width: 274px;
                    height: 650px;
                }
            }
            &-size2 {
                svg {
                    width: 296px;
                    height: 435px;
                }
            }
            &-size3 {
                svg {
                    width: 300px;
                    height: 432px;
                }
            }

            &-size4 {
                svg {
                    width: 270px;
                    height: 396px;
                }
            }
        }
    }

    .video-wrapper {
        .btn-play {
            text-align: center;
            width: 100%;

            strong,
            span {
                font-size: 16px !important;
                line-height: 1.5;
                margin-bottom: 0;
            }

            span {
                margin: 5px;
            }

            svg {
                width: 44px;
                height: 44px;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
