// Component : my-component-2
.cp-my-component-2 {
    padding-bottom: 40px;
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .cp-my-component-2 {
        h1 {
            color: purple;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .cp-my-component-2 {
        h1 {
            color: red;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .cp-my-component-2 {
        h1 {
            color: green;
        }
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .cp-my-component-2 {
        h1 {
            color: orange;
        }
    }
}
