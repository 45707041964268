// Component : Chart
.chart-holder {
    padding: 0;
    .chart-canvas {
        width: 600px;
        margin: 0 auto;
    }

    .chart-title {
        color: #8dced9;
        font-weight: 600;
        margin-bottom: 20px;
        font-size: 9px;
    }

    canvas + .chart-title {
        margin-top: 20px;
    }
}
.chart {
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .chart-holder {
        .chart-title {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .chart {
    }

    .chart-holder {
        padding: 0 50px;
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .chart {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .chart {
    }
}
