// Component : section-interested
.section-interested {
    padding-bottom: 40px;
    background: #f5f5f5;
    z-index: 0;

    .section__head {
        margin: 40px 0 32px;
    }

    .section__body {
        margin: 0 -10px;
        padding-left: 15px;
    }
    .slick-dots {
        button {
            background: #e5e5e5;
            background-clip: content-box;
        }

        .slick-active {
            button {
                background: #8dced9;
                background-clip: content-box;
            }
        }
    }
    .article__label{
        height: 24px;
        padding:5px 10px;
        color: #007286;
        font-size: 11px;
        text-transform: uppercase;
        background: white;
        border-radius: 30px;
        display: inline-block;
        position: absolute;
        top: 16px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-interested {
        padding-left: 30px;
        padding-right: 30px;
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-interested {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 86px;

        .slick-dots{
            display: none;
        }
        .section__head {
            margin: 54px 0 40px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-interested {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-interested {
    }
}
