body.is-safari {
    .toolbar {
        &-sort {
            float: right; //cause flex properties won't work on safari even with webkit
        }
    }
} 

.toolbar {
    margin-top: 20px;
    
    &-total {
        font-size: 2.2em;
    }

    &-sort,
    .alphabet,
    .display {
        display: flex;
    }

    &-sort {
        justify-content: flex-end;

        > * {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
    
    .display,
    .alphabet {
        .display-grid,
        .display-list,
        .alphabet-ascending,
        .alphabet-descending {
            .svg-ico-grid,
            .svg-ico-list,
            .svg-ico-sort-ascending,
            .svg-ico-sort-descending {
                width: 25px;
                margin: 0 10px;
                fill: $medium-gray;
            }

            .has-tip {
                cursor: pointer;
                border-bottom: none;
            }

            &:hover {
                cursor: pointer;
            }

            &.is-active {
                .svg-ico-grid,
                .svg-ico-list,
                .svg-ico-sort-ascending,
                .svg-ico-sort-descending {
                    fill: $medium-blue;                    

                    .inner-arrow {
                        fill: $medium-blue;                    
                    }
                }
            }
        }
    }
}