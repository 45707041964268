// Component : section-lab
.section-lab {
    margin-bottom: 80px;

    p {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 40px;
    }

    h4 {
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 24px;
    }

    .gallery {
        margin: 0 -5px 30px;
        display: flex;
        flex-wrap: wrap;
    }
    .gallery__item {
        margin: 0 5px 10px;
        height: 37vw;
        background-size: cover;
    }

    .gallery__item--small {
        width: calc(42% - 10px);
    }

    .gallery__item--large {
        width: calc(58% - 10px);
    }
}

.list-dots-alt {
    margin: 0 0 30px;
    list-style-type: none;

    li {
        position: relative;
        padding-left: 24px;
        color: inherit;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;

        &:before {
            position: absolute;
            top: 6px;
            left: 0;
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #007286;
        }
    }

    li + li {
        margin-top: 20px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-lab {
        .gallery__item {
            height: 30vw;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-lab {
        margin-bottom: 60px;

        p {
            font-size: 16px;
            line-height: 1.88;
            margin-bottom: 60px;
        }

        h4 {
            font-size: 32px;
            line-height: 1.16;
        }

        .section__head {
            margin: 20px 0;
        }

        .gallery {
            margin: 0 -13px 54px;
        }

        .gallery__item {
            height: 374px;
            margin: 0 13px 26px;
        }

        .gallery__item--small {
            width: calc(42% - 26px);
        }

        .gallery__item--large {
            width: calc(58% - 26px);
        }
    }

    .list-dots-alt {
        margin: 0 0 80px;

        li {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.88;

            &:before {
                top: 10px;
                background: #8dced9;
            }
        }

        li + li {
            margin-top: 30px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-lab {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-lab {
    }
}

// 374+574
// 374/948
// 574/948
