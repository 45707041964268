// Component : section-search-location
.section-search-location {
    padding: 80px 0 0;
    margin-bottom: 80px;
    margin-bottom: 60px;

    .section__head {
        margin-bottom: 64px;
    }

    &--spaced-bottom {
        padding-bottom: 30px;
    }
}

.form-search {
    .choice{
        color: #007286;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        font-size: 14px;
        margin-bottom: 30px;
    }
    .reset-map{
        text-align: center;
        color: #007286;
        font-weight: 700;
        font-size: 14px;
        margin: 0 auto;
        &:hover{
            cursor: pointer;
        }
        svg{
            transform: translateY(5px);
            margin-right: 4px;
            path{
                fill: #007286;
            }
        }
    }
    .form__controls {
        position: relative;

        .icon {
            position: absolute;
            top: 50%;
            left: 28px;
            transform: translateY(-50%);
            font-size: 0;
        }
    }
    .search__field {
        border: 2px solid #007286;
        height: 63px;
        padding: 0 10px 0 60px;
        font-weight: 300;
        font-style: normal;
        color: #007286;
        border-radius: 32px;
        margin: 0;
        box-sizing: border-box;
        width: 100%;
        font-size: 20px;
    }

    .search__field::placeholder {
        font-style: italic;
        color: #007286;
    }

    .form__actions,
    .form__controls {
        margin-bottom: 32px;
    }

    .form__actions {
        .btn {
            width: 100%;
        }
    }
}

.amlocator-index-index{
    .amlocator_store_list{
        display: none;
    }
    .amlocator_mapblock{
        width: 60%;
        h2{
            font-size: 22px;
        }
    }
    .amlocator_input{
        margin-top: 0;
    }
    .btn-filter{
        margin-top: 20px;
        width: 100%;
        height: 57px;
    }
    .form__actions{
        button{
            height: 57px;
        }
    }
    #amasty_locator_filter{
        display: block;
        overflow: hidden;
        .attributes{
            display: block;
            flex-wrap: initial;
        }
        .block-content{
            width: 100%;
            display: inline-block;
            .input-box{
                position: relative;
                &:after{
                    position: absolute;
                    top: 50%;
                    right: 30px;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 4px 0 4px;
                    border-color: #12282d transparent transparent transparent;
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    transition: transform .3s;
                }
            }
            .location-attribute,
            .amlocator_input{
                display: inline-block;
                float: left;
                width: 100%;
                margin: 0;
                select{
                  background: #f5f5f5;
                  height: 40px;
                  line-height: 40px;
                  border-radius: 20px;
                  padding: 0 30px 0 14px;
                  margin: 0;
                  min-width: 166px;
                  width: 100%;
                  font-size: 14px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                    border: none;
                }
                button{
                    width: 100%;
                }
            }
            .amlocator_input{
                button{
                    padding: 0px;
                }
            }
            .amlocator_input:last-of-type{
                display: block;
                margin-left: -10px;
                margin-right: -10px;
                margin-top: 20px;
                button{
                    height: 57px;
                    margin: 0;
                    margin: 0 10px;
                    &:first-of-type{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    .breadcrumbs {
        position: absolute;
    }
}


.catalogsearch-result-index{
    .search.results{
        .modes,
        .pages,
        .toolbar-amount{
            display: none;
        }
        .message{
            max-width: 75rem;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 30px;
        }
        .limiter{
            // margin-bottom: 20px;
            max-width: 73rem;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            .label{
                background-color: transparent;
                color: black;
                font-weight: 700;
                font-size: 14px;
                padding: 0;
                text-transform: uppercase;
            }
            .control{
                display: inline-block;
                select{
                    position: relative;
                    border: none;
                    width: 60px;
                    top: -2px;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            .limiter-text{
                white-space: nowrap;
            }
        }
        .toolbar-top{
            position: relative;

            .pagination {
                display: none;
            }
        }
        .toolbar-sorter{
            top: 0;
            right: 0;
            text-transform: uppercase;
            font-size: 14px;
            margin-bottom: 30px;
            label {
                display: inline-block;
                font-weight: 700;
            }
            .select-container{
                position: relative;
                &:after {
                    position: absolute;
                    top: 50%;
                    right: 30px;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 4px 0 4px;
                    border-color: #12282d transparent transparent transparent;
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    transition: transform 0.3s;
                }
                select{
                    position: relative;
                    text-transform: uppercase;
                    font-size: 14px;
                    margin: 0 5px;
                    font-weight: 600;
                    background: #f5f5f5;
                    height: 40px;
                    border-radius: 20px;
                    padding: 0 45px 0 24px;
                    width: auto;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border: none;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            a {
                color: #007286;
                font-weight: 700;
                font-size: 14px;
                display: block;
                margin-top: 10px;
            }
        }
    }
    .toolbar-products{
        // margin-bottom: 140px;
    }
    .toolbar-bottom{
        .modes,
        .toolbar-amount,
        .limiter,
        .toolbar-sorter{
            display: none;
        }
        .pages{
            ul{
                overflow: hidden;
                text-align: center;
                li {
                    list-style-type: none;
                    display: inline-block;
                    padding: 0 5px;
                    &.current {
                        color: #007286;
                        font-size: 20px;
                    }
                    a {
                        color: #B2B2B2;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-search-location {
        margin-bottom: 100px;
    }

    .catalogsearch-result-index{
        .search.results{
            .toolbar-sorter{
                position: absolute;
            }
            .toolbar-top{
                margin-bottom: 80px;

                .pagination {
                    display: none;
                }
            }
        }
    }
    .form-search {
        .form__btn,
        .search__field {
            height: 57px;
            line-height: 57px;
            border-radius: 29px;
        }
        .form__btn {
            padding: 0 15px;
        }
    }
    .amlocator-index-index {
        .btn-filter {
            margin-top: 30px;
            width: auto;
        }
        .amlocator_center{
            display: block;
            width: 100%;
        }
        .amlocator_mapblock {
            width: 100%!important;
        }
        .section-contacts .section__aside {
            width: 100%;
            max-width: 100%;
        }
        #amasty_locator_filter {
            .attributes{
                display: block;
                margin-left: -15px;
                margin-right: -15px;
                padding-bottom: 7px;
            }
            .block-content {
                .location-attribute,
                .amlocator_input {
                    width: 33.3%;
                    padding: 0 10px;
                    select {
                        border-radius: 20px;
                        padding: 0 30px 0 24px;
                        width: 100%;
                    }
                    button{
                        width: auto;
                    }
                }
                .amlocator_input {
                    margin-bottom: 0;
                }
                .amlocator_input:last-of-type {
                    display: block;
                    white-space: nowrap;
                    margin-left: -4px;
                    margin-right: -4px;
                    margin-top: 20px;
                    width: 100%;
                    button{
                        width: 50%;
                        margin: 0;
                        &:first-of-type{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-search-location {
        padding-top: 140px;

        .section__head {
            margin-bottom: 80px;
        }

        &--spaced-bottom {
            padding-bottom: 140px;
        }
    }

    .form-search {
        .form__actions,
        .form__controls {
            margin-bottom: 30px;
            margin-top: 20px;
        }
        .choice{
            margin-bottom: 5px;
        }
    }
    .amlocator-index-index{
        .amlocator_center{
            display: flex;
        }
        .amlocator_mapblock{
            width: 70%!important;
        }
        .section-contacts{
            .section__aside{
                width: 40%;
                max-height: 523px;
                overflow: auto;
            }
        }
        #amasty_locator_filter{
            max-width: 970px;
            margin: 0 auto;
            .attributes{
                display: flex;
            }
            .block-content{
                .location-attribute,
                .amlocator_input {
                    width: 50%;
                    &:last-of-type{
                        margin-top: 0px;
                        width: 100%;
                        transform: translateY(5px);
                    }
                }
              .amlocator_input{
                  &:last-of-type{
                      width: auto;
                      margin-left: 0;
                      margin-right: 9px;
                  }
              }
            }
        }
    }

    .catalogsearch-result-index{
        .search.results{
            .toolbar-sorter{
                position: absolute;
                margin: 0;
                a {
                    margin: 0;
                    display: inline-block;
                }
            }
            .toolbar-top{
                margin-bottom: 50px;
            }
        }
    }

}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-search-location {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-search-location {
    }
}
