.section-team-join.push-descriptive-7{
  background-color: #9F668F;

  .js-slider-articles{
    padding-bottom: 0;

    .slider__slide{
      margin: 0 auto 50px;
    }
  }
  .section__head .section__title{
    color: #12282d;
  }
  .section__foot{

    ul li{

      .btn{
        background-color: #55B7C8;
        color: white;
        &:hover{
          background-color: #0b97af;
        }
      }
    }
  }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
  .section-team-join.push-descriptive-7{

    .js-slider-articles{

      .slider__slide{
        margin: 0 auto;
      }
    }
  }
}


// --DESKTOP
@media screen and #{breakpoint(large up)} {
  .section-team-join.push-descriptive-7 {
    .text-part {
      order: 1;
    }
  }
}