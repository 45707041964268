// Component : slider-articles-featured
.slider-articles-featured {
    position: relative;

    .slider__slide{
        width: calc(100vw - 20px);
        //height: calc(100vh - 50px);
        .article__content{
            .btn{
                margin-top: 40px;
            }
        }
    }
    .slider__actions {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        left: 0;
        padding: 0 45px;
        transform: translateY(-100%);
        transition: top 0.3s;
        bottom: 490px;
        a {
            font-size: 0;
            line-height: 1;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .slider-articles-featured {

        .slider__slide{
            width: auto;
            height: auto;
        }
        .slider__actions {
            display: flex;
            justify-content: space-between;
            width: auto;
            left: 0;
            padding: 0 45px 60px;
            bottom: 30px;

            a + a {
                margin-left: 10px;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .slider-articles-featured {
        .slider__actions {
            padding-left: 45px;
            padding-bottom: 70px;
            bottom: 0;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .slider-articles-featured {
        .slider__actions {
            padding-left: calc(50vw - 37.5rem + 15px);
            padding-bottom: 85px;
        }
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .slider-articles-featured {
    }
}
