.search { // override product page tabs/slider
    .tabs {
        &-panel {
            display: none;

            &.is-active {
                display: block;
            }
        }
    }
}