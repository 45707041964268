.customer-account-forgotpassword,
.customer-account-createpassword{
  .page-title {
    font-size: 32px;
    line-height: 1.16;
    margin: 0 auto;
    max-width: 73.5rem;
    margin-bottom: 30px;
    margin-top: 70px;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    color: $black;
  }
  .form__head{
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .form-contact {
    &.forget {
      .note {
        margin-bottom: 30px;
      }
      .actions-toolbar {
        margin-top: 30px;
        overflow: hidden;
        .primary{
          display: inline-block;
          float: left;
        }
        .secondary{
          float: left;
          line-height: 58px;
          margin-left: 60px;
        }
      }
    }
  }
}
.customer-account-createpassword{
  .page-title{
    margin-top: 0;
    color: white;
    margin: 0;
  }
  .intro{
    overflow: hidden;
  }
}

@media screen and #{breakpoint(large up)} {

  .customer-account-createpassword {
    .page-title {
      font-size: 48px;
    }
  }

}



