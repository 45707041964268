// Component : section documents
.section-documents {
    padding: 50px 0 40px;
    .section__title {
        margin-bottom: 40px;
    }
    .result-grid{
        display: flex;
        flex-wrap: wrap;
        .grid-item {
            width: 100%;
        }
    }
    &.ressources{
        .e-notice__header{
            background-position: right;
        }
        .document{
            display: block;
        }
    }
}

.document-small {
    padding: 16px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;

    .document__content-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 5px;
        color: black;
        a {
            text-decoration: none;
            color: black;
            display: block;
            transition: opacity 0.3s;
            &:hover {
                opacity: 0.7;
            }
        }
    }
    p:not(.document__content-title) {
        color: #8b9ca0;
        font-size: 14px;
        line-height: 1.14;
        margin: 0;
    }

    .document__image {
        position: relative;
        background-color: #8dced9;
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        border-radius: 50%;
        box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 0;
        }
    }

    .document__content {
        padding-left: 24px;
        flex-grow: 1;
    }
}

.result-grid {
    .grid-item {
        width: 100%;
        .document{
            h6{
                text-transform: uppercase;
            }
        }
    }
}

.list-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -10px 32px;
    list-style-type: none;

    li {
        padding: 0 10px;
        margin-bottom: 20px;
    }

    button {
        height: 32px;
        line-height: 32px;
        padding: 0 16px;
        border-radius: 16px;
        background: #f5f5f5;
        font-size: 14px;
        font-weight: 700;
        color: #8dced9;
        border: none;

        &:focus {
            outline: none;
        }

        &.is-checked {
            background-color: #8dced9;
            color: #fff;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-documents {
        &.ressources{
            .e-notice__header {
                background-position: 50%;
            }
        }
    }

    .list-filters {
        display: flex;
        align-items: center;
    }

    .document-small {
        padding: 32px 16px;

        h6 {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }

    .result-grid {
        .grid-item {
            width: 50%;
            padding: 0 12px;
            display: inline-block;
            float: left;
            h6{
                color: black;
                min-height: 48px;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-documents {
        padding: 0px 0 80px;
        .section__title{
            margin-bottom: 70px;
        }
        .result-grid{
            .grid-item {
                width: 50%;
            }
        }
    }
    .result-grid{
        .grid-item{
            h6{
                min-height: 20px;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-documents {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-documents {
    }
}
