.section-product-reader {
    .reader {
        background: #FFF9F2;

        &__inner {
            padding: 5rem 0;
        }

        &__header {
            margin-bottom: 2rem;
        }

        &__footer img {
            margin: 0 auto;
            display: block;
        }

        &__title {
            font-size: 33px;
            font-weight: 800;
            line-height: 50px;
        }
        
        &__title,
        &__description {
            margin-bottom: 1rem;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-product-reader {
        .reader {
            &__header {
                margin-bottom: 0;
            }

            &__footer img {
                margin: 0;
            }
        }
    }
} 