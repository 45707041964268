// Component : ssection-certifications
.section-certifications {
    padding: 40px 0 8px;

    p {
        font-size: 14px;
        line-height: 2;
    }

    .section__title {
        font-size: 18px;
        line-height: 1.67;
        margin: 0 0 16px;
        font-weight: 700;
    }
    .section__title--decoration {
        font-size: 24px;
    }

    .section__head {
        margin-bottom: 40px;
    }

    .section__body {
        .btn-container{
            margin-top: 20px;
        }
    }

    .section__image {
        margin-bottom: 54px;
        img{
            max-width: 80%;
        }
    }

    .section__foot {
        padding-top: 80px;
        h2 {
            font-size: 24px;
            line-height: 1.33;
            font-weight: 700;
            margin-bottom: 40px;
        }
    }

    &--alt {
        padding: 80px 0;
    }
}

.certifications {
    .dropdown-item {
        margin-bottom: 32px;
        &--last {
            margin-bottom: 0;
        }
        img{
            max-width: 115px;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-certifications {
        .section__image {
            img{
                max-width: 50%;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-certifications {
        padding: 80px 0 0;

        p {
            font-size: 16px;
            line-height: 1.88;
        }

        .section__title {
            font-size: 32px;
            line-height: 1.16;
            margin: 0 0 24px;
        }

        .section__title--decoration {
            font-size: 40px;
        }

        .section__head {
            margin-bottom: 51px;
        }

        .section__body {
            .btn-container{
                margin-top: 50px;
            }
        }

        .section__image {
            margin-bottom: 80px;
            img{
                max-width: 50%;
            }
        }

        .section__foot {
            padding-top: 92px;
            h2 {
                font-size: 32px;
                margin-bottom: 64px;
            }
        }

        &--alt {
            padding: 140px 0;
        }
    }

    .certifications {
        .dropdown-item {
            margin-bottom: 48px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-certifications {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-certifications {
    }
}
