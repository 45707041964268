// Component : quotes

.section-quotes {
    margin: 0 0 100px;
    margin-top: 80px;
    .section__actions {
        padding-top: 8px;
    }

    .hide-mobile {
        display: none;

        &.visible {
            display: block;
        }
    }
}
.quotes {
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-quotes{
        margin-top: 140px;
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .quotes {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .quotes {
    }
}
