// Component : section products tabs
.section-products-tabs {
    margin: 0;
    padding-bottom: 40px;
    &.anchor{
        margin-top: 0;
    }
    .tabs{
        .tabs__body{
            position: relative;
            overflow: hidden;
        }
        .tab{
            height: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            &.current{
                overflow: hidden;
                height: auto;
                position: relative;
            }
        }
        .text-block{
            position: relative;
            padding-left: 0;
        }
    }
    table{

    }

    .section__content {
        width: 100%;
    }

    .section__content table {
        width: 100%;
        max-width: 100%;
    }

    table tr th {
        white-space: nowrap;
    }

    .section__content {
        position: static;
    }

    .table__column--persistent-wrap {
        position: relative;
        border: 1px solid #f0f0f0;
    }

    .table__column--persistent {
        // background-color: #ffffff;
        // position: absolute;
        // top: 0;
        // left: 0;
        // display: inline-block;
        // width: auto;
        // z-index: 4;
    tbody{
        tr{
            td{
                display: none;
            }
            th{
                border-right: none;
            }
        }
    }
    }

    /* ------- Presentational Formatting --------- */
 
    .center {
        margin: 0 auto;
        width: 100%;
    }

    table {
        border-collapse: collapse;
    }

    table tr {
        border-bottom: 1px solid #f0f0f0;
    }

    table thead tr {
        border-bottom: 2px solid #f0f0f0;
    }

    table tr td,
    table tr th {
        padding: .5em;
        border: 1px solid #f0f0f0;
    }

    table th {
        text-align: left;
    }
}

.product {
    margin: 40px 0;

    small {
        font-size: 13px;
        line-height: 1.15;
        color: #8b9ca0;
        font-weight: 600;
    }
    h6 {
        font-size: 16px;
        line-height: 1.88;
        font-weight: 700;
        margin: 0 0 3px;
    }
    p {
        font-size: 13px;
        line-height: 1.69;
        margin-bottom: 16px;
    }

    .product__image {
        margin-bottom: 32px;
        text-align: center;
    }
    .product__content {
        padding-bottom: 40px;
    }
    .product__actions {
        text-align: center;
        ul {
            margin: 0;
            list-style-type: none;
        }
        li + li {
            margin-top: 16px;
        }

        .btn {
            min-width: 231px;
        }
    }
}

.list-tags {
    list-style-type: none;

    a {
        display: inline-block;
        height: 48px;
        line-height: 46px;
        border: 1px solid #e5e5e5;
        padding: 0 24px;
        color: #f08576;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        transition: border-color 0.3s, color 0.3s, background-color 0.3s;

        &:hover {
            background: #12282d;
            color: #fff;
            border-color: #12282d;
        }
    }

    li + li {
        margin-top: 16px;
    }
}

.tabs {
    border: none;
    background: none;

    .tabs__head{
        &.sticky {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 5;

            + .tabs__body {
                padding-top: 102px;
            }
        }

        .full-width{
            margin-left: -50vw !important;
            margin-right: -50vw !important;
        }
    }

    .tabs__nav {
        background: #a06590;
        color: #fff;
        height: 67px;
        &.violet{
            background: #a06590;
            li a:hover,
            .current a {
                background: #78446a;
                color: #fff;
            }
        }
        li a:hover,
        .current a {
            background: #78446a;
            color: #fff;
        }

        li {
            text-align: center;
            &.current{
                line-height: 67px;
            }
        }

        a {
            display: inline-block;
            text-align: center;
            height: 67px;
            line-height: 67px;
            font-size: 16px;
            font-weight: 700;
            color: #fff;
            color: #311229;
            padding: 0 20px;
            white-space: nowrap;
            &:focus {
                outline: none;
            }
        }

        // alt color
        &--alt {
            background: #f08576;
            .product-ivd &{
                background: #a06590;
                li a:hover,
                .current a {
                    background: #78446a;
                    color: #fff;
                }
            }
            .product-both &{
                background: #8487C7;
                li a:hover,
                .current a {
                    background: #494a6d;
                    color: #fff;
                }
            }

            li a:hover,
            .current a {
                background: #d97769;
                color: #fff;
            }
        }

        &--yellow {
            background: $grandis;

            li a:hover,
            .current a {
                background: #d5a957;
                color: #fff;
            }
        }
    }

    .tabs__nav-slider {
        position: relative;

        .slick-arrow {
            position: absolute;
            top: 50%;
            font-size: 0;
            line-height: 0;
            text-indent: -4004px;
            transform: translateY(-50%);
            z-index: 2;
            // opacity: 0;
            &.slick-disabled{
                display: none!important;
            }
        }

        .slick-prev {
            left: 0;
            background: url(../images/svg/arrow-left-white.svg) 0 0 no-repeat;
            height: 66px;
            width: 48px;
            background-size: 22px 22px;
            background-position: 50%;
        }
        .slick-next {
            right: 0;
            background: url(../images/svg/arrow-right-white.svg) 0 0 no-repeat;
            height: 66px;
            width: 48px;
            background-size: 22px 22px;
            background-position: 50%;
        }
    }

    .tab {
        visibility: hidden;
        opacity: 1;
        height: 0;
        overflow: hidden;

        &.current {
            visibility: visible;
            opacity: 1;
            overflow: visible;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .product {
        display: flex;
        align-items: flex-start;

        .product__image {
            width: 25%;
            margin-bottom: 0;
        }
        .product__content {
            width: 50%;
            padding: 10px 20px 0;
        }
        .product__actions {
            margin-left: auto;
            width: 25%;

            .btn {
                min-width: 0;
                width: 100%;
            }

            li + li {
                margin-top: 30px;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .tabs {
        .tabs__head{
            &.sticky {
                top: 75px;
            }
        }
    }

    .section-products-tabs {
        .section-documents{
            margin-top: 80px;
        }
    }

    table.table__column--persistent{
        tbody,
        thead{
            border: none;
            tr{
                th{
                    border-right:none;
                }
            }
        }
    }

    .product {
        display: flex;
        align-items: flex-start;
        margin: 80px 0;

        .product__image {
            width: 26%;
            margin-bottom: 0;
        }
        .product__content {
            width: 50%;
            padding: 30px 72px 0;
        }
        .product__actions {
            margin-left: auto;
            width: 19%;

            .btn {
                min-width: 0;
                width: 100%;
            }
        }
    }

    .list-tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -15px;
        a {
            display: block;
            height: 31px;
            line-height: 29px;
            border: 1px solid transparent;
            padding: 0 5px;
            color: #fff;
            font-size: 13px;
            font-weight: 600;
            background: #12282d;
            border-radius: 16px;

            &:hover {
                background: transparent;
                color: #12282d;
                border-color: #12282d;
            }
        }

        li {
            flex-shrink: 0;
            margin: 0 15px 10px;
        }

        li + li {
            margin-top: 0;
        }
    }

    .tabs__nav-slider {
        .slick-track {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100% !important;
            .lookbook &{
                justify-content: center;
            }
        }
        .slick-slide {
            width: auto !important;
        }
    }

    .tabs {
        .tabs__nav {
            a {
                padding: 0 10px;
                min-width: 140px;
                text-align: center;
            }
        }
        .tabs__nav-slider {
            position: relative;
            .slick-arrow {
                display: none!important;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-products-tabs {
    }

    .tabs {
        .tabs__nav {
            a {
                min-width: 185px;
            }
        }
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-products-tabs {
    }
}
