@mixin font($name, $file, $weight, $style) {
	$url: '../fonts/';
	@font-face {
	  font-family: $name;
	  src:
	    url($url + $name + '/' + $file +'.eot?#iefix') format('embedded-opentype'),
	    url($url + $name + '/' + $file +'.woff') format('woff'),
	    url($url + $name + '/' + $file +'.ttf')  format('truetype'),
	    url($url + $name + '/' + $file +'.svg#'+ $name) format('svg');
	  font-weight: $weight;
	  font-style: $style;
	}
}

//@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700');

@include font("Raleway", "Raleway-Regular", 200, "normal");
@include font("Raleway", "Raleway-Medium", 600, "normal");
@include font("Raleway", "Raleway-Bold", 700, "normal");

@include font("OpenSans", "OpenSans-Regular", 600, "normal");
@include font("OpenSans", "OpenSans-Bold", 700, "normal");

@include font("FiraSans", "FiraSans-Regular", 600, "normal");
@include font("FiraSans", "FiraSans-Bold", 700, "normal");




/**
 * @atom Arial
 * @section Fonts
 * @markup
 *  <p style="font-familly: arial">The quick brown fox jumps over the lazy dog</p>
 */
$arial         : 'arial';


//$lato         : 'LatoWf';
