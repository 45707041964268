// Component : Breadcrumbs
.breadcrumbs-holder {
    width: 100%;
    position: absolute;
    top: 0;
}

.breadcrumbs {
    width: 100%;
    position: relative;
    z-index: 2;
    padding-bottom: 0;
    max-width: 75rem;
    margin: 2rem auto 0;
    
    ul,
    .items {
        background: white;
        padding: 1px 16px;
        border-radius: 10px;
        display: inline-block;
    }

    .lookbook &{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding: 24px 0;
    }
    .lookbook.catalog-product-view &{
        position: relative;
        margin: 2rem 0 0;
    }
    .items {
        padding-left: 15px;
    }
}

.breadcrumbs-holder.breadcrumbs-fishpig {
    position: static;

    .breadcrumbs {
        position: absolute;
    }
}

.breadcrumbs {
    padding: 12px 0;

    ul {
        list-style-type: none;
        margin: 0;
        // display: flex;
        // align-items: center;
    }

    a,
    li,
    li:not(:last-child)::after {
        color: $white;
    }

    li:not(:last-child)::after {
        content: none;
    }

    li {
        font-size: 13px;
        text-transform: none;
        margin: 0;
        strong{
            font-size: 13px;
        }
        .catalog-product-view &{
            color: black;
            a,
            &::after{
                color: #8B9CA0;
            }
        }
    }

    li:not(:last-child)::after {
        margin: 0 3px 0 1px;
    }

    span {
        font-weight: 600;
        color: #09292e;
    }

    &--white {
        a,
        span,
        li:not(:last-child)::after {
            color: #fff;
        }
    }

    &--dark {
        .breadcrumbs {
            padding: 24px 0;

            // .items {
            //     padding: 0;
            // }
        }

        a,
        li,
        li:not(:last-child)::after {
            color: $black;
        }

        a,
        span,
        li:not(:last-child)::after {
            color: $black;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .breadcrumbs {
        li:not(:last-child)::after {
            content: '/';
            color: #000000;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .breadcrumbs {
        padding: 24px 0;
        .catalog-product-view & {
            margin-top: 30px;
            padding: 24px 15px;
        }
        .lookbook &{
            padding: 24px 0px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .breadcrumbs {

    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .breadcrumbs {

    }
}
