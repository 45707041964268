// Component : footer
.cp-footer {
	background-color: #f5f5f5;

	.footer__content {
		padding: 23px 22px 36px;
		color: #09292e;
	}

	.footer__content .navigation {
		color: inherit;
		margin-left: 0 !important;
		padding-left: 0 !important;

		ul {
			list-style-type: none;
			display: block;
		}

		ul > li + li {
			margin-left: 0 !important;
		}

		a {
			font-size: 16px;
			font-weight: 600;
			line-height: 36px;
			text-decoration: none;
			color: inherit;
			text-transform: none;
			color: inherit;
			font-size: 16px;
			font-weight: 600;
			line-height: 36px;
			height: auto;
			display: inline-block;
			background-color: transparent !important;
			color: #09292E !important;
			width: auto;

			&:hover {
				opacity: 0.8;
			}
			&.menu-item-active{
				span{
					text-decoration: none;
				}
			}
		}
	}

	h6 {
		font-size: 16px;
		line-height: 36px;
		font-weight: 600;
		margin: 0 0 8px;
	}

	p {
		font-weight: 600;
		font-size: 13px;
		line-height: 1.69;

		&.socials-title {
			font-size: 16px;
			line-height: 36px;
		}
	}
}

.list-contacts {
	list-style-type: none;
	margin: 0;

	a {
		display: flex;
		align-items: center;
		font-size: 13px;
		font-weight: 600;
		line-height: 1.69;
		color: #12282d;

		&:hover {
			opacity: 0.8;
		}
	}

	span {
		display: block;
		width: 28px;
		margin-right: 6px;
		font-size: 0;
		text-align: center;
	}
}

.contacts {
	margin: 16px 0 29px;
	padding: 16px 0;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
}

.socials {
	ul {
		list-style-type: none;
		margin-left: 0;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		border-radius: 50px;
		background: #12282d;
		transition: background 0.3s;

		&:hover {
			background: rgba(18, 40, 45, 0.7);
		}
	}

	li + li {
		margin-left: 0;
	}
}

.subscribe {
	position: relative;
	padding: 49px 42px 68px;
	margin: 0 -0.625rem;

	background: #007286;

	&:before {
		position: absolute;
		top: 0;
		right: 100%;
		height: 100%;
		width: calc(50vw - 36.56rem);
		content: '';
		background: inherit;
	}

	.footer__logo {
		margin-bottom: 21px;
	}
	.newsletter-text{
		color: #fff;
		font-size: 14px;
		margin-bottom: 5px;
		margin-top: 10px;
	}
	.hs-form{
		position: relative;
		p {
			color: #fff;
			font-size: 13px;
			font-weight: 400;
			margin: 0 0 26px;
			text-shadow: none;
		}
		label{
			display: none!important;
		}
		.input{
			width: 100%;
			height: 48px;
			line-height: 48px;
			border-radius: 24px;
			padding: 0 50px 0 25px;
			font-style: normal;
			color: #8b9ca0;
			font-size: 13px;
			margin-bottom: 0;
			background: #ffffff;
			.hs-input{
				border-bottom: none;
				width: 100%;
				padding: 0;
				font-weight: normal;
				color: black;
				font-size: 13px;
				font-style: normal;
				&::placeholder {
					font-style: italic;
					color: #8b9ca0;
					font-weight: 400;
					font-size: 13px;
				}
				&.error{
					&:focus{
						box-shadow: none;
					}
				}
			}
		}
		.hs-submit{
			position: absolute;
			top: 63px;
			right: 10px;
			width: 27px;
			text-align: left;
			color: #09292e;
			font-size: 13px;
			text-transform: uppercase;
			font-weight: 400;
			padding: 0;
			border: none;
			background: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			margin: 0;
			input[type='submit']{
				background-color: transparent;
				width: 20px;
				display: block;
				min-width: 0;
				padding: 0;
				box-shadow: none;
				color: black;
				font-weight: 400;
				text-transform: uppercase;
				font-size: 13px;
				text-shadow: none;
				&:hover{
					background-color: transparent;
					color: black;
					font-weight: 400;
					text-shadow: none;
				}
			}
			.actions{
				margin: 0;
				padding: 0;
			}
		}
	}
	.form__controls {
		position: relative;
		.mage-error{
			color: white;
			position: absolute;
			top: 70px;
			left: 12px;
		}

	}
	.btn-rounded{
		line-height: 42px;
		min-height: 42px;
	}
	.subscribe__field {
		width: 100%;
		height: 47px;
		line-height: 47px;
		border-radius: 24px;
		padding: 0 60px 0 25px;
		font-style: normal;
		color: #8b9ca0;
		font-size: 13px;
		margin-bottom: 0;
		background: #ffffff;
	}

	.subscribe__field::placeholder {
		font-style: italic;
		color: #8b9ca0;
		font-weight: 600;
	}

	.subscribe__btn {
		position: absolute;
		top: 50%;
		right: 10px;
		width: 27px;
		text-align: left;
		transform: translateY(-50%);
		color: #09292e;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 600;
		padding: 0;
		border: none;
		background: none;
		appearance: none;

		&:focus {
			outline: none;
		}
	}
}

//tarteaucitron
html{
	body{
		#tarteaucitronRoot #tarteaucitronAlertBig {
			display: block;
			background: #FFFFFF;

			#tarteaucitronPersonalize2, #tarteaucitronAllDenied2 {
				padding: 11px 20px;
				border-radius: 20px;
			}

		}

		//#tarteaucitronAlertBig::before {
		//	display: none !important;
		//}

		#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
		#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny{
			border-radius: 100px;
			background-color: #4cb7c7!important;
			line-height:28px;
			min-height:28px;
			font-family: "Raleway", sans-serif!important;
			font-size: 15px;
			font-weight: bold;
			margin-bottom: 20px;
			box-sizing: border-box;
		}
		#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny{
			background-color: #e2e2e0!important;
			color: #959595!important;
		}
		#tarteaucitronRoot{

			&.tarteaucitronBeforeVisible {
				&:before {
					background-color: rgba(#000,0.6);
					content: '';
					position: fixed;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: 999;
				}
			}

			div#tarteaucitron{
				top: 50%!important;
				transform: translateY(-50%);
			}
			#tarteaucitronAlertBig.tarteaucitronAlertBigTop{
				background-color: white!important;
				box-shadow: none!important;
				padding: 35px 0;
			}
			#tarteaucitronPrivacyUrl{
				background-color: white!important;
				padding: 5px 15px;
			}
		}
		div#tarteaucitronAlertBig{
			padding-top: 0 !important;
			border-radius: 0 !important;

			&:before {
				content: '' !important;
				width: 100% !important;
				position: relative;
				color: #0f2825;
				background-image: url('../images/svg/cookie-character.svg') !important;
				background-position: 50% !important;
				background-size: 46% !important;
				background-repeat: no-repeat !important;
				background-color: #c9e8ed !important;
				padding-top: 20%;
				display: inline-block;
			}


			#tarteaucitronDisclaimerAlert{
				text-align: left;
				margin: 20px 0 20px !important;

				&:before {
					content: '';
					background-image: url('../images/cookie-banner-title.png');
					display: block;
					margin-bottom: 20px;
					width: auto;
					height: 30px;
					background-position: 50% 0;
					background-size: 30%;
					background-repeat: no-repeat;
				}
			}
			div#tarteaucitronAlertBig,
			span#tarteaucitronDisclaimerAlert{
				background-color: white!important;
				color: #0f2825!important;
				font-size: 18px!important;
				font-family: "raleway", sans-serif!important;
				text-align: center;
			}
			button#tarteaucitronPersonalize,
			button#tarteaucitronCloseAlert {
				border-radius: 100px;
				background-color: #4cb7c7;
				line-height:38px;
				min-height:38px;
				width: 90%;
				font-family: "Raleway", sans-serif!important;
				font-size: 16px;
				font-weight: bold;
				margin-bottom: 20px;
				box-sizing: border-box;
				margin-left: 0;
				padding: 5px 25px !important;
			}
			#tarteaucitronCloseAlert{
				background-color: #e2e2e0!important;
				color: #959595!important;
			}
		}
		#tarteaucitronPrivacyUrl{
			display: block;
			text-align: center!important;
			margin: 0 auto;
		}
	}
}



// --TABLET
@media screen and #{breakpoint(medium up)} {
	html{
		body{
			div#tarteaucitronAlertBig{
				position: relative;
				padding: 50px;
				color: #0f2825;
				background-size: 100%!important;
				div#tarteaucitronAlertBig,
				span#tarteaucitronDisclaimerAlert{
					font-size: 18px!important;
				}

				#tarteaucitronDisclaimerAlert{
					&:before {
						height: 50px;
						background-position: 50%;
						background-size: auto;
					}
				}

				button#tarteaucitronPersonalize,
				button#tarteaucitronCloseAlert{
					line-height: normal;
					min-height: 42px;
					max-width: 280px;
					font-size: 18px;
					width: auto;
					margin-left: 7px;
				}
				#tarteaucitronCloseAlert{
					margin-bottom: 20px;
					font-size: 18px;
				}
			}
		}
	}
	.subscribe {
		padding: 49px 92px 75px;
		margin: 0 -0.9375rem;
		.hs-form{
			p{
				max-width: 240px;
			}
		}
		p {
			margin-bottom: 0;
		}

		.form__controls {
			margin-top: 14px;
		}
		.subscribe__btn {
			width: 46px;
		}
	}

	.cp-footer {
		.footer__content {
			padding: 32px 75px;
		}

		p {
			margin-bottom: 0;
		}
	}

	.contacts {
		margin: 0;
		border: none;
		padding: 0;
	}

	.socials {
		margin-top: 37px;

		ul {
			justify-content: flex-start;
		}

		li + li {
			margin-left: 30px;
		}
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.subscribe {
		padding: 44px 85px 75px 15px;

		p {
			margin: 0 0 26px;
		}

		.footer__logo {
			display: inline-block;
		}

		.subscribe__btn {
			width: 27px;
		}
		.newsletter-text{
			width: 200px;
			margin-bottom: 10px;
		}
	}

	.cp-footer {
		.footer__content {
			padding: 51px 0 0 40px;
			color: #09292e;

			p {
				margin-bottom: 1px;
			}
		}

		h6 {
			margin-bottom: 21px;
		}
	}

	.footer__content .navigation {
		margin-bottom: 16px;
	}

	.socials {
		margin-top: 0;

		li + li {
			margin-left: 25px;
		}
	}

	.list-contacts {
		margin: 0 0 39px;
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {}
