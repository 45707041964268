.table {
	display: table;
	height: 100%;
	width: 100%;
}

.cell {
	display: table-cell;
	vertical-align: middle;
}

.absolute {
	position: absolute;
}

.relative {
	position: relative;
}

.noscroll {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow-y: scroll;
}

.lockscroll {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow-y: auto;
}

.bullet-none {
  li {
    list-style-type: none;
  }
}

.first-on-mobile {
  order: 1;

  @media screen and #{breakpoint(xlarge up)} {
    order: 2;
  }
}

.second-on-mobile {
  order: 2;

  @media screen and #{breakpoint(xlarge up)} {
    order: 1;
  }
}

// RESPONSIVE IFRAME
// use as <div class="16-9"><iframe></iframe></div>
:root {
    --aspect-ratio: 9/16;

    @media screen and #{breakpoint(medium up)} {
        --aspect-ratio: 16/9;        
    }
}
.box-16-by-9 > :first-child {
  width: 100%;
}
.box-16-by-9 > img {  
  height: auto;
} 
@supports (--custom:property) {
  .box-16-by-9 {
    position: relative;
  }
  .box-16-by-9::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }  
  .box-16-by-9 > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }  
}
