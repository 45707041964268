// Component : section-your-projects
.section-your-projects {
    margin: 0 0 100px;
    .article-product ul li {
      margin: 10px;
    }
    .article-product ul li:before {
      display: inline-block;
      background: #8dced9;
    }
    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 2;
        margin-bottom: 18px;
    }

    .section__head {
        margin-bottom: 48px;
    }

    .section__body {
        display: grid;
    }

    .section__group + .section__group {
        margin-top: 48px;
    }

    .section__image {
        margin-bottom: 24px;
        width: 100%;
    }
    h3{
        font-weight: bold;
    }
    .btn-rounded{
        text-align: center;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-your-projects {
        .section__group-alt {
            .row {
                flex-direction: row-reverse;
                justify-content: space-between;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-your-projects {

        p {
            font-size: 16px;
            line-height: 1.88;
        }
        .section__head {
            margin-bottom: 70px;
        }

        .section__group {
            margin: 40px 0;
        }

        .section__image {
            margin-bottom: 0;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-your-projects {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-your-projects {
    }
}
