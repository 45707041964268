// Component : quote
.quote {
	display: block;
	margin-bottom: 56px;
	text-align: center;

	&:hover {
		.quote__author__image img {
			transform: scale(1.1);
			transition: .4s;
		}
	}

	&:focus {
		outline: none;
	}

	blockquote {
		position: relative;
		color: #8b9ca0;
		font-weight: 400;
		font-size: 14px;
		line-height: 1.5;
		text-align: center;
		padding: 45px 0 0;
		margin: 0 0 30px;
		border: none;

		&:before {
			position: absolute;
			top: 0;
			left: 50%;
			width: 25px;
			height: 20px;
			content: '';
			background: url(../images/svg/quote.svg) 0 0 no-repeat;
			background-size: cover;
			transform: translateX(-50%);
		}
	}

	.quote__author__image {
		margin-bottom: 24px;
		overflow: hidden;

		img{
			max-height: 370px;
			object-fit: cover;
			width: 100%;
			transition: .4s;
		}
	}

	.quote__author {
		strong, h2 {
			display: block;
			color: #12282d;
			font-weight: 600;
			font-size: 14px;
			line-height: 1.14;
		}
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.quote {
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.quote {
		margin-bottom: 40px;

		blockquote {
			font-size: 22px;
			line-height: 1.64;
			padding: 51px 30px 0;
			margin: 0 0 40px;

			&:before {
				width: 40px;
				height: 31px;
			}
		}

		.quote__author__image {
			margin-bottom: 32px;
		}

		.quote__author {
			strong, h2 {
				font-size: 16px;
				line-height: 1.36;
			}
		}
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
	.quote {
	}
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
	.quote {
	}
}
