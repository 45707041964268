// Component : section-contact-us
.section-contact-us {
    padding: 80px 0 80px;

    & + .section-contacts {
        padding-top: 0;
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        color: #8b9ca0;
    }

    .section__title {
        font-weight: 700;
        font-size: 24px;
        line-height: 1.33;
        margin-bottom: 16px;
    }

    .section__head {
        margin-bottom: 64px;

        p {
            margin-bottom: 0;
        }
    }

    .callout-box {
        padding: 64px 0.625rem;
        text-align: center;
        margin-left: calc(-8.33333% - 16px);
        margin-right: calc(-8.33333% - 16px);
        margin-bottom: 0;

        .btn {
            padding: 0 10px;
            max-width: 230px;
            width: 100%;
        }
        &+.callout-box{
            margin-top: 40px;
        }
    }

    .form-contact .form__controls {
        margin-bottom: 48px;
        position: relative;
    }
    .form-contact .form__error {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        width: 100%;
    }
}

.contact-index-index{
    .breadcrumbs .item a {
        color: #000;
    }

    .page-bottom{
        overflow: hidden;
        margin-bottom: 80px;
    }
}

.tabs-contacts {
    overflow: hidden;
    .tab {
        display: none;
        padding-top: 64px;

        &.current {
            display: block;
        }

        .stores-infos{
            max-width: 100%;
            margin-top: 60px;
            background-color: #50B7C9;
            .list-contacts-alt{
                li{
                    max-width: 80%;
                    margin: 0 auto;
                }
                a {
                    span{
                        top: 6px;
                    }
                }
            }
            .btn--white{
                color:#50B7C9;
            }
        }
        .section-contacts {
            display: block;
            .callout-box{
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    .tabs__head {
        padding: 44px 0 44px;
        background: #f4f4f4;

        h3 {
            font-size: 18px;
            color: #12282d;
            font-weight: 700;
            line-height: 2;
            margin: 0 0 20px;
        }

        .select-default .select2-container--default .select2-selection--single {
            background: #fff;
        }
    }

    .tabs__nav {
        ul {
            list-style-type: none;
            display: flex;
            align-items: center;
            margin: 0 -20px;
            flex-wrap: wrap;
        }
        li {
            padding: 0 20px 20px;
        }

        .current {
            .btn-inverted--lightgreen {
                background: #8dced9;
                color: #fff;
            }
            .btn-inverted--lightblue {
                background: #50b7c9;
                color: #fff;
            }
            .btn-inverted--blue {
                background: #007286;
                color: #fff;
            }
        }
    }

    .callout-box {
        margin-bottom: 80px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-contact-us {
        .callout-box {
            padding: 64px 0.625rem;
            text-align: center;
            margin: 0;

            .btn {
                max-width: 282px;
            }
            .callout__entry {
                padding-left: 0;
                text-align: center;
            }

            .callout__actions {
                width: 100%;
            }
        }
        .form-contact .form__controls {
            margin-bottom: 64px;
        }
    }

    .tabs-contacts {
        .tabs__head {
            padding: 64px 0 44px;

            h3 {
                font-size: 32px;
                margin: 0 0 32px;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-contact-us {
        padding: 80px 0 140px;

        & + .section-contacts {
            padding-top: 0;
        }

        .section__title {
            font-size: 48px;
            line-height: 1.33;
            margin-bottom: 24px;
        }

        .section__head {
            margin-bottom: 140px;
        }
    }
    .contact-index-index{
        .page-bottom{
            margin-bottom: 140px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-contact-us {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-contact-us {
    }
}
