// Component : section-callout
.section-callout {
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 80px;
    width: 100%;
    max-width: 1170px;
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-callout {
        margin-bottom: 110px;
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-callout {
        margin-bottom: 140px;
        margin-top: 0;
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-callout {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-callout {
    }
}
