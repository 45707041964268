// Component : Section Aplication
.section-application {
    background: #ffca67;
    color: #fff;
    padding: 64px 0;

    p,
    h4 {
        color: inherit;
    }
    h4 {
        font-weight: 700;
        font-size: 22px;
        line-height: 1.36;
        margin-bottom: 24px;
    }

    p {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 40px;
    }

    .btn {
        color: #12282d;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-application {
        padding: 80px 0;
        position: relative;
        color: #12282d;

        h4 {
            font-size: 32px;
            margin-bottom: 20px;
        }

        p {
            font-size: 16px;
            line-height: 1.88;
            margin-bottom: 40px;
        }
        .section__image {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 43%;
            max-width: 685px;
            cursor: default;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-application {
        padding: 80px 0;
        margin-left: -15px;

        .section__image {
            img {
                transform: none;
            }
        }

        h4 {
            font-size: 40px;
            margin-bottom: 32px;
        }

        p {
            margin-bottom: 45px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-application {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-application {
    }
}
