.hero {
    padding-bottom: 40px;

    .breadcrumbs--dark {
        padding-top: 15px;
    }

    &__content {
        .title {
            font-size: 38px;
            line-height: 1.4;
            font-weight: 700;
            margin: 0.3em 0;
        }

        .subtitle {
            font-size: 22px;
            line-height: 1.4;
        }
    }   

    &__media {
        height: 210px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;

        svg {
            display: none;
        }

        .play-icon {
            width: 90px;
            height: 90px;
            background: linear-gradient(120deg,rgba(255,255,255,0) 0%, rgba(255,255,255,.2) 100%);
            box-shadow: 0 24px 72px 0 rgba(0,0,0,.5);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0) scale(1);
            transition: 0.3s transform cubic-bezier(.4,0,.2,1);
            display: flex;
            align-items: center;
            justify-content: center;

            &-inner {
                width: 60px;
                height: 60px;
                background: $white;
                border-radius: 50%;
                transform: scale(1);
                box-shadow: 0 8px 16px 0 rgba(0,0,0,.3);
                transition: 0.3s all cubic-bezier(.4,0,.2,1);

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 53%;
                    transform: translate3d(-53%, -50%, 0) scale(1);
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10px 0 10px 16px;
                    border-color: transparent transparent transparent $black;
                }
            }
        } 
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)}  {
    .hero {
        &__content {
            .title {
                font-size: 48px;
            }
            
            .subtitle {
                font-size: 32px;
            }
        }

        &__media {
            height: 410px;

            .play-icon {
                width: 120px;
                height: 120px;

                &-inner {
                    width: 90px;
                    height: 90px;
                    
                    &:after {
                        border-width: 17px 0 17px 23px;
                        left: 50%;
                        transform: translate3d(-50%, -50%, 0) scale(1);
                    }
                }
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .hero {
        .large-6 {
            &:last-child {
                padding-right: 0;
            }
        }

        &__media {
            height: 90%;

            svg {
                display: block;
                height: calc(100% + 1px);
                width: 100%;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .hero {
         padding-bottom: 120px;

        .breadcrumbs--dark {
            padding-top: 0;
        }

        &__media {
            position: absolute;
            width: 50%;
            height: 100%;
            right: 0;

            .play-icon {
                top: 43%;
                left: 32%;
                transform: translate3d(-32%, -43%, 0) scale(1);

                &:hover {
                    transform: translate3d(-32%, -43%, 0) scale(.96);

                    .play-icon-inner {
                        transform: scale(1.16);
                    }
                }
            }
        }
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .hero {
        &__media {
            .play-icon {
                top: 40%;
                left: 35%;
                transform: translate3d(-35%, -40%, 0) scale(1);

                &:hover {
                    transform: translate3d(-35%, -40%, 0) scale(.96);

                    .play-icon-inner {
                        transform: scale(1.16);
                    }
                }
            }
        }
    }
}