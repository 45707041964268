// Component : section-lookbook
.catalog-category-view{
    .page-title-wrapper{
        .page-title{
            color: $black;
            font-weight: 700;
        }
        text-align: center;
    }
}

.section-lookbook {
    padding: 80px 0;

    .section__body {
        margin: 0 -10px;
        padding-left: 15px;
    }

    .slick-dots {
        button {
            background: #8b9ca0;
            background-clip: content-box;
        }

        .slick-active {
            button {
                background: #8dced9;
                background-clip: content-box;
            }
        }
    }
}

.slider__slide {
    position: relative;
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-lookbook {
        padding-left: 30px;
        padding-right: 30px;
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-lookbook {
        padding: 140px 0;
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-lookbook {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-lookbook {
    }
}
