// Component : Section Research
.section-research {
    overflow: hidden;
    padding: 80px 0 80px;
    h3 {
        text-align: center;
        font-size: 18px;
        line-height: 1.67;
        margin-bottom: 40px;
        font-weight: 700;
    }

    p {
        font-size: 14px;
        line-height: 2;
    }

    .video-section {
        margin-bottom: 40px;
    }
    .list-benefits {
        margin-bottom: 40px;
        overflow: hidden;
        img{
            max-width: 25px;
        }
    }
}

.list-benefits {
    list-style-type: none;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    li {
        float: left;
        width: 50%;
        margin-bottom: 20px;
    }



    i {
        font-size: 0;
        min-width: 40px;
        top: 6px;
        position: relative;
        left: -5px;
        height: 45px;
        display: inline-block;
        float: left;
    }
    span {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        width: calc(100% - 25px);
        display: block;
        margin: 0;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-research {
        padding-bottom: 40px;

        h3 {
            font-size: 19px;
            margin-bottom: 64px;
        }

        p {
            font-size: 16px;
            line-height: 1.88;
        }
    }

    .list-benefits {
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 -20px;
        flex-wrap: nowrap;
        li + li {
            margin-top: 40px;
        }
        li {
            padding: 0 20px;
            display: flex;
            align-items: center;
        }

        li + li {
            margin-top: 0;
        }
        span{
            width: auto;
            white-space: nowrap;
        }
        i{
            top: 0;
            left: -5px;
            height: auto;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-research {
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-research {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-research {
    }
}
