// Component : section-maintenance
.section-maintenance {
    padding: 80px 0 0;

    h1 {
        font-size: 30px;
        line-height: 1.27;
        font-weight: 700;
        color: #fff;
        margin-bottom: 40px;
    }

    p {
        font-size: 16px;
        line-height: 1.88;

        a {
            color: #fff;
        }
    }

    .section__content {
        margin-bottom: 140px;
    }

    .section__image {
        padding: 0 15px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-maintenance {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-maintenance {
        padding: 140px 0;
        display: flex;
        flex-direction: column-reverse;

        h1 {
            font-size: 48px;
            line-height: 1;
            margin-bottom: 8px;
        }

        p {
            font-size: 14px;
            line-height: 2;
            font-weight: 500;
        }

        .section__content {
            margin-bottom: 0;
        }

        .section__image {
            margin-bottom: 80px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-maintenance {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-maintenance {
    }
}
