table{
  thead{
    background-color: $white;
    border:none;
  }
}

.account {
  .intro--small {
    margin-bottom: 0 !important;
  }

  .wrapper .wrapper__inner {
    padding-top: 0 !important;
  }

  .column.main {
    padding: 0 !important;
  }
}
//LOGOUT
.customer-account-logoutsuccess{
  .page-title{
    font-size: 48px;
    line-height: 1.33;
    margin-bottom: 24px;
    font-family: Raleway,sans-serif;
    color: $black;
    font-weight: 700;
  }
}


//ACCOUNT EDIT
.customer-account-edit{
  .form-edit-account{
    .actions-toolbar{
      .primary{
        display: inline-block;
        float: left;
        margin-right: 60px;
      }
    }

    .fieldset {
      margin: 0;
      padding: 0;
      border: 0px solid #ffffff;
      margin-bottom: 30px;

      legend {
        color: #12282D;
        font-family: Raleway, sans-serif;
        font-size: 32px;
        font-weight: bold;
        line-height: 37px;
      }
    }

    .field:not(.choice) {
      position: relative;
      overflow: hidden;
      min-height: 73px;
      margin-bottom: 30px;

      label, .password-strength-meter {
        display: block;
        font-size: 14px;
        color: #8b9ca0;
        line-height: 1.14;
        margin-bottom: 0;
        padding-left: 8px;
        font-weight: normal!important;
      }

      .password-strength-meter {
        margin-top: 10px;
      }

      input{
        font-size: 16px;
        font-weight: 500;
        color: #12282d;
        box-shadow: none;
        border: none;
        background: none;
        border-bottom: 1px solid #e5e5e5;
        height: 48px;
        line-height: 48px;
        padding: 0 20px 0 8px;
        margin-bottom: 0;

        &::placeholder {
          color: $black;
        }

        &.error{
          box-shadow: none;
          border: none;
        }
      }

      textarea {
        font-size: 16px;
        font-weight: 700;
        color: #12282d;
        box-shadow: none;
        border: none;
        background: #f4f4f4;
        display: block;
        padding: 24px 24px;
        height: 240px;
        resize: none;
      }

      select{
        background-color: #f5f5f5;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.14;
        padding: 15px 30px 15px 14px;
        color: #12282d;
        height: auto;
        border-radius: 24px;
        border: none;
        width: 100%!important;
        max-width: 550px;
      }

      select,
      textarea,
      input{
        &:focus{
          text-shadow: none;
          border: 0 solid transparent;
          box-shadow: none;
        }
      }

      select,
      input{
        &:focus{
          border-bottom: 1px solid #e5e5e5;
        }
      }
    }

    input[type='submit']{
      background: #007286;
      color: #fff;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 16px;
      font-weight: 700;
      padding: 0 48px;
      transition: background 0.3s, color 0.3s;
      line-height: 57px;
      height: 57px;
      border-radius: 29px;
      min-width: 179px;
      border: none;

      &:hover{
        background: #0b97af;
        color: #fff;
        box-shadow: none;
        text-shadow: none;
      }
    }

    .form__controls {
      .control {
        margin-bottom: 40px;

        strong {
          color: #8B9CA0;
          font-family: Raleway, sans-serif;
          font-size: 14px;
          line-height: 16px;
          display: block;
          margin-bottom: 16px;
        }

        input + span {
          color: #12282D;
          font-family: Raleway, sans-serif;
          font-size: 16px;
          font-weight: bold;
          line-height: 19px;
          position: relative;
          padding-bottom: 18px;

          &:after {
            display: none;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            border-bottom: 1px solid #E5E5E5;
            width: 474px;
          }
        }

        .label {
          span {
            color: #12282D;
            font-family: Raleway, sans-serif;
            font-size: 16px;
          }
        }
      }
    }
  }

  .contact__problem {
    p {
      color: #8B9CA0;
      font-family: Raleway,sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 21px;
    }
  }

  .retailer {

    h2 {
      color: #12282D;
      font-family: Raleway,sans-serif;
      font-size: 32px;
      font-weight: bold;
      line-height: 37px;
    }
  }

  .retrailer__informations {
    background-color: #F5F5F5;
    color: #12282D;
    font-family: Raleway,sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    padding: 24px;
    margin-top: 40px;
    margin-bottom: 40px;

    p {
      margin-bottom: 0px;
    }
  }
}

.account {
  .intro .intro__background {
    min-height: 100%;
  }

  .field {
    .label {
      background-color: transparent;
    }
  }

  .form-edit-account .actions-toolbar .primary {
    margin-right: 28px;
  }
}

.form-newsletter-manage {
 .choice {
   margin-bottom: 20px;
 }

  .actions-toolbar {
    .primary, .secondary {
      display: inline-block;
    }

    .primary {
      margin-right: 20px;
    }
  }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
  //ACCOUNT EDIT
  .customer-account-edit {
    .form-edit-account, .side {
      width: 49%;
      display: inline-block;
      margin-bottom: 40px;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      vertical-align: top;
    }

    .side {
      margin-left: 53px;
      width: 46%;
    }
  }
}