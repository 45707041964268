// Component : callout

.callout-box {
    padding: 60px 0;
    color: #fff;

    p {
        font-size: 16px;
        line-height: 1.88;
        margin-bottom: 0;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h3, h2 {
        font-size: 18px;
        line-height: 1.67;
        font-weight: 700;
        margin-bottom: 0;
    }

    .callout__entry {
        margin-bottom: 32px;
    }

    .calout__actions {
        text-align: center;
    }

    .callout__logo {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 32px;
    }

    .btn-white {
        color: #12282d;
    }
    .btn--white {
        color: #8dced9;
    }

    &--alt {
        padding: 32px 0;
        margin-bottom: 32px;

        .callout__entry {
            margin-bottom: 24px;
            text-align: center;
            max-width: 100%;
        }

        .callout__inner {
            margin: 0 -10px;
        }

        .callout__inner--alt {
            margin: 0;
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
        }

        .btn {
            display: block;
            max-width: 231px;
            width: 100%;
            min-width: 0;
            margin: 0 auto;
            max-width: 100%;
            padding: 0 20px;
        }
    }
}

.callout-box-large {
    position: relative;
    min-height: 366px;
    padding: 40px 0;
    display: flex;
    align-items: center;
    color: #fff;

    h3, h2 {
        font-size: 30px;
        line-height: 1.17;
        margin-bottom: 32px;
        font-weight: 700;
    }

    p {
        font-size: 16px;
        line-height: 1.88;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .callout__entry {
        margin-bottom: 40px;
    }

    .callout__actions {
        .btn {
            width: 100%;
        }
    }
    .callout__inner {
        width: 100%;
    }

    .row {
        /*width: auto;
        max-width: 100% !important;*/
    }

    .callout-decoration {
        display: none;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .callout-box {
        padding: 30px 15px;

        h3, h2 {
            font-size: 24px;
            line-height: 1.18;
        }

        .callout__inner {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        .callout__entry {
            flex-grow: 1;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 0;
        }
        .callout__actions,
        .callout__logo {
            flex-shrink: 0;
            margin-bottom: 0;
        }

        &--alt {
            padding: 40px 0;

            .callout__inner {
                flex-direction: column;
                text-align: center;
            }

            .callout__entry {
                margin-bottom: 24px;
            }

            .btn {
                display: inline-block;
                max-width: none;
                width: auto;
                padding: 0 40px;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .callout-box {
        padding: 40px 15px;

        h3, h2 {
            font-size: 28px;
        }

        .callout__entry {
            padding: 0 40px;
        }

        p {
            font-size: 16px;
            line-height: 1.88;
        }

        &--alt {
            .callout__entry {
                padding: 0;
            }
        }
    }

    .callout-box-large {
        min-height: 461px;
        height: 461px;
        position: relative;

        h3, h2 {
            font-size: 40px;
        }

        .callout__actions {
            .btn {
                width: auto;
                color: #12282d;
                font-size: 16px;
            }
        }
        .callout-decoration {
            display: block;
            position: absolute;

            &--left {
                top: 121px;
                left: 0;
                margin-left: -112px;
                z-index: -1;
            }
            &--right {
                top: 33px;
                right: -15px;
                margin-right: -94px;
                z-index: -1;

                &-arm{
                    right: -110px;
                    top: 182px;
                }
            }
            &--hand {
                z-index: 2;
                top: 321px;
                left: 0;
                margin-left: -9px;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
