// Component : section-phosphorylation
.section-phosphorylation {
    margin: 0 0 100px;
    .section__image{
        margin-top: 100px;
    }
}

.article-product {
    h5 {
        font-size: 18px;
        line-height: 1.67;
        margin: 0 0 16px;
        font-weight: 700;
    }

    p {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 24px;
    }

    .list-dots {
        list-style-type: none;
        margin: 0 0 24px;
        padding: 0;

        li {
            padding-left: 24px;
            position: relative;
            font-size: 14px;
            line-height: 2;

            &:before {
                position: absolute;
                top: 9px;
                left: 0;
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #8dced9;
            }
        }

        li + li {
            margin-top: 28px;
        }
    }

    .article__actions {
        padding: 16px 0 40px;
    }

    .article__image {
        img {
            width: 100%;
            height: auto;
        }
    }
}

.article-product + .article-product {
    margin-top: 80px;
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .article-product {
        p{
            margin-bottom: 38px;
        }
        .row-reverse-d {
            flex-direction: row-reverse;
        }

        .article__actions {
            text-align: left;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-phosphorylation {
    }

    .article-product {
        h5 {
            font-size: 32px;
            line-height: 1.16;
            margin: 0 0 24px;
        }

        p {
            font-size: 16px;
            line-height: 1.88;

            &:last-child() {
                margin-bottom: 0;
            }
        }

        .article__image {
            img {
                width: auto;
                max-width: 100%;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-phosphorylation {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-phosphorylation {
    }
}
