// Component : Section Offers
.section-offers {
    padding: 80px 0 40px;

    .section__head {
    }
    .section__title {
        font-weight: 700;

        text-align: center;
        margin-bottom: 24px;
    }
}

.section-offers-single{
    .section__aside{
        margin-bottom: 80px;
    }
}

.offer__meta {
    margin-bottom: 33px;
    align-items: center;
    & + span{
        float: left;
    }
    span {
        font-size: 13px;
        line-height: 1.69;
        color: #8b9ca0;
        font-weight: 600;
    }
    span + span {
        &:before {
            display: inline-block;
            vertical-align: middle;
            font-size: 0;
            content: '';
            width: 34px;
            height: 1px;
            background: #8b9ca0;
            margin: 0 15px;
        }
    }
}

.offer + .offer {
    border-top: 1px solid #e5e5e5;
}

.offer {
    padding: 40px 30px;

    h2 {
        font-size: 18px;
        line-height: 1.67;
        font-weight: 700;
        margin: 0 0 16px;
    }

    p {
        font-size: 14px;
        line-height: 2;
        color: #8b9ca0;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .offer-location {
        display: flex;
        align-items: center;

        i {
            flex-shrink: 0;
            margin-right: 10px;
            font-size: 0;
        }
        small {
            flex-grow: 1;
            font-size: 14px;
            color: #12282d;
            line-height: 2;
        }
    }

    .offer__head {
        margin-bottom: 40px;
    }

    .offer__actions {
        padding-top: 32px;
        text-align: center;
        .btn-inverted {
            padding: 0 20px;
            min-width: 240px;
            text-align: center;
        }
    }

    .list-tags-small {
        margin-bottom: 0;
    }

    &.offer--single {
        padding: 0;
        margin-bottom: 80px;

        .offer__group {
            h4 {
                font-size: 18px;
                line-height: 1.67;
                margin-bottom: 16px;
                font-weight: 700;
            }

            p {
                font-size: 14px;
                line-height: 2;
                color: #12282d;
                margin-bottom: 28px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            ul {
                list-style-type: none;
                margin-bottom: 28px;
                li {
                    position: relative;
                    font-size: 14px;
                    line-height: 2;
                    padding-left: 25px;

                    &:before {
                        position: absolute;
                        top: 10px;
                        left: 0;
                        content: '';
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #007286;
                    }
                }
                li + li {
                    margin-top: 25px;
                }
            }
        }
        .offer__group + .offer__group {
            margin-top: 40px;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-offers {
        padding: 140px 0;
    }

    .offer__meta{
        display: flex;
    }
    .offer {
        padding: 64px 9px;
        position: relative;

        h2 {
            font-size: 32px;
            line-height: 1.5;
            margin-bottom: 8px;
        }

        p {
            font-size: 16px;
            line-height: 1.88;
        }

        .offer__head {
            padding-right: 0;
            // padding-right: 250px;
        }

        .offer__head-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .offer__actions {
            padding-top: 0;
            margin-bottom: 8px;
            flex-shrink: 0;
        }

        .offer-location {
            small {
                font-size: 16px;
            }
        }
        .form-container{
            max-width: 100%;
        }
        &.offer--single {
            padding: 0;

            .offer__head {
                margin-bottom: 64px;
            }

            .offer__group {
                h4 {
                    font-size: 32px;
                    margin-bottom: 13px;
                }

                ul li {
                    font-size: 16px;
                }
            }
            .offer__group + .offer__group {
                margin-top: 96px;
            }

            .offer__group p {
                font-size: 16px;
            }

            .offer__body + .offer__actions {
                padding-top: 40px;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-offers {
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-offers {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-offers {
    }
}
