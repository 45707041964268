// Component : section-account-information

.section-account-tab {
    padding: 40px 0;
}
.section-account-information {
    .section__head {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin-bottom: 40px;
        margin-bottom: 0px;

        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 1.16;
            width: 100%;
            margin: 0;
        }

        a {
            display: inline-block;
            text-decoration: underline;
            color: #007286;
            font-size: 14px;
            line-height: 1.14;

            &:hover {
                text-decoration: none;
            }
        }

        h2 + a {
            margin-top: 14px;
        }
    }
}

.section-account-information + .section-account-information {
    margin-top: 40px;
}

.table-orders {
    .list-btns {
        display: flex;
        align-items: center;
        margin: 0 -12px;
        flex-wrap: wrap;

        li {
            padding: 0 12px;
            text-align: center;
            width: 100%;
        }

        li + li {
            margin-top: 10px;
        }

        .btn {
            width: 100%;
        }
    }

    tr {
        display: block;
        width: 100%;
    }

    th,
    td {
        display: block;
        width: auto;
    }

    table tbody tr:nth-child(even) {
        background: none;
    }

    tr + tr {
        margin-top: 10px;
    }

    th {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.14;
        color: #8b9ca0;
        padding-bottom: 12px;
        text-align: center;
        display: none;
    }

    td {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 700;
        text-align: center;
        padding: 5px;

        &:before {
            display: block;
            text-align: center;
            content: attr(data-title);
            color: #8b9ca0;
            font-size: 12px;
            margin-bottom: 10px;
            font-weight: normal;
        }
    }

    .btn-white-smoke {
        color: #007286;
    }

    tbody {
        border: none;
    }
}

.information-box {
    padding: 20px 0;
    p {
        color: #12282d;
        font-size: 16px;
        font-weight: 700;
        line-height: 2;
        margin-bottom: 0;
        a{
            color: #12282d;
        }
    }
    .information-box__title {
        font-weight: normal;
        color: #8b9ca0;
        font-size: 14px;
        line-height: 1.14;
        margin-bottom: 20px;
    }

    .information-box__actions {
        padding-top: 32px;
        padding-top: 12px;

        .btn-white-smoke {
            color: #007286;
        }

        ul {
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0 -12px;

            .btn {
                display: block;
                min-width: 147px;
                padding: 0 10px;
            }

            li {
                padding: 0 12px;
            }

            li {
                width: 100%;
                text-align: center;
            }

            li + li {
                margin-top: 10px;
            }
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-account-information {
        .section__head {
            margin-bottom: 40px;
            h2 {
                width: auto;
            }

            h2 + a {
                margin-top: 0px;
                margin-left: 14px;
            }
        }
    }

    .section-account-information + .section-account-information {
        margin-top: 80px;
    }

    .table-orders {
        tr {
            display: table-row;
        }

        th,
        td {
            display: table-cell;
            vertical-align: top;
            text-align: left;
        }

        th {
            padding: 0 0 24px;
        }

        td {
            min-height: 57px;
            padding: 10px 0;
            vertical-align: middle;

            &:before {
                display: none;
            }

            &:last-of-type {
                width: 200px;
                white-space: nowrap;
            }
        }

        .list-btns {
            width: auto;
            padding: 20px 0;

            li {
                width: 50%;
                min-width: 227px;
                max-width: 227px;
            }

            li + li {
                margin-top: 10px;
            }
            .btn {
                min-width: 0;
            }
        }
    }

    .information-box {
        padding: 0;

        .information-box__title {
            margin-bottom: 37px;
        }

        .information-box__actions {
            padding-top: 32px;
            ul {
                li {
                    width: auto;
                }
            }

            .shrink {
                flex-shrink: 0;
            }
            .grow {
                flex-grow: 1;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-account-tab {
        padding: 80px 0 40px;

        .section__head {
            h2 {
                font-size: 32px;
            }

            a {
                line-height: 1.9;
            }
        }
    }
    .section-account-information {
    }

    .section-account-information + .section-account-information {
        margin-top: 140px;
    }

    .table-orders {
        .list-btns {
            padding: 16px 0;
            li + li {
                margin-top: 0;
            }
        }
        th {
            padding-bottom: 8px;
        }

        td {
            min-height: 57px;
            padding: 0;
            vertical-align: middle;

            &:last-of-type {
                width: 454px;
                white-space: nowrap;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-account-information {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-account-information {
    }
}
