/**
 * @atom Button
 * @section Navigation > Buttons
 * @markup
 *   <a class="button">
 *     A button
 *   </a>
 */

// h1{ font-size: 100px; }

p {
	font-size: 16px;
	line-height: 1.88;
}

.btn {
	background: $blue-teal;
	color: #fff;
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	appearance: none;
	font-size: 16px;
	font-weight: 700;
	padding: 0 48px;
	transition: background 0.3s, color 0.3s;

	&:hover {
		background: #0b97af;
		color: #fff;
	}

	@media (max-width: 370px) {
		padding: 0 20px;
	}
}

.btn-rounded {
	line-height: 47px;
	min-height: 47px;
	border-radius: 24px;
	min-width: 231px;

	&--width-alt {
		min-width: 171px;
	}
}

.btn--white {
	background: #ffffff;
	color: #007286;

	&:hover {
		background: #dae3e4;
		color: #007286;
	}
}

.btn-square {
	border-radius: 0;
	border: 1px solid #e5e5e5;
	// height: 48px;
	// line-height: 48px;
	height: auto;
	color: #a06590;
	background: none;
	font-size: 12px;
	line-height: 1.5;
	padding: 14px 24px;
	text-transform: uppercase;

	&--light {
		color: #f08576;
		.product-ivd &{
			color: #a06590;
		}
	}

	&:hover {
		color: #fff;
		background: #a06590;
		cursor: default;
		.product-ls &{
			background: #f08576;
		}
	}
}

.btn-white-smoke {
	background: $white-smoke;
	color: #8dced9;

	&--dark-text {
		color: #007286;
	}
	&:hover {
		background: #dae3e4;
		color: #007286;
	}
}

.btn-with-border {
	border: 2px solid #000;
	line-height: 43px;
}

.btn--lightblue {
	background: $blue-viking;
	color: #fff;
}

.btn--greyblue {
	background: $light-blue;
	color: #fff;
}

.btn-lightgreen {
	background-color: $green-riptide;
	color: #fff;
	transition: all .4s;

	&:hover {
		background: #77dff1;
		color: #fff;
	}
}

.btn-lightgreen-alt {
	background: none;
	border-color: $green-riptide;
	color: $green-riptide;

	&:hover {
		background: $green-riptide;
		color: #fff;
	}
}

.btn-inverted {
	display: inline-block;
	vertical-align: middle;
	line-height: 53px;
	min-height: 57px;
	border-radius: 28px;
	background: none;
	padding: 0 53px;
	font-size: 14px;
	font-weight: 700;
	transition: backgrund 0.3s, color 0.3s;
	// default colors
	border: 2px solid #f5f5f5;
	color: #f5f5f5;

	&:hover {
		background: #f5f5f5;
		color: #fff;
	}

	&--lightgreen {
		border-color: $green-riptide;
		color: $green-riptide;

		&:hover {
			background: $green-riptide;
			color: #fff;
		}
	}

	&--lightblue {
		border-color: $blue-viking;
		color: $blue-viking;

		&:hover {
			background: $blue-viking;
			color: #fff;
		}
	}

	&--blue {
		border-color: $blue-teal;
		color: $blue-teal;

		&:hover {
			background: $blue-teal;
			color: #fff;
		}
	}
}

/* Base for label styling */

[type='checkbox']:not(:checked),
[type='checkbox']:checked {
	position: absolute;
	left: -9999px;
}

[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label {
	position: relative;
	padding-left: 36px;
	line-height: 1.19;
	cursor: pointer;
	font-size: 16px;
	color: #12282d;
	margin-left: 0;
}

/* checkbox aspect */

[type='checkbox']:checked + label:before,
[type='checkbox']:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 20px;
	height: 20px;
	border-radius: 0px;
	background: #e5e5e5;
	z-index: 1;
	transform: translateY(-50%);
}
[type='checkbox']:checked + label:after,
[type='checkbox']:not(:checked) + label:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 6px;
	background: #007286;
	transition: visibility 0.3s, opacity 0.3s;
	width: 8px;
	height: 8px;
	transform: translateY(-50%);
	visibility: hidden;
	opacity: 0;
	z-index: 2;
}

[type='checkbox']:checked + label:after {
	opacity: 1;
	visibility: visible;
}

/*  Radio  */

[type='radio']:not(:checked),
[type='radio']:checked {
	position: absolute;
	left: -9999px;
}

[type='radio']:not(:checked) + label,
[type='radio']:checked + label {
	position: relative;
	padding-left: 25px;
	line-height: 2;
	cursor: pointer;
	font-size: 14px;
	color: #12282d;
	margin-left: 0;
	margin-right: 0;
	padding-right: 5px;

	img {
		margin-right: 5px;
	}
}

#hubspot-quote-form{
	.hs-form-radio-display{
		line-height: 30px;
		span{
			display: inline-block;
			position: relative;
			padding-left: 25px;
			padding-right: 50px;
			&:hover{
				cursor: pointer;
			}
		}
		label{
			top: 14px;
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				top: 28px;
			}
			&:before,
			&:after{
				pointer-events: none;
			}
		}
	}
}

/* radio aspect */

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	border: 2px solid #007286;
	z-index: 1;
	transform: translateY(-50%);
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 5px;
	background: #007286;
	border-radius: 50%;
	transition: visibility 0.3s, opacity 0.3s;
	width: 7px;
	height: 7px;
	transform: translateY(-50%);
	visibility: hidden;
	opacity: 0;
	z-index: 2;
}

[type='radio']:checked + label:after {
	opacity: 1;
	visibility: visible;
}

.pages-items {
	overflow: hidden;
	text-align: center;

	li{
		list-style-type: none;
		display: inline-block;
		padding: 0 5px;
		&.current{
			color: #007286;
			font-size: 20px;
		}
		a{
			color: #B2B2B2;
			font-size: 20px;
		}
	}

	.page-next,
	.page-prev {
		position: relative;
		width: 12px;

		&:before {
			display: block;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
		}
	}

	.page-prev {
		&:before {
			border-width: 6px 12px 6px 0;
			border-color: transparent #12282d transparent transparent;
		}
	}

	.page-next {
		&:before {
			border-width: 6px 0 6px 12px;
			border-color: transparent transparent transparent #12282d;
		}
	}
}

.revert-for-mobile {
	display: flex;
	flex-direction: column-reverse;
}

@media screen and #{breakpoint(medium up)} {
	.revert-for-mobile {
		display: block;
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.btn-rounded {
		line-height: 57px;
		min-height: 57px;
		border-radius: 29px;
		min-width: 179px;

		&--width-alt {
			min-width: 202px;
		}
	}

	.btn-with-border {
		line-height: 53px;
	}

	.btn-square {
		font-size: 14px;
	}

	[type='radio']:not(:checked) + label,
	[type='radio']:checked + label {
		position: relative;
		padding-left: 49px;
		line-height: 2;
		cursor: pointer;
		font-size: 16px;
		color: #12282d;
		margin-left: 0;
		margin-right: 0;
		padding-right: 5px;

		img {
			margin-right: 24px;
		}
	}
	.btn-inverted {
		font-size: 16px;
	}
}
.clearfix::before,
.clearfix::after {
	display: table;
	content: ' ';
}

.clearfix::after {
	clear: both;
}

body{

	a:focus,
	div:focus{
		outline: none!important;
	}
}
.limiter{
	.limiter-options{
		display: inline-block;
		width: 60px;
	}
}
.btn-play {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	&:focus{
		outline: none;
	}
}

.list-dots {
	list-style-type: none;

	li {
		position: relative;
		font-size: 14px;
		line-height: 1.43;
		font-weight: 500;
		padding-left: 24px;

		&:before {
			position: absolute;
			top: 7px;
			content: '';
			width: 8px;
			height: 8px;
			background: #ffca67;
			border-radius: 50%;
		}
	}

	li + li {
		margin-top: 20px;
	}
}


.full-width{
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}

.absolute-link{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.wrapper .wrapper__inner {
	transform: none !important;
}



.hbspt-form .hs-form .field.hs-fieldtype-file .hs-error-msgs label {
	background-color: transparent;

	&:after {
		display: none;
	}
}

.hbspt-form {
	.hs-form {
		.field{
			margin-bottom: 30px;
			padding: 0;
			&.hs-fieldtype-textarea{
				border-bottom: none;
			}
			input{
				&.error{
					border-bottom: 1px solid #e5e5e5;
					&:focus{
						box-shadow: none;
					}
				}
			}
		}
		.hs-error-msgs{
			label{
				color: #ea0c0c;
			}
		}
		.hs-fieldtype-text {
			input {
				font-size: 16px;
				font-weight: 700;
				color: #12282d;
				border-radius: 0;
			}

			::placeholder {
				color: #8b9ca0 !important;
				font-size: 16px;
				font-weight: 700;
			}
		}
		.hs-fieldtype-select{
			select{
				margin-top: 20px;
			}
		}
		.field.hs-fieldtype-textarea {
			height: auto;

			label {
				display: none;
			}

			textarea {
				font-size: 16px;
				font-weight: 700;
				color: #12282d;
				box-shadow: none;
				border: none;
			}

			::placeholder {
				color: #8b9ca0 !important;
				font-size: 16px;
				font-weight: 700;
			}
		}

		.file-name {
			margin: 0;
			position: absolute;
			top: -37px;
			left: 22px;
			min-width: 351px;
			max-width: 400px;
			background: #f5f5f5;
			pointer-events: none;

			overflow: hidden;
			display: block;
			height: 27px;
			text-overflow: ellipsis;
		}

		.field.hs-fieldtype-file {
			position: relative;
			margin-top: 38px;
			max-width: 470px;
			height: auto;
			margin: 0;
			border-bottom: none;
			&.hs-fieldtype-textarea{
				border-bottom: none;

			}
			&.hs_file_upload_cv{
				.hs-form-required{
					position: absolute;
					left: 90px;
				}
			}
			.input {
				position: absolute !important;
			}
			label {
				display: -ms-flexbox;
				display: flex;
				-ms-flex-pack: justify;
				justify-content: space-between;
				-ms-flex-align: center;
				align-items: center;
				margin: 0;
				height: 47px;
				line-height: 16px;
				border-radius: 24px;
				background: #f5f5f5;
				padding: 0 24px;
				color: #12282d;
				font-size: 14px;
				font-weight: 600 !important;

				&:after {
					font-family: 'icomoon' !important;
					speak: none;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					content: "\e900";
				}
			}

			[type=file] {
				position: absolute;
				border: none;
				font-size: 0;
				line-height: 0;
				text-indent: -4004px;
				margin: 0;
				padding: 0;
			}
		}

		.hs_submit {
			margin-top: 40px;
			text-align: center;
			max-width: 466px;
			margin-bottom: 50px;
		}
	}
}

.no-strong-style {
	font-weight: normal;
}
// --DESKTOP
@media screen and #{breakpoint(medium up)} {
	.hbspt-form{
		.hs-form {
			.field{
				&.hs-fieldtype-file{
					label{
						line-height: 47px;
					}
				}

			}
		}
	}

}
@media screen and #{breakpoint(large up)} {
	body {
		width: 100vw;
		overflow-x: hidden;
	}
}