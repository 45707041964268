.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.9);
    display: none;
    z-index: 30;

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 80%;
        max-width: 1200px;
        background: #fff;
        padding: 60px;
        height: 90%;
        overflow-y: auto;

        .title {
            font-size: 2.5rem;
            margin-bottom: 3.5rem;
            line-height: 34px;
        }

        &__text {
            padding: 10px;
            display: block;
            width: 100%;
        }

        &__close {
            display: block;
            position: absolute;
            top: 1rem;
            left: auto;
            right: 1rem;
            background: url(../images/svg/ico-close.svg) 0 0 no-repeat;
            opacity: 1;
            background-size: 100%;
            width: 20px;
            height: 20px;

            &:hover{
                cursor: pointer;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .modal {
        &__content {
            overflow-y: inherit;
        }
    }
}