// Component : section-profiling
.section-profiling {
    padding: 40px 0 40px;

    h2 {
        font-size: 18px;
        line-height: 1.67;
        font-weight: 700;
        margin-bottom: 16px;
    }

    p {
        font-size: 14px;
        line-height: 2;
    }

    .section__group {
        margin-bottom: 46px;
        ul{
            @extend .list-btns-square;

            li{
                padding: 0 12px;
                margin-bottom: 24px;
                width: auto;
                position: relative;
                list-style: none;
                padding-left: 30px;
                line-height: 27px;
                width: 100%;
                margin-bottom: 0;
                &:before {
                    position: absolute;
                    top: 8px;
                    left: 0;
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #007286;
                }
                a{
                    @extend .btn;
                    @extend .btn-square--light;
                    @extend .btn-square;
                }
            }
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-profiling {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-profiling {
        padding: 80px 0 40px;
        h2 {
            font-size: 32px;
            line-height: 1.16;
            margin-bottom: 24px;
        }

        p {
            font-size: 16px;
            line-height: 1.88;
        }

        .section__group {
            &:last-of-type {
                margin-bottom: 0;
            }
            ul {
                margin: 15px 0;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-profiling {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-profiling {
    }
}
