// Component : Section Kits Available
.section-kits-available {
    padding: 40px 0 40px;
    .document{
        h6{
            text-transform: uppercase;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-kits-available {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-kits-available {
        padding: 80px 0 40px;

        .section__foot {
            padding-top: 48px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-kits-available {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-kits-available {
    }
}
