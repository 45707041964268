// Component : section await
.section-await {
    padding: 80px 0 40px;
    overflow: hidden;

    h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.67;
        margin: 0 0 16px;
    }

    p {
        font-size: 14px;
        line-height: 2;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-await {
        h3 {
            font-size: 24px;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-await {
        padding: 140px 0 70px;

        h3 {
            font-size: 32px;
            line-height: 1.94;
            margin: 0 0 13px;
        }

        p {
            font-size: 16px;
            line-height: 1.88;
        }

        .video-with-decoration {
            margin-bottom: 70px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-await {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-await {
    }
}
