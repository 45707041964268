// Component : section-callouts
.section-callouts {
    padding: 80px 0 48px;
    padding-top: 0;
    .section__title {
        margin-bottom: 32px;
        font-weight: 700;
        font-size: 32px;
    }

    p {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 36px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-callouts {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-callouts {
        padding: 140px 0 108px;
        padding-top: 0;
        text-align: center;

        p {
            font-size: 16px;
            line-height: 1.88;
            margin-bottom: 64px;
        }

        .section__title {
            margin-bottom: 23px;
            font-size: 40px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-callouts {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-callouts {
    }
}
