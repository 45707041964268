// Component : Form Contact

.form-contact {
    margin: 20px 0 80px;

    .hbspt-form .hs-form .field.hs-fieldtype-file {
        margin-bottom: 20px;
    } 

    .fileName {
        position: absolute;
        top: -5px;
        left: 23px;
        font-size: 12px;
        font-weight: normal;
        width: 230px;
        text-align: left;
        span {
            margin-right: 5px;
        }
        svg{
            position: relative;
            top: 3px;
        }
    }

    .form__controls {
        margin-bottom: 40px;
    }

    .form__actions {
        text-align: center;

        p {
            color: #8b9ca0;
            font-size: 14px;
            line-height: 1.14;
            font-weight: 600;
            margin-bottom: 24px;
        }

        a {
            display: block;
            margin: 10px 0;
        }
        button{
            a{
                color: $white;
                line-height: 57px;
                color: #fff;
                text-align: center;
                display: inline-block;
                vertical-align: middle;
                text-decoration: none;
                font-size: 16px;
                font-weight: 700;
            }
        }
    }

    .form__label {
        display: block;
        font-size: 14px;
        color: #8b9ca0;
        line-height: 1.14;
        margin-bottom: 0;
        padding-left: 8px;
    }

    .form__btn {
        width: 100%;
    }

    .field,
    .textarea {
        font-size: 16px;
        font-weight: 700;
        color: #12282d;
        box-shadow: none;
        border: none;
    }

    .field {
        background: none;
        min-height: 48px;
        line-height: 48px;
        padding: 0 20px 0 8px;
        &.fieldtype-textarea{
            padding: 0;
        }
    }

    .textarea {
        background: #f4f4f4;
        display: block;
        padding: 24px 24px;
        height: 240px;
        resize: none;
    }

    .form__error {
        display: none;
        color: #d46f61;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.14;
        text-transform: uppercase;
    }

    .form__controls.error {
        .form__error {
            display: block;
        }
    }

    .form__head {
        padding-top: 40px;
        padding-bottom: 40px;
        h1,
        h2 {
            font-size: 24px;
            line-height: 1.33;
            font-weight: 700;
            margin: 0;
        }
    }

    .text-box {
        background: #f5f5f5;
        padding: 24px;
        margin-bottom: 40px;
        margin-left: -25px;
        margin-right: -25px;

        p {
            font-size: 16px;
            font-weight: 700;
            line-height: 1.5;
            margin-bottom: 0;
        }
    }

    .form__entry {
        padding-bottom: 40px;
        p {
            font-size: 16px;
            line-height: 1.88;
            margin-bottom: 0;
        }
    }

    .form__foot {
        padding-top: 40px;

        .hint {
            display: inline-block;
            color: #8b9ca0;
            font-size: 14px;
            line-height: 1.71;
        }
    }

    a {
        font-size: 14px;
        line-height: 1.14;
        display: inline-block;
        color: #007286;
        text-decoration: underline;
        margin-top: 15px;
        &:hover {
            text-decoration: none;
        }
    }

    .remember-box {
        display: flex;
        align-items: center;
        padding-bottom: 41px;
        flex-wrap: wrap;

        .checkbox + a {
            width: 100%;
            text-align: left;
            margin-left: 0;
            margin-top: 20px;
        }
    }

    [type='file'] {
        position: absolute;
        border: none;
        font-size: 0;
        line-height: 0;
        text-indent: -4004px;
        width: 0 !important;
        visibility: hidden !important;
        padding: 0 !important;
    }

    [type='file'] + .form__label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        height: 47px;
        line-height: 47px;
        border-radius: 24px;
        background: #f5f5f5;
        padding: 0 24px;

        span {
            color: #12282d;
            font-size: 14px;
            font-weight: 600;
            max-width: calc(100% - 30px);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .form__controls-upload {
        position: relative;
    }
    ::-webkit-input-placeholder {
        color: #8b9ca0;
    }
    ::-moz-placeholder {
        color: #8b9ca0;
    }
    :-moz-placeholder {
        color: #8b9ca0;
    }
    :-ms-input-placeholder {
        color: #8b9ca0;
    }
}

.select-default {
    .select2-container--default .select2-selection--single {
        background: #f5f5f5;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.14;
        padding: 15px 30px 15px 14px;
        color: #12282d;
        height: auto;
        border-radius: 24px;
        border: none;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 1.14;
        color: #12282d;
        padding-left: 0;
        text-overflow: normal;
        white-space: normal;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border-color: #12282d transparent transparent transparent;
    }

    .select2-container,
    .select2-container span,
    &:focus {
        outline: none;
    }

    .select2-container {
        width: 100% !important;
    }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #f5f5f5;
    color: #12282d;
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .form-contact {
        .form__btn {
            width: auto;
        }

        .text-box {
            margin-left: 0;
            margin-right: 0;
        }

        .form__actions.text-left {
            text-align: left;
        }
        .form__actions.text-right {
            text-align: right;
        }
        .fileName {
            width: 280px;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .form-contact {
        .textarea {
            height: 314px;
        }

        .text-box {
            p {
                font-size: 16px;
                line-height: 1.5;
            }
        }

        .form__head {
            padding-top: 30px;
            padding-bottom: 40px;
            h1,h2 {
                font-size: 32px;
                line-height: 1.16;
                margin: 0;
            }
        }

        .remember-box {
            flex-wrap: wrap;

            .checkbox + a {
                width: auto;
                text-align: left;
                margin-left: 30px;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
