///////////////////////////////*/
/*
/*   Colors
/*
///////////////////////////////*/

/** @color Light Gray */
$light-gray: #e6e6e6;
/** @color Medium Gray */
$medium-gray: #cacaca;
/** @color Dark Gray */
$dark-gray: #8a8a8a;
/** @color Black */
$black: #12282d;
/** @color White */
$white: #fefefe;

$blue-teal: #007286;
$green-riptide: #8dced9;
$blue-viking: #50b7c9;
$white-smoke: #f5f5f5;
$light-coral: #f08576;
$grandis: #ffca67;
$yellow: #ffca67;
$violet: #A06590;

// 3 mains blue color by universe
$light-blue: #8DCED9;
$medium-blue: #50B7C9;
$dark-blue: #007286;
