// Component : Article Featured
.article-featured {
    margin: 0 0 100px;
    margin-bottom: 100px;
    background-color: #007286;
    color: #fff;
    padding: 0;
    &.salmon{
        background-color: #EE8578!important;
        .article__content{
          background-color: #EE8578!important;
          .btn{
            background: #8FCED8!important;
            color: white!important;
              &:hover{
                  background: #77dff1!important;
              }
          }
          .svg-mask{
            path{
              fill: #EE8578!important;
            }
          }
        }
    }
    &.grey{
        background-color: #F5F5F5!important;
        .article__content{
          background-color: #F5F5F5!important;

          .btn{
            background: #0F7285!important;
            color: white!important;
              &:hover{
                  background: #0b97af!important;
              }
          }
          p, h3, h2{
            color: black!important;
          }
          .svg-mask{
            path{
              fill: #F5F5F5!important;
            }
          }
        }
    }
    h3, h2 {
        font-size: 24px;
        line-height: 1.33;
        margin-bottom: 32px;
        font-weight: 700;
    }
    p {
        font-size: 14px;
        line-height: 1.71;
        margin-bottom: 40px;
    }
    .article__image {
        position: relative;
        width: 100%;
        height: 284px;
    }

    .article__image__inner {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
    }

    .article__content {
        position: relative;
        text-align: center;
        padding: 48px 20px 23px;
        min-height: 450px;
        .svg-mask {
            position: absolute;
            left: 0;
            bottom: -28px;
            width: 100%;
            z-index: 3;
            font-size: 0;
            line-height: 1;
            svg {
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }
    }

    &--large {
        .article__image {
            height: 380px;
        }

        .article__content {
            padding-left: 45px;
            padding-right: 45px;
            padding-bottom: 80px;

            p {
                margin-bottom: 0;
            }
        }
    }
}

.section-article-featured {
    padding-bottom: 32px;

    & + .section-article-featured:last-of-type {
        padding-bottom: 80px;
    }
}

@media screen and (min-width:330px) {
  .article-featured {
      .article__content {
          .svg-mask {
            bottom: -30px;
          }
      }
  }
}

@media screen and (min-width:350px) {
  .article-featured {
      .article__content {
          .svg-mask {
            bottom: -34px;
          }
      }
  }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .article-featured {
        display: flex;
        overflow: hidden;
        margin-bottom: 140px;
        .article__content {
            width: 40%;
            min-height: 0;
            .svg-mask {
                margin-left: -1px;
                position: absolute;
                left: 100%;
                left: calc(100% + 16px);
                top: 0;
                width: 51px;
                height: 100%;

                svg {
                    width: 52px;
                    max-width: none;
                    height: calc(100% + 1px);
                }
            }
        }
        .article__image {
            width: 60%;
            height: 439px;
            left: 15px;
        }

        &--large {
            .article__image {
                height: 583px;
                width: 65%;
            }

            .article__content {
                width: 35%;
                text-align: left;
                .svg-mask {
                    margin-left: -1px;
                    width: 127px;
                    svg {
                        margin-left: -1px;
                        width: 127px;
                    }
                }
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .article-featured {
        h3, h2 {
            font-size: 30px;
            line-height: 1.27;
            margin-bottom: 32px;
        }

        p {
            font-size: 16px;
            line-height: 1.88;
            margin-bottom: 40px;
        }

        .article__content {
            text-align: left;
            padding: 64px 20px 20px 56px;
        }

        &--large {
            h3, h2 {
                font-size: 40px;
                line-height: 1.55;
                margin-bottom: 40px;
            }

            p {
                margin-bottom: 0;
            }

            .article__content {
                padding-left: 30px;
            }
            .article__image{
                height: 645px;
            }
        }
    }

    .section-article-featured {
        padding-bottom: 140px;

        & + .section-article-featured:last-of-type {
            padding-bottom: 140px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .article-featured {
        &--large {
            .article__content {
                padding-left: calc(50vw - 37.5rem + 15px);
                padding-right: 0;
                padding-top: 40px;
            }
            .article__image{
                height: 700px;
            }
        }

    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .article-featured {
    }
}
