// Component : page-header

.page-header{
	display: block;
	min-height: 70px;
	.switcher-language{
		position: relative;
		margin-right: 8px;
		top: 15px;
		width: 30px;
		&:hover{
			cursor: pointer;
		}
		.switcher-trigger{
			strong{
				font-weight: 600;
			}
		}
		.dropdown{
			box-shadow: none;
			border-radius: 0;
		}
		.switcher-options{
			.langage {
				&:after{
					content:'';
					position: absolute;
					top: 10px;
					right: 0;
					width: 0;
					height: 0;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-top: 6px solid black;
					transform: rotate(0);
					transition: 0.4s ease;
				}
			}
			.dropdown{
				position: relative;
				opacity: 0;
				visibility: hidden;
				transition: 0.4s ease;
				left: -12px;
				width: 45px;
				top: -5px;
				padding: 5px 15px;
				background: white;
			}
			&.active{
				.langage {
					&:after{
						transform: rotate(-180deg);
						transition: 0.4s ease;
					}
				}
				.dropdown{
					top: 0;
					opacity: 1;
					visibility: visible;
					transition: 0.4s ease;
				}
			}
		}
	}
}
.cp-page-header {
	position: relative;
	margin-bottom: 90px;
	.breadcrumbs{
		display: none;
	}
	h1 {
		font-size: 30px;
		font-weight: 700;
		line-height: 1.27;
		margin: 0 0 32px;
		color: #12282d;
	}

	.link-play {
		display: flex;
		align-items: center;
		justify-content: center;
		vertical-align: middle;
		color: #12282d;
		font-size: 16px;
		font-weight: 600;
		transition: opacity 0.3s;

		svg {
			width: 44px;
			height: 44px;
			flex-shrink: 0;
			margin-right: 6px;
		}

		&:hover {
			opacity: 0.8;
		}
	}

	.cp-page-header__content {
		min-height: 354px;
		position: relative;
		z-index: 2;
		padding: 40px 25px 20px;
		margin-bottom: 50px;
		text-align: center;
	}

	.cp-page-header__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 354px;
		background-size: cover;
		background-position: center center;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			width: 100%;
			height: 100%;
			background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.7) 39%, rgba(255, 255, 255, 0) 100%);
		}

		.svg-mask {
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 100%;
			z-index: 1;
			min-height: 32px;
			height: auto;
		}
	}
}

.search {
	position: relative;
	z-index: 1;

	.form__controls {
		position: relative;
		overflow: hidden;
		border-radius: 100px;
		box-shadow: 0 10px 25px 0 rgba(0, 114, 134, 0.15);
	}

	.search__field {
		background: #007286;
		border-radius: 100px;
		width: 100%;
		max-width: 100%;
		border: none;
		box-shadow: none;
		height: 63px;
		line-height: 63px;
		padding: 0 90px 0 60px;
		font-size: 20px;
		font-weight: 300;
		font-style: normal;
		color: #fff;
		margin-bottom: 0;
		box-sizing: border-box;
	}

	input:-ms-input-placeholder,
	input::-ms-input-placeholder,
	input::placeholder {
		color: rgba(255, 255, 255, 0.8);
		font-style: italic;
	}
	input::-ms-input-placeholder {
		/* IE Edge */
		color: #fff;
		color: transparent;
		color: #fff;
	}

	.search__btn {
		position: absolute;
		top: 50%;
		right: 20px;
		color: #fff;
		transform: translateY(-50%);
		line-height: 42px;
		height: 42px;
		padding: 0 0 0 10px;
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 700;
		background: none;
		border: none;
		border-left: 1px solid rgba(255, 255, 255, 0.7);
	}

	.ico-magnifier {
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 0;

		svg {
			width: 23px;
			height: 23px;
		}
	}

	&--lightblue {
		.search__field {
			background: $blue-viking;
		}
	}
}



// --TABLET
@media screen and #{breakpoint(medium up)} {
	.cp-page-header {
		.cp-page-header__image {
			left: 50%;
			width: 50%;
			height: 100%;
			overflow: hidden;

			.svg-mask {
				height: calc(100% + 1px);
				width: auto;
				min-width: 100%;
			}

			&:before {
				background: rgba(18, 40, 45, 0.15);
			}
		}

		h1 {
			font-size: 30px;
			line-height: 1.5;
			margin: 0 0 32px;
		}

		.cp-page-header__content {
			padding: 50px 15px 16px;
			margin-bottom: 43px;
			min-height: 277px;
			max-width: 380px;
		}
		.link-play{
			margin-top: 30px;
		}
	}

	.search {
		padding: 0 15px;

		.search__field {
			padding: 0 130px 0 120px;
		}

		.search__btn {
			right: 62px;
		}

		.ico-magnifier {
			left: 55px;
		}

		&.search--shrink-desktop {
			padding: 0 60px;
		}
	}
}

// --DESKTOP
@media screen and (min-width: 1280px) {
	.page-header{
		// min-height: 95px;
		.switcher-language{
			top: 17px;
			.switcher-options{
				.dropdown{
					width: 36px;
					padding: 5px 10px;
					top: -10px;
					left: -9px;
					opacity: 0;
				}
				&.active{
					.dropdown{
						top: 0;
						opacity: 1;
						visibility: visible;
						transition: 0.4s ease;
					}
				}
			}
		}
	}

	.cp-page-header {
		h1 {
			font-size: 48px;
			line-height: 1.33;
			margin: 0 0 12px;
		}

		.cp-page-header__content {
			padding: 88px 15px 20px;
			margin-bottom: 0;
			min-height: 527px;
			max-width: 520px;
		}
	}
	.search {
		padding: 0 15px;

		.search__field {
			padding: 0 150px 0 128px;
			height: 121px;
			line-height: 121px;
			font-size: 28px;
		}

		.search__btn {
			width: 122px;
			right: 0;
			line-height: 71px;
			height: 71px;
			text-align: center;
			padding: 0;
			font-size: 28px;
		}

		.ico-magnifier {
			left: 59px;
			svg {
				width: 34px;
				height: 34px;
			}
		}
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
	.cp-page-header {
		.cp-page-header__content {
			padding-left: 0;
			padding-right: 0;
		}
		.switcher-language {
			.switcher-options {
				&:after {
					content: '';
					position: absolute;
					top: 10px;
					right: -16px;
					width: 0;
					height: 0;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-top: 6px solid black;
					transform: rotate(0);
					transition: 0.4s ease;
				}
				.dropdown {
					position: relative;
					opacity: 0;
					visibility: hidden;
					transition: 0.4s ease;
					left: -12px;
					width: 45px;
					top: -5px;
					padding: 5px 15px;
					background: white;
				}
				&.active {
					&:after {
						transform: rotate(-180deg);
						transition: 0.4s ease;
					}
					.dropdown {
						top: 0;
						opacity: 1;
						visibility: visible;
						transition: 0.4s ease;
					}
				}
			}
		}

	}
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
