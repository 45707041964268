// Component : product

// a la demande de benjamin CSS > update classe [figure img] #67269
figure img {
  margin: auto auto 1rem auto;
  display: block;
}

.product-items {
  list-style-type: none;

  .product-item-link .row {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.page-products{
  .products {
    .search {
      margin-bottom: 60px;

      &__field {
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        padding: 20px 45px;

        &::placeholder {
          opacity: 0;
        }
      }

      &__btn {
        width: 70px;
        font-size: 18px;
        line-height: 30px;
        height: 30px;
      }

      .ico-magnifier {
        left: 20px;

          svg {
            width: 20px;
            height: 20px;
          } 
      }
    }
  }

  .product{
    margin: 0;
    .product-image-photo{
      margin: 0;
    }
  }
}

.catalog-category-view{
  .wrapper{
    .wrapper__inner{
      padding-top: 0;
      display: flex;
      flex-direction: column-reverse;
    }
    .product-items{
      list-style: none;
    }
  }
  .modes{
    display: none;
  }
  .limiter{
    margin-bottom: 20px;
    max-width: 73rem;
    // font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;

    .label{
      background-color: transparent;
      color: black;
      // font-weight: 700;
      font-size: 14px;
      padding: 0;
      text-transform: uppercase;
    }
    .control{
      display: inline-block;
      font-weight: 700;
      font-size: 14px;
      &:hover{
        cursor: pointer;
      }
      select{
        position: relative;
        border: none;
        width: 60px;
        top: -2px;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
  .toolbar-amount{
    display: none; 
  }

  .toolbar-top{
    position: relative;
    margin: 0 auto;
    max-width: 73rem;

    .toolbar-products{
      width: calc(100% + 1.875em);

      // pagination
      nav {
        display: none;
      }
    }
    .toolbar-sorter {
      top: 0;
      right: 0;
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 30px;
      width: 100%;
      label{
        display: inline-block;
        font-weight: 700;
      }
      select{
        position: relative;
        text-transform: uppercase;
        font-size: 14px;
        margin: 0 5px;
        font-weight: 600;
        background: #f5f5f5;
        height: 40px;
        border-radius: 20px;
        padding: 0 45px 0 24px;
        width: 100%;
        width: auto;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: none;
        &:hover{
          cursor: pointer;
        }
      }
      .select-container{
        position: relative;
        &:hover{
          cursor: pointer;
        }
        &:after{
          position: absolute;
          top: 50%;
          right: 30px;
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 4px 0 4px;
          border-color: #12282d transparent transparent transparent;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          transition: transform 0.3s;
        }
      }
      a{
        color: #007286;
        font-weight: 700;
        font-size: 14px;
        display: block;
        margin-top: 10px;
        display: inline;
      }
    }
  }

  .toolbar-bottom {
    .toolbar-total,
    .toolbar-sort {
      display: none;
    }
  }
}

.catalog-product-view{
    padding-bottom: 70px;
  .wrapper .wrapper__inner{
    padding-top: 0;
  }

  .breadcrumbs{
    display: none;
  }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
  .page-products{
    &.page-layout-1column {
      .page-main{
        .products-grid {
          .product-item {

          }
        }
        .page-products{
          .product-item-info{
            width: auto;
            .product-item-photo{
              width: 37%;
            }
          }
        }
      }
    }
  }
  .catalog-product-view,
  .catalog-category-view {
    .wrapper {
      .wrapper__inner {
        padding-top: 0;
      }
    }
  }
  .catalog-category-view{
    .limiter{
      margin-bottom: 40px;
    }
    .toolbar-top{
      .toolbar-sorter{
        position: absolute;
        margin: 0;
        width: auto;
        a{
          margin: 0;
          display: inline-block;
        }
      }
    }
  }
  .catalog-product-view{
    padding-bottom: 0;
    .breadcrumbs{
      display: block;
    }
  }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
