.catalogsearch-result-index {
    .intro-alt {
        height: auto;
    }

    .intro__background {
        background-image: none !important;
        min-height: inherit !important;
    }

    .intro .intro__inner {
    }

    .intro .page-title {
        color: $black;
        margin-bottom: 0;
    }

    .toolbar-bottom {
        .toolbar-total,
        .toolbar-sort {
            display: none;
        }
    }

    .search.results {
        overflow: hidden;

        // tabs
        .search-tabs-results {
            overflow-x: visible;
            display: flex;
            position: relative;
            border-bottom: 1px solid $light-blue;
            --n: 1;
            transition: transform .5s ease-out;
            transform: translate(calc(var(--i, 0)/7*-100%));
            width: calc(var(--n)*100%);

            &-item {
                list-style-type: none;
                text-align: center;
                padding: 15px 0;
                flex-shrink: 0;

                a {
                    color: $black;
                }

                &:not(:last-child) {
                    margin-right: 30px;
                }

                .nbr {
                    color: $light-blue;
                }

                &:hover {
                    cursor: pointer;
                }

                &.is-active {
                    font-weight: 800;
                    border-bottom: 3px solid $light-blue;
                }
            }

            #magic-line {
                display: none;
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100px;
                height: 4px;
                background: $light-blue;
                transition: 0.4s;

                &:after {
                    content: url('../images/francisbio-tab.svg');
                    position: absolute;
                    z-index: 1;
                    bottom: -7px;
                    right: 0;
                    width: 80px;
                    height: auto;
                }
            }
        }

        // toolbar
        .toolbar {
            margin-bottom: 20px;
            width: 100%;

            &-top {
                .toolbar-products {
                    // display: none;
                }
            }

            &-total,
            .alphabet {
                display: none;
            }

            &-sort {
                justify-content: space-between;
            }
        }
        
        // override foundation css
        .tabs-panel {
            padding: 0;
        }

        .doc,
        .tile {
            box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);
            margin-bottom: 32px;
        }

        .list {
            .doc {
                .doc-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                &__label {
                    margin-left: 0.5em;
                    margin-bottom: 0.5em;
                    align-self: flex-start;
                    display: inline-block;
                }

                &__title {
                    margin-top: 0;
                    width: 100%;
                }
            }

            .products-item {
                .main {
                    padding: 0;
                }

                // .description {
                //     padding: 0;
                // }
            }
        }

        .doc {
            &:hover {
                .doc__image {
                    img {
                        transform: scale(1.15);
                    }
                }
            }

            &-content {
                padding-top: 1em;
                padding-bottom: 1em;
            }

            &__image {
                max-width: 132px;
                overflow: hidden;

                img {
                    transform: scale(1);
                    will-change: transform;
                    transition: 0.4s;
                }
            }

            &__title {
                font-size: 13px;
                color: $black;
                line-height: 20px;
                margin-top: 15px;
                margin-bottom: 0;
                padding: 0 0.5em;
            }

            &__label {
                color: $light-blue;
                border: 1px solid $light-blue;
                border-radius: 30px;
                font-size: 0.8rem;
                padding: 0 10px;
                display: none;
            }
        }

        .tile {
            display: block;
            position: relative;
            padding: 1em;
            color: $black;
            overflow: hidden;

            &__title {
                font-weight: 700;
                margin: 0 0 5px;
            }

            &__desc {
                font-size: 13px;
                margin-bottom: 0;
            }

            &__link {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 100%;
                left: 0;
                transform: translate3d(0, 100%, 0);
                will-change: transform;
                transition: 0.4s;

                &:after {
                    content: url('../images/svg/arrow-right-white.svg');
                    margin-left: 10px;
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                    border-radius: 50%;
                    border: 2px solid $white;
                }
            }

            &:hover {
                .tile__link {
                    top: 0;
                    transform: translate3d(0, 0, 0);
                }
            }
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .catalogsearch-result-index {
        .search.results {
            .search-tabs-results {
                overflow: hidden;
                transform: none;
                width: auto;

                &-item {
                    width: calc(100% / 5);

                    &:not(:last-child) {
                        margin-right: 0;
                    }
                }
            }

            .list {
                .doc {
                    flex-direction: row-reverse;
                    align-items: center;

                    &__label {
                        margin-right: 20px;
                    }
                }
            }

            .doc {
                &__label {
                    display: inline-block;
                }

                &__title {
                    padding-right: 3em;
                }
            }

            .tile {
                padding: 1em 2em;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .catalogsearch-result-index {
        .search.results {
            .search-tabs-results {
                overflow-x: auto;

                &-item {
                    &.is-active {
                        border-bottom: none;
                    }
                }

                #magic-line {
                    display: block;
                }
            }
        }
    }
}