// Component : section-wysiwyg
.section-wysiwyg,
.section-products-tabs,
.section-reader,
.section-image-map{
  margin: 40px 0 60px;
  &.full-width{
    .lookbook &{
      margin-left: -50vw;
      margin-right: -50vw;
    }
  }
  h1, h2 {
    font-size: 30px;
    line-height: 1.55;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    text-rendering: optimizeLegibility;
    margin-top: 0;
  }
  h2 {
    font-size: 26px;
  }
  .section-group{
    ul {
      margin: 1rem 0;
      li {
        position: relative;
        list-style: none;
        padding-left: 30px;
        line-height: 27px;
        &:before {
          position: absolute;
          top: 8px;
          left: 0;
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #007286;
        }
      }
    }
  }
}
.section-products-tabs{
  margin: 0;

}
.section-wysiwyg {
  ul {
    overflow: initial !important;
    height: auto !important;

    li {
      position: relative;
      list-style: none;
      padding-left: 30px;
      line-height: 27px;
      
      &:before {
        position: absolute;
        top: 8px;
        left: 0;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #007286;
      }
    }
  }
  form{
    ul {
      li {
        &:before {
          display: none;
        }
      }
    }
    [type='checkbox']:not(:checked),
    [type='checkbox']:checked {
      position: relative;
      left: 0;
      width: auto;
      display: inline-block;
      float: left;
      width: auto!important;
    }
    .hs-fieldtype-booleancheckbox{
      margin-bottom: 30px!important;
      min-height: auto;
    }
    .legal-consent-container{
      .hs-fieldtype-booleancheckbox{
        margin-top: 20px;
      }
    }
  }
}

.article--expert-opinion::before {
  display: inline-block;
  margin-right: 14px;
  margin-bottom: -10px;
  content: "";
  background-image: url(../images/svg/icon-documents-quote.svg);
  background-size: auto;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
}

.article--author::before {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: -6px;
  content: "";
  background-image: url(../images/svg/icon-documents-authors.svg);
  background-size: auto;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
}

.article--reference::before {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: -6px;
  content: "";
  background-image: url(../images/svg/icon-documents-scientific-journal.svg);
  background-size: auto;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
}

figure img {
  margin: auto auto 1rem auto;
  display: block;
} 

figure {
  margin: 0;
  padding: 0;

  figcaption {
    margin:0 0 $global-margin 0;
    font-style: italic;
    font-size: 90%;
  }
}

figure.post-figure {
    background: #f5f5f5;
    padding:1em;
    margin-bottom:1em;
  }
.post-figure figcaption {
        color: #8a8a8a;
        font-size: 0.8em;
        margin-bottom: 0;
          }
.post-figure figcaption:before {
    display: block;
    vertical-align: middle;
    font-size: 0;
    content: '';
    width: 34px;
    height: 1px;
    background: #8b9ca0;
    margin: 20px 0 10px 0 ;
    }


@media screen and #{breakpoint(medium up)} {
  figure {
      padding: $global-padding*2;
  }
  figure.post-figure {
      margin: 2em 0;
    }
  .post-figure figcaption {
      margin-bottom: 1em;
  }
}

@media screen and #{breakpoint(large up)} {
  figure {
    padding: $global-padding*2;
  }

  .section-wysiwyg,
  .section-products-tabs {
    h1, h2 {
      font-size: 40px;
      line-height: 1.55;
    }
    h2 {
      font-size: 33px;
    }
  }

}

