#switchCountryModal {
  .main-title {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 15px;
    padding: 30px;
    color: #12282d;
  }

  .subtitle {
    font-size: 22px;
    line-height: 1.88;
    font-weight: 700;
    color: #007286;
    border-bottom: 1px solid #F8F8F8;
    margin: 0 0 20px;
    padding-bottom: 10px;
  }

  ul {
    list-style: none;

    li {
      padding: 10px;

      .store-switcher-store {
        display: block;
      }

      .store-flag {
        display: inline-block;
        margin-right: 15px;
        width: 31px;
        height: 31px;
      }

      .store-name {
        color: #12282d;
        font-size: 16px;
      }
    }

    li:hover {
      background-color: #F8F8F8;
    }
  }

  .store-area + .store-area {
    margin-top: 25px;
  }
}

.store-switcher-button-container {
  list-style: none;
  margin: 25px 0px 35px 0;
  cursor: pointer;

  .switcher {
    border: 2px solid #007286;
    border-radius: 50px;
    display: inline-block;

    .store-switcher-button {
      width: 100%;
      padding: 5px;

      .store-flag {
        float: left;
      }

      span {
        white-space: nowrap;
        text-align: center;
        width: 100%;
        display: block;
        line-height: 31px;
        padding-left: 35px;
        padding-right: 5px;
        font-size: 16px;
        font-weight: 600;
        color: #09292E;
      }
    }
  }
}

.follow-us-small {
  text-decoration: none;
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
  height: auto;
  display: inline-block;
  background-color: transparent;
  color: #09292E;
  width: auto;
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
  margin: 0;
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
  #switchCountryModal {

    min-height: 600px;
    min-width: 800px;

    .row-stores-switcher {
      margin-bottom: 55px;
    }

    .main-title {
      font-size: 40px;
    }

    .store-area {
      .subtitle {
        text-align: center;
      }
      ul {
        padding-left: 75px;
      }
    }

    .store-area + .store-area {
      margin-top: 0;
    }
  }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {

}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
