.imp-tooltips-container h3 {
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5em;
}

.imp-tooltips-container h4 {
  font-size: 1.3em;
  font-weight: bold;
  border-bottom: 1px solid #fad6d1;
  padding-bottom: 0.5em;
}

.imp-tooltips-container .subtitle {
  font-size: 0.8rem;
  color: #f17e6f;
  background: #fefefefa;
  padding: 4px 16px;
  text-transform: uppercase;
}

.btn-tooltip {
  width: 100%;
  font-size: 0.75rem;
  background-color: #8ccdd9;
  color: #12282d;
  display: inline-block;
  vertical-align: middle;
  margin: 0.5rem 0 0.5rem 0;
  padding: 0.85em 1.85em;
  border: 1px solid transparent;
  border-radius: 50px;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: bold;
  -webkit-appearance: none;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}

.btn-tooltip:hover, .btn-tooltip:focus {
    color: #efefef;
    background-color: #50b7c9;
}
