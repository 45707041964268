// Component :

.price {
  font-family: Arial;
}

#block-shipping {
  display: none;
}

.hide-if-empty {
  display: none;
}

.cart-empty {
  margin-bottom: 130px;

  p {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
  }
}

.checkout-cart-index {

  .loader {
    text-align: center;
    color: #8B9CA0;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
  }

  h2 {
    color: #12282D;
    font-family: "Raleway", sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 64px;
    margin-bottom: 0;
  }

  .column.main {
    vertical-align: top;
  }

  .multicheckout.action {
    display: none;
  }

  .cart-container {
    display: none;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  #shopping-cart-table {

    thead {
      display: none;
    }


    th.col {
      color: #8B9CA0;
      font-family: "Raleway", sans-serif;
      font-size: 14px;
      line-height: 1.14;
      font-weight: 400;
    }


    table, th, tr, td, tbody {
      font-family: "Raleway", sans-serif;
      border: 0px solid #f5f5f5;
    }

    .item-info {
      display: flex;
      flex-direction: column;
    }

    .product-item-photo {
      padding: 0;
    }

    .product-image-photo {
      width: 100%;
      max-width: 400px;
      margin-bottom: 15px;
    }

    .product-image-wrapper {
      background-color: #F5F5F5;
      padding: 0 !important;
      height: 140px;
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .product-item-name {
      a {
        margin: 0 0 7px;
        color: #007286;
        font-family: "Raleway", sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.2;
      }
    }

    .item-price,
    .cart-price,
    .price-nc {
      font-size: 18px;
      line-height: 1.17;
      font-weight: 700;
    }

    .references {
      margin: 10px 0 18px;
      color: #12282d;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.14;
    }

    .cart-price {
      font-weight: 800;
    }

    .item-options {
      dt {
        color: #007286;
        font-family: "Raleway", sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
      }

      dd {
        color: #12282D;
        font-family: "Raleway", sans-serif;
        font-size: 18px;
        font-weight: bold;
        line-height: 21px;
        margin-top: 24px;
      }
    }

    .col.qty {
      max-width: 80px;
    }

    input.qty {
      width: 50px;
      color: #12282D;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      font-family: "Raleway", sans-serif;
      line-height: 19px;
      margin-bottom: 0;
    }

  }

  .cart.main.actions {
    margin-bottom: 140px;
    margin-top: 54px;
  }

  .label {
    background-color: transparent;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #12282D;
    padding: 0;
  }

  .subtotal-label {
    background-color: transparent;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #12282D;
    padding: 0;
    margin-bottom: 10px;
  }

  .action-edit {
    &:after {
      content: "\e902";
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      color: #007286;
      margin-right: 20px;
    }

    span {
      display: none;
    }
  }

  .action-delete {
    &:after {
      content: "\e903";
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      color: #007286;
    }

    span {
      display: none;
    }
  }

  .button-container {
    .ask-quote {
      margin-top: 40px;
    }
  }

  #cart-totals {
    background-color: #f5f5f5;
    padding: 40px;

    table, th, tr, td, tbody {
      background-color: #f5f5f5 !important;
      font-family: "Raleway", sans-serif;
      text-align: left;
      border: 0px solid #f5f5f5;
      font-size: 16px;
      font-weight: bold;
      color: #12282D;
    }

    .table-caption {
      font-size: 28px;
      font-weight: bold;
      line-height: 33px;
    }

  }

  ul.checkout {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .ask-quote {
      background-color: #007286;
      width: 100%;
      text-align: center;
      padding: 40px;

      h3 {
        color: $white;
        font-size: 28px;
        font-weight: bold;
        line-height: 33px;
        font-family: "Raleway", sans-serif;
      }

      p {
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: $white;
        font-family: "Raleway", sans-serif;
      }
    }
  }
}

.checkout-index-index {
  .field {
    label {
      color: #8B9CA0;
      font-family: "Raleway", sans-serif;
      font-size: 14px;
      line-height: 16px;
      background-color: transparent;
    }
  }

  .label span {
    color: #8B9CA0;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    line-height: 16px;
    background-color: transparent;
  }

  .fieldset {
    border: 0px solid #ffffff;
  }

  .step-title {
    color: #12282D;
    font-family: "Raleway", sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 64px;
    margin-bottom: 40px;
    border: 0 solid #ffffff;
  }
}

.summary-box {
  .ask-quote {
    text-align: center;
    margin: 40px 0;
  }
}

.checkout-box.summary-box {
  padding-bottom: 0;
  margin-bottom: 20px;

}

.customer-support-container {
  padding-top: 3em;

  .customer-support-illustration {
    max-width: 230px;
  }
}

.cms-block p {
  font-size: 11px;
  color: #8B9CA0;
}


// --TABLET
@media screen and #{breakpoint(medium up)} {

  #form-validate {
    min-height: 600px;
  }

  .checkout-cart-index {
    h2 {
      font-size: 48px;
      margin-bottom: 15px;
    }
  }

  #shopping-cart-table {

    tbody:before {
      content: "@";
      display: block;
      line-height: 20px;
      text-indent: -99999px;
    }
  }

  .checkout-cart-index #shopping-cart-table {
    thead {
      display: table-header-group;

      th.col.qty,
      th.col.subtotal {
        text-align: center;
      }
    }

    .item-info {
      display: table-row;
      flex-direction: inherit;

      td {
        text-align: start;
        margin: 0;

        &.col.subtotal {
          text-align: center;
          min-width: 100px;
        }
      }

      .product-item-photo img {
        width: 100px;
      }
    }
  }

  .checkout-cart-index {
    .button-container {
      .ask-quote {
        margin-top: 0;
        display: inline-block;
        margin-left: 15px;
      }

      .action.update {
        margin-right: 15px;
      }
    }
  }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
  .checkout-cart-index #shopping-cart-table {
    .product-item-photo img {
      width: 118px;
    }
  }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {

}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {

}
