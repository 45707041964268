// Component : Section Updates
.section-updates {
	margin: 0 0 100px;
	overflow: hidden;

	.section__body {
		margin: 0 -10px;
		padding-left: 12px;
	}
}

.js-slider-updates {
	padding-bottom: 82px;
	overflow: visible;

	.slick-slide {
		padding: 0 5px;
	}

	.slider__slide {
		position: relative;
		width: 177px !important;
		max-width: calc(100vw - 60px) !important;
		.list-tags-small{
			position: absolute;
			top: 24px;
			left: 28px;
			z-index: 99;
		}
		&:not(.slider__slide--wide){

			.update{

				.update__entry{

					h4, h2{
						color: #12282d;
					}
				}
			}
		}
	}

	.slider__slide--wide {
		width: 320px !important;
		max-width: calc(100vw - 60px) !important;
	}

	.slide--wide {
		width: 320px !important;
		max-width: calc(100vw - 60px) !important;
	}

	.update {
		display: flex;
		flex-direction: column;
		height: 237px;
		text-decoration: none;
		box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);
		overflow: hidden;

		&:hover {
			.update__image {
				transform: scale(1.1);
			}
		}

		.update__entry {
			background: #fff;
			padding: 16px 15px 16px;
			min-height: 116px;
			flex-shrink: 0;
		}

		.update__image-holder {
			flex-grow: 1;
			background-size: cover;
			position: relative;
			overflow: hidden;
		}

		.update__image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: 50%;
			transition: transform 0.3s;
		}

		h4,
		h6, h2 {
			margin: 0;
			font-weight: 700;
		}

		h6 {
			color: #12282d;
			font-size: 14px;
			font-weight: 700;
			line-height: 1.5;
		}

		h4, h2 {
			font-size: 16px;
			line-height: 1.5;
		}
	}

	.update--featured {
		position: relative;
		.update__image-holder {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center center;
		}

		.update__entry {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			min-height: 0;
			padding: 0 22px 24px;
			background: none;
		}

		h4,
		h6, h2 {
			color: #fff;
		}
	}

	.slick-list {
		overflow: visible;
	}
}

.slick-dots {
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	list-style-type: none;
	z-index: 3;
	display: flex;
	align-items: center;
	margin-left: 0;

	li {
		padding: 0 5px;
		font-size: 0;
		line-height: 1;
	}

	button {
		border: none;
		appearance: none;
		border-radius: 0;
		background: #8b9ca0;
		height: 4px;
		padding: 1px 0;
		width: 40px;
		font-size: 0;
		line-height: 0;
		text-indent: -4004px;
		background-clip: content-box;

		&:focus {
			outline: none;
		}
	}

	.slick-active button {
		background: #007286;
		background-clip: content-box;
	}
}
.section-updates--alt {
	margin-bottom: 0;
	.js-slider-updates .slick-list {
		margin-left: -5px;
	}
}

.update-featured {
	display: block;
	height: 237px;
	text-decoration: none;
	box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);
	overflow: hidden;
	position: relative;

	h4, h2 {
		font-size: 16px;
		line-height: 1.5;
		margin-bottom: 0;
		color: inherit;
		font-weight: 700;
	}

	.update-meta {
		margin-bottom: 12px;
		ul {
			display: flex;
			align-items: center;
			list-style-type: none;
		}

		li {
			position: relative;
		}

		li + li:before {
			display: inline-block;
			vertical-align: middle;
			visibility: 0;
			content: '';
			background: #fff;
			height: 1px;
			width: 34px;
			margin: 0 14px;
		}

		span {
			color: #fff;
			font-size: 13px;
			font-weight: 600;
			text-transform: uppercase;
		}
	}

	&:hover {
		.update__image {
			transform: scale(1.1);
		}
	}

	.update__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: transform 0.3s;
		background-size: cover;
	}

	.update__image-holder {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center center;
	}

	.update__entry {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		min-height: 0;
		padding: 0 22px 24px;
		background: none;
		color: #fff;
	}
}

.article-listings {
	.article-excerpt {
		position: relative;
		margin-bottom: 40px;
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.section-updates {
		padding-left: 30px;
		padding-right: 30px;
	}

	.js-slider-updates {
		padding-bottom: 64px;

		.slick-slide {
			padding: 0 10px;
		}

		.slider__slide {
			width: 275px !important;
			max-width: 275px !important;
		}

		.slider__slide--wide {
			width: 497px !important;
			max-width: 497px !important;
		}

		.slide--wide {
			width: 497px !important;
			max-width: 497px !important;
		}

		.update {
			height: 370px;

			h6 {
				font-size: 16px;
			}
		}
	}

	.slick-dots {
		bottom: 32px;
	}

	.update-featured {
		height: 500px;
		h4, h2 {
			font-size: 28px;
			line-height: 1.29;
		}

		.update__entry {
			padding: 32px 46px;
		}
	}

	.section-updates--alt .section__body {
		margin: 0 -46px;
	}

	.article-listings {
		.article-excerpt {
			margin-bottom: 80px;
		}
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.section-updates {
		padding-left: 0;
		padding-right: 0;

		.section__head {
			margin-bottom: 70px;
		}

		.section__body {
			margin: 0;
			padding-left: 0;
		}
	}

	.js-slider-updates {
		padding-bottom: 122px;

		.slider__slide {
			width: 374px !important;
			max-width: 374px !important;
		}

		.slider__slide--wide {
			width: 674px !important;
			max-width: 674px !important;
		}

		.slide--wide {
			width: 974px !important;
			max-width: 974px !important;
		}

		.update {
			height: 500px;

			h4, h2 {
				font-size: 28px;
				line-height: 1.29;
			}

			h6 {
				font-size: 20px;
				line-height: 1.65;
			}

			.update__entry {
				padding: 24px 32px 10px;
				min-height: 147px;
			}
		}

		.update--featured {
			.update__entry {
				padding: 0 32px 32px;
			}
		}

		.slick-dots {
			bottom: 80px;
		}
	}

	.section-updates--alt {
		padding-bottom: 0;
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
