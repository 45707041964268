// Component : slider bio

.slider-bio-modal,
.slider-video-modal{
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    margin: 0!important;
    padding: 78px 18px 48px;
    top: 0;
    left: 0;
    z-index: 1000;
    display: none;

    .mfp-close {
        border: none;
        position: fixed;
        top: auto;
        right: 25px;
        opacity: 1;
        font-size: 0;
        line-height: 0;
        padding: 5px;
        z-index: 3;
        background: url(../images/svg/ico-close.svg) 0 0 no-repeat;
        width: 18px;
        height: 18px;
        margin-top: 20px;
        margin-right: 16px;
        cursor: pointer;
    }
    .section-bio{
        padding: 65px 45px 0;
    }
    .slick-slide{
        background: $white;

    }
    iframe{
        position: absolute;
        height: 100%;
        width: 100%;
    }
}
.slider-video-modal {
    padding: 60px 20px 60px;
    .section-bio{
        padding: 0;
    }
    .mfp-close{
        background-size: 100%;
        background: url(../images/svg/ico-close-white.svg) 0 0 no-repeat;
        width: 28px;
        height: 28px;
        top: -10px;
        right: 5px;
    }
}
.mfp-content {
    vertical-align: top;
}
.slider-bio {
    position: relative;
    background: white;
    height: 100%;
    width: 100%;
    overflow: scroll;
    &__push4 {
      background: none;
    }
    .slider__clip{
        max-height: 100%;
    }
    .slick-arrow {
        position: absolute;
        bottom: -68px;
        z-index: 2;
        font-size: 0;
        line-height: 0;
        text-indent: -4004px;

        &:focus {
            outline: none;
        }
    }
    .slick-prev {
        background: url(../images/svg/slide-arrow-left.svg) 0 0 no-repeat;
        width: 26px;
        height: 28px;
        background-size: 100% 100%;
        left: 40px;
        bottom: 70px;
        position: fixed;
    }
    .slick-next {
        background: url(../images/svg/slide-arrow-right.svg) 0 0 no-repeat;
        width: 26px;
        height: 28px;
        background-size: 100% 100%;
        bottom: 70px;
        position: fixed;
        right: 40px;
    }
}

.section-bio {
    &__push4 {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%,-50%);
      .video__content {
        width: 100%;
      }
    }
    img {
        width: 100%;
    }
    .section__content{
        padding-bottom: 60px;
    }
    blockquote {
        position: relative;
        color: #8b9ca0;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        text-align: center;
        padding: 45px 0 0;
        margin: 32px 0 39px;
        border: none;

        &:before {
            position: absolute;
            top: 0;
            left: 50%;
            width: 25px;
            height: 20px;
            content: '';
            background: url(../images/svg/quote.svg) 0 0 no-repeat;
            background-size: cover;
            transform: translateX(-50%);
        }
    }
    h3 {
        color: #12282d;
        font-size: 22px;
        line-height: 1.36;
        font-weight: 700;
        margin: 0 0 23px;
    }

    p {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 0;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .slider-bio-modal {
        padding: 140px 100px 175px;
        padding: 0;
        margin-top: 200px;

        .mfp-close {
            right: 23px;
            width: 40px;
            height: 40px;
            background-size: 100% 100%;
            margin-top: 50px;
            svg {
            }
        }
    }
    .slider-bio {
        overflow: visible;
        width: 85%;
        //height: 75%;
        // height: 838px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .slick-arrow {
            bottom: -140px;
        }
        .slick-prev {
            left: 40px;
            background: url(../images/svg/slide-arrow-left.svg) 0 0 no-repeat;
            width: 51px;
            height: 46px;
            background-size: 100% 100%;
            position: absolute;
        }
        .slick-next {
            right: 40px;
            background: url(../images/svg/slide-arrow-right.svg) 0 0 no-repeat;
            width: 51px;
            height: 46px;
            background-size: 100% 100%;
            position: absolute;
        }
    }

    .slider-video-modal {
        .mfp-close {
            top: -60px;
            right: -15px;
        }
    }

    .section-bio {
        display: flex;

        blockquote {
            font-size: 16px;
            line-height: 1.75;
            margin-bottom: 0;

            &:before {
                position: absolute;
                top: 0;
                left: 50%;
                width: 40px;
                height: 31px;
                content: '';
                background: url(../images/svg/quote.svg) 0 0 no-repeat;
                background-size: cover;
                transform: translateX(-50%);
            }
        }
        h3 {
            color: #12282d;
            font-size: 40px;
            line-height: 1.18;
            margin: 0 0 33px;
        }

        p {
            font-size: 16px;
            line-height: 1.88;
        }

        .section__aside {
            width: 39%;
        }
        .section__content {
            padding-right: 0;
            padding-left: 64px;
            width: 61%;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {

    .slider-video-modal {
        padding: 0;
        .mfp-close{
            right: -90px;
            width: 48px;
            height: 48px;
            top: 0;
            z-index: 1004;
        }
    }

    .slider-bio {
        //top: calc(50% - 55px);
        .section__aside {
            width: 29%;
        }
        .slick-arrow {
            bottom: 50px;
        }
        .slick-prev {
            left: 90px;
        }
        .slick-next {
            right: 60px;
        }
    }
    .slider-bio-modal {
        padding: 140px 100px 175px;
        padding: 0;
        margin-top: 200px;
        .mfp-close {
            right: 40px;
        }
        .section-bio {
            padding: 105px;
            padding-bottom: 130px;
        }
        .slick-slide {
            height: 838px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .slider-bio {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .slider-bio {
    }
}
