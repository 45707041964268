// don't know where to put global sections style
body.img-zoom-active {
    .zoom-is-active {
        margin-top: 0;
    }
}

.section__image {
    position: relative;
    display: flex;
    cursor: zoom-in;
    
    img {
        width: 100%;
        height: auto;
        transform: scale(0.9);
        transition: 0.5s;
    }
    
    
    &.zoom-is-active {
        background-color: rgba(0, 0, 0, 0.8);
        cursor: zoom-out;
        padding: 1em;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 100;
        
        img {
            transform: scale(1);
            background: #ffffff;
            padding: 24px;
        }
    }
}

// Component : section assay
.section-assay {
    padding: 40px 0;

    p {
        font-size: 14px;
        line-height: 2;
    }

    .section__group + .section__group {
        margin-top: 40px;
    }

    .section__head {
        h2 {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 10px;
        }
    }

    .section__image {
        margin-top: 30px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section__image {
        &.zoom-is-active {
            padding: 50px;
            img {
                margin: 0 auto;
            }
        }
    }
    .section-assay {
        padding: 80px 0 40px;

        p {
            font-size: 16px;
            line-height: 1.88;
            margin-bottom: 30px;
        }

        .section__group + .section__group {
            margin-top: 60px;
        }

        .section__head {
            h2 {
                font-size: 32px;
                line-height: 1.16;
                margin-bottom: 24px;

            }
        }

        .section__image {
            margin-top: 0;
        }

    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-assay {

    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-assay {

    }
}
