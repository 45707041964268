// Component : intro
.search {
    ::-webkit-input-placeholder {
        color: #ffffff;
        opacity: 1;
    }
    ::-moz-placeholder {
        color: #ffffff;
        opacity: 1;
    }
    :-moz-placeholder {
        color: #ffffff;
        opacity: 1;
    }
    :-ms-input-placeholder {
        color: #ffffff;
        opacity: 1;
    }
}

.universe-dd .search {
    .search__field,
    .search--lightblue .search__field {
        background: $light-blue; 
    }
}

.universe-ivd .search {
    .search__field,
    .search--lightblue .search__field {
        background: $medium-blue; 
    }
}

.is-blog .search {
    .search__field,
    .search--lightblue .search__field {
        background: $dark-blue; 
    }
}

.intro {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-direction: column;
    height: 300px;

    .js-term-description {
        .morecontent span {
            display: none;
        }

        .morelink {
            display: block;
            font-size: 30px;
            color: $white;
            font-weight: 300;

            &.less {
                transform: rotate(180deg);                
            }
        }
    }
    &.search-bar{
        .section-products-anchor &{
            margin-bottom: 55px;
        }
    }
    .intro__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        .job-single-view &{
            background-image: url('../images/bg-hero-job-offer-apply-small.jpg')!important;
        }
    }

    h1 {
        font-size: 30px;
        font-weight: 700;
        color: #fff;
        line-height: 1.27;
        margin: 0 0 40px;
        max-height: 190px;
        overflow: auto;
    }

    strong {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        line-height: 1.5;
        margin: 0;
    }

    .intro__inner {
        z-index: 1;
        padding: 64px 5px;
        width: 100%;
        text-align: center;
    }

    .search {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translateY(50%);
    }
}

.intro-paging {
    margin-bottom: 0px;
    &.push_cover_2 {
      margin-bottom: 50px;
    }
    .job-single-view &{
        margin-bottom: 0;
    }
    .wordpress-term-view &,
    .homepage &{
        margin-bottom: 90px;
    }
    .homepage &{
        margin-bottom: 100px;
    }
    // .is-blog &{
    //     margin-bottom: 100px;
    // }
    &.cover-3{
        height: 310px;
    }
    /**
    & + .section-video-help{
        margin-top: 45px;
    }
    **/
    &+input+svg+section {
        margin-top: 0px;
    }
    .page-next,
    .page-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        span {
            display: block;
            font-size: 0;
        }

        svg {
            width: 22px;
            height: 20px;
        }

        small {
            display: block;
            color: #fff;
            font-size: 12px;
            line-height: 1.17;
            font-weight: 600;
            margin-top: 14px;
            text-transform: uppercase;
            opacity: 1;

            filter: blur(0);
        }

        &:hover {
            small {
                opacity: 0.8;
            }
        }
    }

    .page-prev {
        left: 12px;
        text-align: left;
    }
    .page-next {
        right: 12px;
        text-align: right;
    }
}

.intro-paging.cover-3 + .section-wysiwyg {
    margin-top: 60px;
}

.intro-paging.cover-3 + .section-video-help {
    margin-top: 60px;
}

.intro-alt {
    height: 310px;
    margin-bottom: 40px;
    .catalogsearch-result-index &{
        margin-bottom: 20px;
        overflow: hidden;
    }
    .intro__inner {
        padding: 0;
    }

    h1 {
        margin-bottom: 16px;
    }

    p {
        font-size: 18px;
        font-weight: 700;
    }
}

.intro--small {
    height: 166px;

    h1 {
        font-size: 30px;
        margin-bottom: 0;
    }
    .intro__inner {
        padding: 20px 0;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .intro-paging {
    }
    .intro{
        &__inner {
            padding: 64px 34px;
        }
        
        &.search-bar{
            .section-products-anchor &{
                margin-bottom: 175px;
            }
        }
        h1{
            max-height: 440px;
            overflow: auto;
        }
    }
    .intro-alt {
        .catalogsearch-result-index &{
            margin-bottom: 50px;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .intro {
        height: 400px;
        .homepage &{
            height: 485px;
            .intro__background {
                min-height: 485px;
            }
        }
        .intro__background {
            min-height: 400px;
            .job-single-view &{
                background-image: url('../images/bg-hero-job-offer-apply-large.jpg')!important;
            }
            .customer-account-login &{
                min-height: auto;
            }
        }
        .intro__inner {
            padding: 72px 20px;
        }

        h1 {
            font-size: 48px;
            margin: 0 0 23px;
        }

        strong {
            font-size: 20px;
            line-height: 1.5;
        }

        .intro__inner {
            padding: 80px 0;

            .columns {
                position: relative;
            }
        }
    }

    .intro-paging {
        /**
        &+.section-video-help{
            margin-top: 90px;
        }
        **/
        &+input+svg+section {
            margin-top: 0px;
        }
        .homepage &{
            margin-bottom: 100px;
            //200px
        }

        // .is-blog &{
        //     margin-bottom: 100px;
        // }
        &.cover-3{
            height: 400px;
            // margin-bottom: 50px;
        }
        .page-prev,
        .page-next {
            svg {
                width: 25px;
                height: 23px;
            }
        }

        .page-prev {
            left: 15px;
        }
        .page-next {
            right: 15px;
        }
    }

    .intro-alt {
        height: 400px;
        margin-bottom: 100px;
        .catalogsearch-result-index &{
            margin-bottom: 50px;
        }
        .breadcrumbs-holder {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    .intro--small {
        height: 264px;

        h1 {
            font-size: 48px;
            margin-bottom: 0;
        }
        .intro__inner {
            padding: 20px 0;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
