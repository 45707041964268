// Component : section-specification
.section-specification,
.section-performances{
    padding: 40px 0;

    p {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 0;
    }

    .section__head {
        margin-bottom: 30px;

        h2 {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-specification {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-specification,
    .section-performances{
        padding: 80px 0 40px;

        p {
            font-size: 16px;
            line-height: 1.88;
        }

        .section__head {
            margin-bottom: 60px;
            h2 {
                font-size: 32px;
                line-height: 1.16;
                margin-bottom: 13px;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-specification {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-specification {
    }
}
