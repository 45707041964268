.section-image-map{
  .position-text-center{
    text-align: center;
  }
  .image-map-container {
    .imp-wrap{
      margin: 0 auto;
      margin-top: 40px;
    }
  }
  .position-text-right,
  .position-text-right{
    display: flex;
    text-align: left;
    .section-text-container,
    .image-map-container{
      flex: 1;
    }
  }
  .position-text-right{
    flex-direction: row-reverse;
  }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {

  .section-image-map {
    .section-title {
      font-size: 32px;
    }
  }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {

}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {

}
