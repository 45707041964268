// Component : section-search-filters
.section-search-filters {
    padding: 90px 0 40px;

    p {
        font-size: 16px;
        line-height: 1.88;
    }

    .section__entry {
        margin-bottom: 40px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .document {
        .document__image img {
            height: 135px;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-search-filters {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-search-filters {
        padding: 20px 0 50px;
        .section__entry {
            margin-bottom: 30px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-search-filters {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-search-filters {
    }
}
