// Component : Services Slider
.section-services-alt {
	padding-bottom: 86px;
	position: relative;
	border-top: 1px solid transparent;
	margin-top: 201px;

	.section__head {
		margin: 100px 0 35px;
	}

	.section__body {
	}

	.section-graphic {
		position: absolute;
		top: -120px;
		left: 50%;
		transform: translateX(-50%);
		z-index: -1;
	}

	.circles {
		position: absolute;
		top: -21px;
		width: 42px;
		height: 42px;
		border-radius: 50%;
		background: #f4ac8b;

		&--left {
			left: 50%;
			transform: translateX(-150px);
		}
		&--right {
			right: 50%;
			transform: translateX(150px);
		}
	}
}

.section-services--default {
	margin-top: 0;
	padding-bottom: 40px;
	.service-alt p {
		color: #12282d;
	}

	.service__head {
		margin-top: 80px;
	}
}

.service-alt {
	text-align: center;
	position: relative;
	padding: 0 12px 0;
	margin-bottom: 14px;

	.service__image {
		display: flex;
		min-height: 74px;
		align-items: flex-end;
		justify-content: center;
		margin-bottom: 25px;
	}

	p {
		font-size: 14px;
		font-weight: 600;
		line-height: 1.5;
		color: #fff;
	}
}

.services-grid {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: 0 -10px;

	.service-alt {
		width: calc(50% - 9px);
		&:after {
			position: absolute;
			top: 50%;
			right: -42px;
			content: '';
			background: url(../images/svg/line-separator.svg) 0 0 no-repeat;
			width: 48px;
			height: 159px;
			background-size: cover;
			transform: translate(0, -50%);
			display: none;
		}

		@include each(1, 2) {
			margin-right: 18px;
			&:after {
				display: block;
			}
		}
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.section-services-alt {
		padding-bottom: 140px;

		.section__head {
			margin-top: 140px;
			margin-bottom: 64px;
		}
		.section__body {
			padding-left: 0;
			padding-right: 0;
		}
	}

	// .services-grid {
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: flex-start;
	// 	flex-wrap: wrap;
	// 	margin: 0 -10px;

	// 	.service-alt {
	// 		width: calc(50% - 9px);
	// 		&:after {
	// 			position: absolute;
	// 			top: 50%;
	// 			right: -42px;
	// 			content: '';
	// 			background: url(/images/svg/line-separator.svg) 0 0 no-repeat;
	// 			width: 48px;
	// 			height: 159px;
	// 			background-size: cover;
	// 			transform: translate(0, -50%);
	// 			display: none;
	// 		}

	// 		@include each(1, 2) {
	// 			margin-right: 18px;
	// 			&:after {
	// 				display: block;
	// 			}
	// 		}
	// 	}
	// }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.section-services-alt {
		padding-bottom: 90px;
		.section__head {
			margin-top: 141px;
			margin-bottom: 69px;
		}
	}

	.section-services--default {
		margin-top: 0;
	}

	.services-grid {
		justify-content: center;

		.service-alt {
			width: 190px;
			padding: 0;

			@include each(1, 2) {
				margin-right: 0;

				&:after {
					display: block;
				}
			}
		}

		.service-alt {
			&:after {
				display: block;
				right: -81px;
			}

			&:last-of-type {
				&:after {
					display: none;
				}
			}
		}

		.service-alt + .service-alt {
			margin-left: 110px;
		}

		.service-alt-1of4 {
			width: 190px;

			&:after {
				display: block;
				right: -66px;
			}
		}

		.service-alt-1of4 + .service-alt-1of4 {
			margin-left: 66px;
		}
	}

	.service-alt {
		p {
			font-size: 16px;
			font-weight: 700;
		}

		.service__image {
			margin-bottom: 36px;
		}
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
	.services-grid {
		.service-alt-1of4 {
			width: 244px;

			&:after {
				display: block;
				right: -66px;
			}
		}

		.service-alt-1of4 + .service-alt-1of4 {
			margin-left: 66px;
		}
	}
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
