// Component : section-optimize
.section-optimize {
    margin: 0 0 100px;

    .section__title {
        margin-bottom: 0;
    }

    h3, h2 {
        font-size: 18px;
        line-height: 1.5;
        color: #12282d;
        margin: 0 0 24px;
        font-weight: 700;
    }

    .list-dots {
        list-style-type: none;

        li {
            position: relative;
            font-size: 14px;
            line-height: 1.43;
            font-weight: 500;
            color: #12282d;
            padding-left: 24px;

            &:before {
                position: absolute;
                top: 7px;
                left: 0;
                content: '';
                width: 8px;
                height: 8px;
                background: #ffca67;
                border-radius: 50%;
            }
        }

        li + li {
            margin-top: 20px;
        }
    }

    .section__image {
        margin-bottom: 24px;

        img{
            max-height: 400px;
        }
    }

    .section__entry {
        padding: 40px 30px;
        position: relative;

        &--with-decoration {
            margin-top: 24px;
            background: #ffca67;

            .list-dots li:before {
                background: #fff;
            }
        }

        .section__entry-decoration {
            position: absolute;
            bottom: -13px;
            right: -148px;
            z-index: -1;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-optimize {
        .section__entry {
            &--with-decoration {
                margin-top: 0;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-optimize {

        h3, h2 {
            font-size: 32px;
            margin: 0 0 32px;
        }

        .section__head {
            margin-bottom: 70px;
        }

        .section__image {
            margin-bottom: 56px;
        }

        .section__entry {
            padding: 24px 0;

            &--with-decoration {
                padding: 64px 40px;
                margin-top: 161px;
            }
        }

        .list-dots {
            li {
                font-size: 16px;
                line-height: 1.88;

                &:before {
                    top: 10px;
                }
            }
            li + li {
                margin-top: 30px;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-optimize {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-optimize {
    }
}
