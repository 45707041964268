// Component : slider-video-article

.slider-video-article {
    overflow: hidden;
    padding-left: 8.32%;
    margin-top: 70px;
    .slider__slides {
        padding-bottom: 40px;
    }

    .slick-slide {
        padding: 0 5px;
    }

    .slider__slide {
        width: 289px !important;
        // width: 100% !important;
        max-width: calc(100vw - 70px) !important;
        box-shadow: 0 2px 20px 0 rgba(78, 40, 35, 0.15);
    }

    .slick-dots {
        bottom: 0;
    }

    .slick-list {
        overflow: visible;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .slider-video-article {
        margin-top: 64px;
        padding-left: 0;
        padding-bottom: 0;

        .slider__slide {
            width: 181px !important;
            max-width: 181px !important;
        }

        .slider__slides:not(.slick-dotted) {
            .slick-track,
            .slick-list {
                display: flex !important;
                justify-content: center;
            }
            .slick-track {
                transform: none !important;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .slider-video-article {
        padding-bottom: 40px;
        .slick-slide {
            padding: 0 15px;
        }
        .slider__slide {
            width: 300px !important;
            max-width: 300px !important;
        }

        .slider__slides {
            padding-bottom: 40px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .slider-video-article {
        padding-bottom: 40px;
        .slick-slide {
            padding: 0 15px;
        }
        .slider__slide {
            width: 359px !important;
            max-width: 359px !important;
        }
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .slider-video-article {
    }
}
