// Component : team join
.section-team-join {
    position: relative;
    background: #007286;
    padding: 64px 30px 130px;
    color: #fff;
    margin: 0 0 100px;

    p {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 24px;
    }

    .section__title {
        color: #12282d;
        margin-bottom: 24px;
        font-weight: 700;
        line-height: 1.33;
        font-size: 24px;

        &--dark {
            color: #12282d;
        }
    }
    .list-dots{

        li{

            &:before{
                margin-left: -20px;
            }
        }
    }

    .section__head {
        p {
            font-weight: 600;
            margin-bottom: 24px;
        }
    }

    .section__body {
        padding-bottom: 16px;
        .article__content{
            p{
                font-size: 13px;
            }
        }
    }

    .section__foot {
        margin-bottom: 58px;

        ul {
            list-style-type: none;
            margin: 0;
        }

        li + li {
            margin-top: 32px;
        }

        a.btn {
            min-width: 231px;
        }
    }

    .section__content {
        text-align: center;
    }

    .section__graphic {
        text-align: center;
        position: relative;
        margin-bottom: -210px;
        right: 0;
        width: 240px;
        margin-left: auto;
        margin-right: auto;
        svg {
            width: 240px;
            height: 194px;
        }

        img {
            width: 117px;
            position: absolute;
            top: -9px;
            right: 32px;
            border: 20px solid transparent;
            border-radius: 50%;
            height: 117px;
            object-fit: cover;
        }
    }

    .list-dots-decoration {
        list-style-type: none;
        width: 100%;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;

        li {
            position: relative;
            padding-left: 24px;
            font-size: 14px;
            line-height: 1.43;
        }
        li:before {
            position: absolute;
            top: 6px;
            left: 0;
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
           // background: #ffca67;
            background: #ffffff;
        }

        li + li {
            margin-top: 20px;
        }
    }

    &--alt {
        .section__head {
            margin-bottom: 44px;
        }

        .section__body {
            padding-bottom: 40px;
        }
    }
    &.nose{
        .section__graphic{
            img{
                width: 158px;
                height: 158px;
                top: -3px;
                right: -3px;
            }
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-team-join {
        min-height: 350px;
        padding: 64px 0;
        &.nose{
            .section__graphic{
                img{
                    width: 270px;
                    height: 270px;
                    top: 8px;
                    right: -69px;
                }
            }
        }
        .section__title {
            font-size: 24px;
        }

        .section__head {
            /*padding-left: 40px;
            padding-right: 40px;*/
        }

        .section__content {
            padding-left: 20px;
            padding-right: 20px;
        }

        .section__graphic {
            width: 359px;
            position: absolute;
            bottom : -144px;
            left: -50px;
            transform: translateY(0);
            margin-bottom: 0;
            margin-left: 0;

            img {
                border-width: 27px;
                width: 200px;
                height: 200px;
                top: -7px;
                right: -9px;
            }

            svg {
                width: 429px;
                height: 361px;
            }
        }

        .section__foot {
            margin-bottom: 0;

            ul {
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            li + li {
                margin-top: 0;
            }

            a.btn {
                min-width: 171px;
                padding: 0 10px;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-team-join {
        padding: 60px 0 70px;
        &.nose{
            margin-bottom: 210px;
            .section__graphic{
                top: 20px;
                bottom: auto;
                img{
                    width: 350px;
                    height: 350px;
                    top: 35px;
                    right: 0px;
                }
            }
        }
        p {
            font-size: 16px;
            line-height: 1.88;
        }

        .section__title {
            font-size: 40px;
            line-height: 1.55;
        }

        .section__head {
            p {
                margin-bottom: 32px;
            }
        }

        .section__body {
            padding-bottom: 60px;

            p {
                margin-bottom: 0;
            }
        }

        .section__foot {
            a.btn {
                min-width: 201px;
            }

            ul {
                padding: 0 40px;
            }
        }

        .section__graphic {
            width: 554px;
            left: -45px;
            right: auto;
            transform: none;
            top: auto;
            bottom: -205px;

            img {
                border-width: 40px;
                width: 292px;
                height: 292px;
                top: -13px;
                right: 54px;
            }
            svg {
                width: 554px;
                height: 518px;
            }
        }
        .list-dots-decoration {
            max-width: 375px;

            li {
                padding-left: 24px;
                font-size: 16px;
                line-height: 1.5;
            }
            li:before {
                top: 8px;
            }

            li + li {
                margin-top: 24px;
            }
        }

        &--alt {
            .section__head {
                margin-bottom: 40px;
            }

            .section__body {
                padding-bottom: 48px;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-team-join {
        &.nose{
            .section__graphic{
                img{
                    top: 95px;
                    right: 0px;
                }
            }
        }
        .section__graphic{
            left: -5px;
            bottom: -250px;
            svg {
                width: 554px;
                height: 618px;
            }
            img{
                width: 342px;
                height: 342px;
                top: -12px;
                right: 14px;
            }
        }
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-team-join {
        .section__graphic {
            left: 45px;
            svg {
                width: 554px;
                height: 638px;
            }
            img{
                width: 342px;
                height: 342px;
                top: -8px;
                right: 11px;
            }
        }
    }
}
