// Component : section-contacts
.section-contacts {
    .section__aside {
        padding: 54px 23px;
        color: #fff;
        background: #007286;
        position: relative;
        &--lightblue {
            background: $blue-viking;

            .btn {
                color: $blue-viking;
            }
        }

        &--lightg.section-mapreen {
            background: $green-riptide;

            .btn {
                color: $green-riptide;
            }
        }
        &.no-stores-infos{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 28px;
            text-align: center;
            font-weight: 700;
        }
        li{
            position: relative;
            min-height: 30px;
            .pin-ico{
                position: absolute;
                top: 4px;
            }
        }
        .market{
            position: absolute;
            top: 15px;
            right: 20px;
            background: white;
            color: #007286;
            border-radius: 100px;
            padding: 3px 10px;
            font-weight: 900;
            font-size: 13px;
            text-align: center;
        }

        .stores-infos-street,
        .stores-infos-city,
        .stores-infos-postcode {
            display: inline;
        }
        .stores-infos-address{
            position: relative;
            display: inline-block;
            padding-left: 40px;
            width: 100%;
        }
        .stores-infos-tel {
            min-width: 20px;
            display: block;
            width: 100%;
            height: 30px;
        }
        .stores-infos-city{
            display: block;
        }
        .stores-infos-email,
        .stores-infos-website{
            word-break: break-word;
        }
    }

    h3 {
        font-size: 28px;
        font-weight: 700;
        color: #fff;
        line-height: 1.18;
        margin: 0 0 40px;
        text-align: center;
    }
}

.list-contacts-alt {
    color: #fff;
    list-style-type: none;
    max-width: 420px;
    margin: 0 auto 40px;
    a {
        position: relative;
        display: block;
        padding-left: 40px;
        font-size: 16px;
        line-height: 1.88;
        font-weight: 600;
        color: #fff;

        span {
            position: absolute;
            left: 0;
            top: -1px;
        }
    }

    li + li {
        margin-top: 20px;
    }
    .list-contact-phone{
        a{
            span{
                top: 7px;
                left: 3px;
            }
        }
    }
}

.section-contacts--spaced {
    padding: 80px 0;
}

.list-btns {
    text-align: center;
    list-style-type: none;

    .btn {
        min-width: 231px;
        padding: 0 10px;
    }

    li + li {
        margin-top: 32px;
    }
}
.section-map {
    overflow: hidden;
    .googlemap {
        width: 100%;
        height: 340px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-contacts {

        .section__aside {
            width: 474px;
            max-width: 50%;
            flex-shrink: 0;
            min-height: 500px;
            padding: 43px 15px;

        }

        .section-map {
            flex-grow: 1;
            position: relative;
            height: 523px;
            .googlemap {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-contacts {
        display: flex;
    }
    .list-contacts-alt {
        max-width: 360px;
        li + li {
            margin-top: 14px;
        }
    }

    .list-btns {
        .btn {
            min-width: 260px;
            height: 57px;
            line-height: 57px;
            border-radius: 29px;
        }

        li + li {
            margin-top: 14px;
        }
    }
    .section-contacts--spaced {
        padding: 140px 0;
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
