// Component : section-error
.section-error {
    position: relative;
    background: #8dced9;
    padding: 80px 0 304px;
    text-align: center;
    overflow: hidden;

    h1 {
        font-size: 80px;
        line-height: 1;
        margin-bottom: 32px;
        color: #fff;
        font-weight: 700;
    }

    h5 {
        font-size: 18px;
        line-height: 1.67;
        color: #12282d;
        margin-bottom: 40px;
        font-weight: 700;
    }

    p {
        font-size: 16px;
        line-height: 1.88;
        margin-bottom: 48px;
    }

    .section__decoration {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 0 10px;
        z-index: 2;
    }

    .section__image {
        z-index: 1;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-error {
        padding: 163px 0 175px;
        background: none;
        text-align: left;

        h1 {
            color: #007286;
            margin-bottom: 40px;
        }

        h5 {
            font-size: 28px;
            line-height: 1.43;
            margin-bottom: 24px;
        }

        p {
            margin-bottom: 64px;
        }

        .section__image {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 50%;

            img {
                position: absolute;
            }

            .decoration-left {
                top: 50%;
                left: -37px;
                transform: translateY(-50%);
            }

            .decoration-right {
                bottom: -5px;
                left: 266px;
            }
        }

        .section__decoration {
            width: auto;
            left: 50%;
            transform: translateX(-47px);
        }

        .section__content {
            padding-right: 50px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-error {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-error {
    }
}
