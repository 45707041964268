// Component : section-partners
.section-partners {
    p {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 40px;
    }

    .section__image {
        margin-bottom: 40px;
    }

    .section__video {
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-partners {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-partners {
        p {
            font-size: 16px;
            line-height: 1.88;
            margin-bottom: 60px;
        }
        .section__head {
            margin: 140px 0 70px;
        }

        .section__image {
            margin-bottom: 60px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-partners {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-partners {
    }
}
