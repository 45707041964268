// Component : section-product
.section-product {
    padding: 0;
    margin-top: 60px;
    position: relative;

    .label{
        background: transparent;
        color: black;

        &--techno {
            text-transform: uppercase;
            border: 1px solid $medium-blue;
            color: $medium-blue;
            border-radius: 30px;
            display: inline-block;
            vertical-align: middle;
        }
    }
    .dropdown-item--blue a{
        padding-right: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: unset;
        position: relative;
        overflow: hidden;
    }
    .box-tocart{
        .fieldset{
            display: flex;
            flex-direction: column;
            #partkey{
                .control{
                    display: inline-block;
                }
            }
            .actions{
                position: relative;
                padding-top: 20px;
            }
            .qty{
                input{
                    max-width: 172px;
                }
            }
        }
    }
    p,
    p a {
        font-size: 16px;
        line-height: 2;
        margin-bottom: 13px;
    }

    .section__head {
        margin-bottom: 23px;
        width: 100%;
        &.top{
            position: relative;
            display: flex;
            // flex-direction: row-reverse;
            align-items: center;
            
            .modal {
                .products {
                    list-style-type: none;
                    margin-bottom: 0;

                    &__item {
                        box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);
                        margin-bottom: 3rem;
                        overflow: hidden;
                        display: flex;

                        &:hover {
                            .products__item__img img {
                                transform: scale(1.02);
                            }
                        }

                        &__img {
                            width: 100%;
                            overflow: hidden;
                            display: flex;
                            align-content: center;

                            img {
                                width: 100%;
                                transition: transform 00.4s;
                                transform: scale(1);
                            }
                        }

                        &__text {
                            align-self: center;
                            color: $black;
                            font-weight: 800;
                        }
                    }
                }
            }
        }
        .product{
            margin: 0;
        }
        .page-title{
            font-size: 30px;
            line-height: 1.27;
            font-weight: 700;
            margin: 0;
            margin-bottom: 10px;
        }
    }
    .section__group{
        &+.section__group{
            margin-top: 50px;
        }
    }
    .section__body{

        .article-excerpt.article-excerpt--small{
            &:hover {
                .article__image {
                    transform: none;
                }
            }
        }
        .gallery{
            ul{
                width: 100vw;
                img{
                    width: 100%;
                }
            }
        }
        .gallery-placeholder{
            display: none;
        }
        .gallery-thumbs-container{
            .slick-list{
                padding: 0!important;
            }
        }
        .full-description{
            a{
                color: #50b7c9;
                font-weight: 700;
            }
        }
        .text-block{
            margin-bottom: 50px;
        }
        .image-block{
            margin-bottom: 50px;
            .section__image{
                text-align: center;
                img{
                    // width: 90%;
                }
            }
        }
        .product{
            display: inline-block;
            margin: 0;
            width: 100%;
            &.sku{
                margin-top: -46px;
                .value{
                    font-weight: 700;
                    display: inline-block;
                }
            }
            &.configurable{
                margin-top: 10px;
            }
            &.attribute{
                &.description{
                    display: none;
                }
            }
        }
        .price-box{
            float: right;
            transform: translateY(-34px);
            &.price-final_price {
                float: none;
                transform: none;
                display: block;
            }
        }
        .label{
            line-height: 1.14;
            margin-bottom: 16px;
            margin-right: 5px;
            font-size: 14px;
            padding: 0;
            span{
                padding: 0;
            }
        }
        select,
        input{
            height: 30px;
            line-height: 30px;
            border: none;
            border-bottom: 1px solid #e5e5e5;
            color: #8bceda;
            width: 100%!important;
            color: #8bceda;
            padding: 0;
            padding-left: 8px;
            padding-right: 20px;
            font-weight: 600;
            box-shadow: none;
        }
        .link-container,
        .functionnal-benefits{
            width: 100%;
            margin: 0 auto;
        }
    }

    .section__head--actions {
        // max-width: 174px;
        // width: 100%;
        // margin-left: auto;
        // margin-top: 15px;
        .btn-rounded {
            line-height: inherit;
            min-height: inherit;
            font-weight: 400;
            min-width: auto;
            padding: 5px 15px;
            font-size: 0.9rem;
        }
    }

    .section__content {
        //FICHE PRODUIT TABS
        padding: 0;
        .box-tocart,
        .product-options-wrapper{
            .fieldset{
                position: relative;
                margin: 0;
                padding: 0rem;
                border: none;
            }
        }
        .product-options-wrapper{
            margin-bottom: 15px;
            .field{
                &.configurable{
                    margin-top: 0;
                    div.mage-error{
                        position: absolute;
                        left: 0;
                        bottom: -15px;
                        font-size: 14px;
                        color: #e36363;
                    }
                }
            }
        }
        .product-options-bottom{
            .field{
                &.qty{
                    position: relative;
                    div.mage-error{
                        position: absolute;
                        left: 0;
                        bottom: -15px;
                        font-size: 14px;
                        color: #e36363;
                    }
                }
            }
        }
    }

    .link-container {
        padding: 23px 0;
        display: flex;

        a {
            display: flex;
            align-items: center;
            color: #50b7c9;
            transition: color 0.3s;

            span {
                display: block;
                width: 34px;
                font-size: 0;
            }

            strong {
                font-size: 14px;
                font-weight: 700;
                line-height: 1.14;
            }

            &:hover {
                color: #0b97af;
            }
        }

        &.text-center {
            a {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .product-quantity {
        padding-top: 15px;
        padding-bottom: 56px;
        .form__row {
            flex-wrap: wrap;
        }
        .form__label {
            display: block;
            width: 100%;
            font-size: 14px;
            line-height: 1.14;
            margin-bottom: 16px;
        }

        .form__controls {
            width: 100%;
        }
        .select2-container,
        .select2-container span,
        &:focus {
            outline: none;
        }

        .select2-container {
            width: 100% !important;
            color: #8bceda;
            font-weight: 600;
        }

        .select2-container--default .select2-selection--single {
            height: 30px;
            border: none;
        }

        .select2-container--default .select2-selection--single .select2-selection__rendered {
            height: 30px;
            line-height: 30px;
            border: none;
            border-bottom: 1px solid #e5e5e5;
            color: #8bceda;
        }

        .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
            border-color: transparent transparent #8b9ca0 transparent;
        }

        strong {
            font-size: 24px;
        }
        small {
            font-size: 12px;
            margin-left: 5px;
        }
    }
    .action{
        &.tocart{
            position: fixed;
            bottom: 0px;
            left: 0;
            display: block;
            background: #12282d;
            box-shadow: 0 -2px 15px 0 rgba(0,0,0,.3);
            color: #fff;
            height: 70px;
            width: 100vw;
            min-width: 100%;
            border-radius: 0;
            z-index: 3;
        }
    }
    .stock {
        position: absolute;
        top: -25px;
        display: block;
        text-align: right;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.17;
        margin-left: auto;
        padding-left: 17px;
        right: 0;
        &:before {
            position: absolute;
            top: 50%;
            left: 0;
            content: '';
            width: 7px;
            height: 7px;
            border-radius: 50%;
            transform: translateY(-50%);
        }

        &.available {
            color: #9fde89;

            &:before {
                background: #9fde89;
            }
        }
    }

    .product-number {
        display: block;
        font-size: 14px;
        line-height: 1.14;
        font-weight: 600;
        margin-bottom: 32px;

        width: 100%;

        &-shrink {
            flex-shrink: 0;
            width: 50%;
            text-align: left;
        }
    }

    .price {
        display: block;
        font-size: 48px;
        font-weight: 700;
        line-height: 50px;

        .currency {
            vertical-align: super;
            font-size: 25px;
        }
    }

    sup {
        font-size: 12px;
        font-weight: 600;
    }

    .product-controls {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .btn {
            min-width: 100%;
        }

        .btn-holder {
            width: 100%;
        }

        .btn-cart-holder {
            position: relative;
            margin-left: -10px;
            margin-right: -10px;
            height: 70px;
            width: calc(100% + 20px);
            margin-bottom: 20px;
        }

        .btn-cart {
            position: fixed;
            bottom: 0;
            left: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #12282d;
            box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, 0.3);
            color: #fff;
            height: 70px;
            width: 100vw;
            min-width: 100%;
            transform: translateX(-50%);
            border-radius: 0;
            z-index: 99;

            &:hover {
                background: rgba(0, 0, 0, 0.8);
            }

            span {
                font-size: 16px;
                font-weight: 700;
                line-height: 1.19;
            }

            .ico-cart {
                font-size: 0;
                line-height: 1;
            }

            i + span {
                margin-left: 10px;
            }
        }

        .btn-cart-holder.stick {
            .btn-cart {
                position: absolute;
                bottom: auto;
                top: 0;
                z-index: 1;
            }
        }
    }
}

.select2-container--default .select2-results__option {
    color: #8bceda;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #8bceda;
    color: #fff;
}

.select2-dropdown {
    border: 1px solid #e5e5e5;
}
.select2-dropdown {
    strong {
        font-size: 24px;
    }

    small {
        font-size: 12px;
        margin-left: 5px;
    }
}

.list-product-description {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style-type: none;

    span {
        width: 23px;
        display: block;
        font-size: 0;
        margin-right: 10px;
        img{
            width: 13px;
        }
    }

    li {
        padding: 17px 0;
        padding-bottom: 0;
        width: 50%;
        display: flex;
        align-items: center;
        padding-right: 10px;
        small {
            font-size: 10px;
            font-weight: 500;
            line-height: 14px;
            text-transform: uppercase;
            color:#12282d;
        }
    }
}

.gallery {
    ul {
        list-style-type: none;
    }

    .gallery-thumbs {
        width: 100%;
        display: none;
        .slick-slide {
            font-size: 0;
            line-height: 1;
            padding: 1px;
            margin-bottom: 5px;
        }

        .slick-current {
            box-shadow: inset 0px 0px 0px 1px #8dced9;
        }
        .slide__image {

        }

        .slide__video-thumb {
            position: relative;

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                content: '';
                background: url(/images/svg/svg-btn-play-white.svg) 0 0 no-repeat;
                background-size: 100% 100%;
                width: 50px;
                height: 50px;
                transform: translate(-50%, -50%);
            }
        }
    }

    .gallery-main {
        .slide__video,
        .slide__image {

        }

        .slide__video {
            position: relative;
            width: 100%;
            padding-top: 100%;
            overflow: hidden;
            margin-bottom: 0;

            .btn-play {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
            }
            video {
                position: absolute;
                top: 50% !important;
                left: 50% !important;
                transform: translate(-50%, -50%);
                max-width: none;
            }
        }

        .slick-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 0;
            line-height: 0;
            text-indent: -4004px;
            z-index: 9;
        }
        .slick-slide{
            width: 100vw!important;
        }
        .slick-prev {
            left: 16px;
            background: url(../images/svg/svg-left-arrow-dark.svg) 0 0 no-repeat;
            background-size: cover;
            width: 18px;
            height: 16px;
        }

        .slick-next {
            right: 25px;
            background: url(../images/svg/svg-right-arrow-dark.svg) 0 0 no-repeat;
            background-size: cover;
            width: 18px;
            height: 16px;
        }
    }
}

.section-product {

    .legal-price-info {

        margin: 20px 0;

        p {
            font-size: 11px;
            line-height: 1.8;
            margin-bottom: 13px;
            color: #8b9ca0;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-product {
        .product-controls {
            .btn-holder {
                text-align: center;
            }

            .btn {
                min-width: 0;
            }
        }
    }

    .section-product .product-controls .btn-cart-holder {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-product {
        padding-bottom: 100px;
        .page__title {
            font-size: 40px;
            line-height: 1.18;
            padding-right: 100px;
            margin-bottom: 0;
        }

        .section__head {
            //FICHE PRODUIT
            display: flex;
            justify-content: space-between;
            margin-bottom: 3rem;
            .product{
                // width: calc(100% - 210px);
                // margin-bottom: 55px;
            }
            .page-title {
                font-size: 40px;
                line-height: 1.18;
                padding-right: 0;
                margin-bottom: 0;
            }
            .dropdown-item{
                position: absolute;
                top: 6px;
                right: 0;
                width: 200px;
            }
        }
        .section-news {
            .section__head {
                display: block;
            }
        }
        .section__body{
            .gallery{
                &.full-width{
                    width: 100%;
                    position: relative;
                    left: auto;
                    right: auto;
                    margin-left: auto;
                    margin-right: auto;
                }
                ul{
                    width: 100%;
                }
                .slick-track{
                    width: 100%!important;
                }
                .gallery-thumbs-container{
                    .slide__image{
                        height: 97px;
                        width: 97px;
                    }
                }
            }
            .full-description,
            .technical-benefits,
            .link-container,
            .functionnal-benefits,
            #product_addtocart_form{
                width: 100%;
                margin: 0 auto;
            }
            .technical-benefits{
                margin-top: 15px;
            }
            select,
            .control,
            input{
                display: inline-block;
                width: auto!important;
            }
            .product.attribute.description,
            .functionnal-benefits{
                display: none;
            }
            .product{
                display: block;
                float: left;
            }
        }
        .stock{
            top: -35px;
            right: 25px;
        }
        .section__head--actions {
            // min-width: 203px;
            // flex-shrink: 0;
            // margin-top: -35px;
            // margin-bottom: 34px;
        }
        .section__content {
            padding-top: 0;
            margin-top: 0px;
        }
        .box-tocart{
            .fieldset{
                .actions{
                    flex-direction: row-reverse;
                    align-items: center;
                    align-self: flex-start;
                    padding-top: 30px;
                    width: 100%;
                    padding-top: 20px;
                    position: relative;
                }
            }
        }

        p {
            a {
                color: #98d4de;
                text-decoration: underline;
                font-weight: 400;
            }
        }
        .link-container {
            padding: 20px 0;
        }

        .product-quantity {
            padding-top: 0;
            padding-bottom: 54px;
            .form__label {
                width: auto;
                margin-bottom: 0;
            }

            .form__controls {
                width: 268px;
                margin: 0 32px;
            }

            .product-availability {
                margin-top: 0;
                margin-left: 0;
            }
        }

        .link-container {
            a {
                color: #50b7c9;
            }
            a strong {
                font-size: 16px;
            }

            &.text-center a {
                margin-left: 0;
            }
        }

        .product-number {
            margin-bottom: 19px;
            &-shrink {
                width: 100%;
            }
        }

        .price-holder {
            text-align: left;
            width: auto;
            margin-bottom: 5px;
            flex-shrink: 0;
            padding-right: 40px;
        }

        .product-controls {
            .btn-holder {
                width: auto;

                &-grow {
                    flex-grow: 1;
                    .btn {
                        width: 100%;
                    }
                }
                &-shrink {
                    flex-shrink: 0;
                }

                & + .btn-holder {
                    margin-left: 15px;
                }
            }

            .btn-cart-holder {
                position: static;
                margin: 0;
                height: auto;
                width: auto;

                .btn-cart {
                    position: static;
                    width: 100%;
                    transform: none;
                    box-shadow: none;
                    padding: 0 60px;
                }
            }

            .btn-cart-holder.stick .btn {
                position: static;
            }

            .btn {
                height: 57px;
                line-height: 57px;
                border-radius: 29px;
                padding: 0 35px;
            }
        }
        .action.tocart{
            width: 100%;
            max-width: 310px;
            border-radius: 100px;
        }
    }

    .list-product-description {
        margin: 0 -10px;
        span{
            margin-right: 0;
        }
        li {
            padding: 0 10px;
            margin: 0;
            width: auto;
            margin-bottom: 10px;
        }
    }

    .gallery {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row-reverse;
        .slick-slide{
            &:focus{
                outline:0;
            }
        }
        .gallery-thumbs {
            display: block;
            width: 100px;
            flex-shrink: 0;
        }

        .gallery-main {
            padding-left: 0;
            flex-grow: 1;
            max-width: calc(100% - 140px);

            .slick-slide {
                opacity: 0;
                width: auto!important;
                width: 100%!important;
            }
            .slick-slide.slick-active {
                opacity: 1;
            }
            //FIX SLICK
            .slick-track{
                //A VERIFIER
                height: 550px;
                .slide__image{
                    &:hover{
                        cursor: default;
                    }
                }
            }
            .slick-slide{
                position: absolute;
                top: 0;
                display: none;
                &.slick-active{
                    display: block;
                }
            }
            .slick-arrow {
                display: none !important;
            }
        }
    }

    .section-product {

        .legal-price-info {
            margin: 10px 0 0 0;

            p {
                font-size: 11px;
                line-height: 1.8;
                margin-bottom: 13px;
                color: #8b9ca0;
            }
        }

        .action{
            &.tocart{
                float: right;
                left: 0;
                position: relative;
                max-width: 310px;
                min-width: auto;
                i{
                    position: relative;
                    top: 3px;
                    margin-right: 18px;
                }
            }
        }
        .product-controls{
            .btn-cart-holder .btn-cart {
                padding: 0 45px;
            }
        }

        .price {
            font-size: 30px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-product {
        .price {
            font-size: 48px;
        }
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-product {
    }
}


// IE BROWSER 
/*
 * Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
 *
 * [1] IE9
 * [2] IE10+
 */
/* 1 */
.ie11 img:not(.image-decoration)[src$=".svg"] {
  width: 100vw; 
  height: auto;
}
/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img:not(.image-decoration)[src$=".svg"] {
    width: 100vw; 
    height: auto;
  }
}

body.is-ie.catalog-product-view {
    .gallery {
        justify-content: flex-start;

        &-thumbs {
            margin-right: 40px;
        }

        .asset-type-video {
            &:before {
                -ms-transform: translate(25%, 25%);
            }
        }
    }
}