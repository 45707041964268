// Component : section-callouts-decorated
.section-callouts-decorated {
	margin: 40px 0 0;
	margin-bottom: 120px;
	&.little-blocks{

		h2{
			font-weight: bold;
		}
		.description{
			font-weight: normal;
		}
		.callout-box-large{
			height: 230px;
			min-height: 230px;

			h3{
				font-size: 28px;
			}
		}
	}
	.columns{

		.callout-box-large:nth-child(1){
			margin-bottom: 20px;
		}
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.section-callouts-decorated {
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.section-callouts-decorated {
		margin: 40px 0 60px;
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
	.section-callouts-decorated {
	}
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
	.section-callouts-decorated {
	}
}
