// Component : Section

.section {
	.section__head {
		margin-bottom: 40px;

		strong {
			color: #8b9ca0;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.5;
			margin: 0;
		}
	}

	.section__title {
		margin: 0 0 19px;
		color: #12282d;
		font-weight: 700;
		line-height: 1.33;
	}

	.section__title {
		font-size: 24px;
		strong{
			color: initial;
			font-size: inherit;
			font-weight: inherit;
		}
	}

	h3.section__title {
		font-size: 22px;
	}
}

.section-wysiwyg,
.section__title.section__title--decoration {
	strong {
		display: inline-block;
		position: relative;
		font-weight: inherit;
		font-size: inherit;
		color: #12282d;
		z-index: 2;
		svg {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			width: 110%;
			bottom: 0;
			height: 10px;
			fill: #ffca67;
			z-index: -1;
		}
	}
	&.white{
		strong{
			svg{
				fill: #fff;
			}
		}
	}
}

.section-wysiwyg{
	strong{
		font-weight: bold;
	}

	h3 {
		margin-bottom: 0.5rem !important;
	}
}

.section__title--decoration-white {
	strong {
		svg {
			use {
				fill: #fff;
			}
		}
	}
}

.section__title--decoration-violet {
	strong {
		svg {
			use {
				fill: #a06590;
			}
		}
	}
}

.section__title--decoration-coral {
	strong {
		svg {
			use {
				fill: #f08576;
			}
		}
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.section__title--decoration {
		span {
			&:before {
				width: 53px;
				height: 6px;
			}
		}
		strong{
			svg{
				bottom: -2px;
				height: 13px;
			}
		}
	}
	.section{
		.section__title.section__title--decoration {
			strong {
				display: inline-block;
				position: relative;
				font-weight: inherit;
				font-size: inherit;
				color: #12282d;
			}
		}
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.section {
		.section__title {
			font-size: 40px;
			line-height: 1.55;
		}
		h3.section__title {
			font-size: 32px;
		}

		.section__head {
			strong {
				font-size: 16px;
			}
		}
		.section__title.section__title--decoration {
			strong {
				display: inline-block;
				position: relative;
				font-weight: inherit;
				font-size: inherit;
				color: initial;
				color: #12282d;
			}
		}
	}

	.section__title--decoration {
		span {
			&:before {
				width: 81px;
				height: 11px;
			}
		}
		strong{
			svg{
				bottom: 10%;
			}
		}
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
