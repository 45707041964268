// Component : section video help
.section-video-help {
    margin-bottom: 100px;
    .section__head {
        margin: 20px 0 30px;
    }

    .section__foot {
        padding-top: 5px;
    }
    &.playing{
        .video-section-play-ico{
            visibility: hidden!important;
        }
    }
}


.back{
    z-index: 1!important;
}

.video-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.9);
    display: none;
    z-index: 2;
    
    &.visible{
    }
    // .video-iframe,
    // .video-iframe-half{
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     width: 80%;
    //     height: 66%;
    // }
    .video__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 55%;
    }
    .mfp-close{
        display: block;
        top: 50px;
        left: auto;
        right: 50px;
        background: url(../images/svg/ico-close-white.svg) 0 0 no-repeat;
        opacity: 1;
        background-size: 100%;
        &:hover{
            cursor: pointer;
        }
    }
}
// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-video-help {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-video-help {
        .section__head {
            margin: 20px 0 72px;
        }

        .section__foot {
            padding-top: 35px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-video-help {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-video-help {
    }
}
