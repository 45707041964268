// Component : section-product-docs
.section-product-docs {
    #product-insert-assets{
        background: #f5f5f5;
        padding: 40px 0;
    }
    .section__title {
        margin-bottom: 40px;
        font-size: 18px;
        line-height: 1.88;
        font-weight: 700;
    }
}

.list-docs {
    list-style-type: none;

    a {
        display: block;
        position: relative;
        padding-left: 74px;
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #8dced9;
        box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);
    }

    strong {
        display: block;
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.71;
        color: #12282d;
    }

    small {
        display: block;
        font-weight: 400;
        color: #8b9ca0;
        font-size: 14px;
        text-transform: uppercase;
        line-height: 1.14;
    }

    li + li {
        margin-top: 32px;
    }
}

.fixed {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    z-index: 5;
    margin-left: 0;
    margin-right: 0; }


// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-product-docs {
    }

    .list-docs {
        display: flex;
        align-items: left;
        justify-content: left;
        flex-wrap: wrap;
        margin: 0 -15px;

        li {
            padding: 0 15px;
            width: 50%;
            margin-bottom: 28px;
            &:nth-last-child(-n+2) {
                margin-bottom: 0;
            }
        }

        li + li {
            margin-top: 0;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-product-docs {
        #product-insert-assets{
            padding: 64px 0 72px;
        }
        .section__title {
            font-size: 32px;
            line-height: 1.16;
            margin-bottom: 32px;
        }
    }

    .list-docs {
        strong {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 10px;
        }
    }
}

// -- Custom breakpoint used in header/style
@media screen and (min-width: 1280px) {
    .fixed {
        position: fixed;
        top:95px;
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-product-docs {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-product-docs {
    }
}
