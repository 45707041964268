// Component : Section Contact Information
.section-contact-information {
    padding: 0;
    .form-contact {
        margin-bottom: 0;
    }

    &--alt {
        padding-bottom: 40px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-contact-information {
        &--alt {
            padding-bottom: 80px;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-contact-information {
        .form-contact {
            .form__actions {
                text-align: left;
            }
        }

        &--alt {
            padding-bottom: 140px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
