// Component : section-scientists
.section-scientists {
    margin: 0 0 100px;

    & + .section-news {
        .section__head {
            margin-top: 0;
        }
    }
    .section__title {
        margin-bottom: 32px;
    }

    .section__image {
        position: relative;
        padding-right: 64px;
        margin-left: 7px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 52px;
        z-index: 1;
        text-transform: uppercase;

        .media__video, .media__image {
            z-index: 4;
            text-align: right;
            margin-right: 0;
            padding-bottom: 59.25%; // for mobile
            height: auto;
            .video-iframe{
                position: relative;
                min-height: 300px;
            }
        }
        img {
            position: relative;
            z-index: 2;
            min-height: 200px;
            object-fit: cover;
        }
        .decoration{
            display: none;
            position: absolute;
            &.show{
                display: block;
            }
            .section__image__decoration{
                &--top{
                    position: absolute;
                    width: 23px;
                    z-index: 2;
                }
            }
            &.black-man{
                top: -100px;
                right: 92px;
                .section__image__decoration{
                    &--top{
                        top: 93px;
                        right: 20px;
                    }
                }
            }
            &.white-man{
                top: -100px;
                right: 167px;
                .section__image__decoration{
                    &--top{
                        top: 90px;
                        left: 20px;
                    }
                }
            }
            &.brunette-woman{
                top: -100px;
                right: 110px;
                .section__image__decoration{
                    &--top{
                        top: 90px;
                        right: 30px;
                    }
                    &--girl{
                        &-arm{
                            top: 160px;
                            left: -34px;
                            z-index: 2;
                        }
                    }
                }
            }
            &.blond-woman{
                top: -130px;
                right: 120px;
                .section__image__decoration{
                    &--top{
                        top: 120px;
                    }
                    &--girl{
                        &-arm{
                            top: 166px;
                            left: -37px;
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }

    .section__image__decoration {
        position: absolute;
        &--left {
            z-index: 3;
            left: -7px;
            top: 56px;

            svg {
                width: 16px;
                height: 19px;
            }
        }
        &--right {
            z-index: 1;
            top: -30px;
            right: 0px;

            svg {
                width: 88px;
                height: 275px;
            }
        }

        &--right-alt {
            right: -25px;
            svg {
                width: 116px;
                height: 276px;
            }
        }
    }

    .section__content {
        margin-bottom: 130px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .section__title {
        font-weight: 700;
        margin: 0 0 32px;
    }

    p {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 20px;
    }

    .list-btns {
        margin-top: 20px;

        li + li {
            margin-top: 20px;
        }

        .btn {
            min-width: 231px;
        }
    }

    &--primary {
        padding-top: 80px;
        .section__title {
            font-size: 18px;
            margin-bottom: 16px;
        }
        .section__content {
            margin-bottom: 40px;
        }
    }
}

.section-scientists--alt {
    padding-top: 80px;
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-scientists {
        .section__image{
            .media__video,
            .media__image {
                padding-bottom: 0;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-scientists {
        p {
            font-size: 16px;
            line-height: 1.88;
        }

        .section__title {
            margin-bottom: 37px;
        }

        .list-btns {
            margin-top: 44px;
            display: flex;
            justify-content: center;

            li + li {
                margin-top: 0;
            }

            li {
                margin: 0 16px;
            }

            .btn {
                min-width: 0;
                padding: 0 50px;
            }
        }
        .section__content{
            margin-bottom: 0px;
        }
        .section__image {
            padding-right: 84px;
            margin-left: 7px;
            margin-top: 32px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 120px;

            img {
                position: relative;
                z-index: 2;
                min-height: 250px;
                display: inline-block;
            }
            .decoration{
                display: none;
            }
            .show{
                display: block !important;
            }
            .decoration{
                &.black-man{
                    .section__image__decoration{
                        &--top{
                            top: 93px;
                            right: 40px;
                        }
                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                            top: 27px;
                        }
                        &--girl{
                            right: -90px;
                            top: 10px;
                        }
                    }
                }
                &.white-man{
                    .section__image__decoration{
                        &--top{
                            top: 93px;
                            right: 40px;
                        }
                        &--girl{
                            right: -179px;
                            top: 0px;
                        }
                    }
                }
                &.brunette-woman{
                    top: -120px;
                    right: 110px;
                    .section__image__decoration{
                        &--top{
                            top: 110px;
                        }
                    }
                }
                &.blond-woman{
                    .section__image__decoration{
                        &--top{
                            left: -60px;
                        }
                    }
                }
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-scientists {
        .section__image {
        }
        .section__decoration--left {
            top: 83px;
            right: -10px;
        }
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-scientists {
    }
}
