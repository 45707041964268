// Component : section-articles
.section-articles {
    padding: 80px 0 40px;
}

.article-excerpt.article-excerpt--medium {
    margin-bottom: 40px;
    background: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);
    color: #12282d;

    &:hover {
        h3{
            color: #007286;
            transition: all 0.3s ease;
        }
        .article__image{
            transform: scale(1.1);
            transition: all 0.3s;
        }
    }
    h3 {
        font-size: 24px;
        line-height: 1.33;
        margin: 0;
        font-weight: 700;
        margin-bottom: 10px;
        display: block;
        color: #12282d;
        transition: color 0.3s;
    }

    p {
        font-size: 14px;
        line-height: 1.71;
        margin-bottom: 10px;
        color: #12282d;
    }

    .link-more {
        display: inline-block;
        font-size: 16px;
        line-height: 1.19;
        font-weight: 700;
        text-decoration: underline;
        color: #12282d;

        &:hover {
            text-decoration: none;
        }
    }
    .article__image_container{
        overflow: hidden;
    }
    .article__image {
        height: 200px;
        transition: all 0.3s;
    }

    .article__content {
        padding: 26px 20px 23px;
        min-height: 204px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-articles {
        padding: 60px 0;
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-articles {
        padding: 60px 0;
    }

    .article-excerpt.article-excerpt--medium {
        h3 {
            font-size: 30px;
            line-height: 1.5;
            margin-bottom: 10px;
        }

        p {
            font-size: 16px;
            line-height: 1.63;
            margin: 0 0 24px;
        }

        .link-more {
            font-size: 16px;
            line-height: 1.19;
        }

        .article__image {
            height: 320px;
        }

        .article__content {
            padding: 20px 34px 20px;
            min-height: 235px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-articles {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-articles {
    }
}
