// Component : Nav Filter

//LAST ADDED
.level1{
    & > span{
        display: none;
    }
}

.classic-cat{
    .level1{
        & > span{
            display: block;
        }
    }
    .infos{
        display: block!important;
        padding: 0 20px;
    }
    .tabs-navigation{
        .tabs__nav{

            padding-bottom: 0;
        }
    }
    a{
        color: black;
        &:after{
            border-color: black transparent transparent transparent;
        }
    }
}

.navigation .level0.parent.classic-cat > a{
    color: black;
    &:after{
        border-color: black transparent transparent transparent;
    }
}


//*******


.dropdown-item {
    position: relative;
    a,
    > select{
        display: block;
        height: 62px;
        line-height: 62px;
        padding: 0 30px 0 30px;
        background: #f5f5f5;
        font-size: 16px;
        color: #12282d;
        border: none;
    }

    > a,
    > select{
        border-radius: 32px;
        transition: border-radius 0.1s;
        position: relative;

        &:after {
            position: absolute;
            top: 50%;
            right: 30px;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-color: #12282d transparent transparent transparent;
            transform: translateY(-50%);
            transition: transform 0.3s;
        }
    }
    .form-arrow{
        position: absolute;
        top: 50%;
        right: 30px;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-color: #12282d transparent transparent transparent;
        transform: translateY(-50%);
        transition: transform 0.3s;
        z-index: 9;
    }
    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: none;
        list-style-type: none;
        z-index: 2;

        li:last-of-type {
            border-radius: 0 0 32px 32px;
            overflow: hidden;
        }

        a:hover {
            background: $light-coral;
            color: #fff;
        }
    }

    &.dropdown-open {
        > a {
            border-radius: 32px 32px 0 0;

            &:after {
                transform: rotate(180deg) translateY(50%);
            }
        }
    }

    &--small {
        a {
            line-height: 32px;
            height: 32px;
            font-size: 13px;
            padding: 0 20px 0 12px;

            &:after {
                right: 15px;
            }
        }
        > a {
            border-radius: 16px;
            line-height: 32px;
            height: 32px;
            font-size: 13px;
            padding: 0 20px 0 12px;
        }
        .dropdown li:last-of-type {
            border-radius: 0;
        }

        &.dropdown-open > a {
            border-radius: 16px 16px 0 0;
        }
    }
    &--blue {
        a {
            background: $blue-viking;
            color: #fff;
        }

        li {
            border-top: 1px solid #fff;
        }

        > a:after {
            border-color: #fff transparent transparent transparent;
        }

        .dropdown a:hover {
            background: #fff;
            color: $blue-viking;
        }
    }
}

.nav-filter {
    .link-expander {
        display: block;
        position: relative;
        line-height: 64px;
        height: 64px;
        background: #f5f5f5;
        padding: 0 32px;
        color: #12282d;
        font-size: 14px;
        font-weight: 600;

        &:after {
            position: absolute;
            top: 50%;
            right: 35px;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-color: #12282d transparent transparent transparent;
            transform: translateY(-50%);
            transition: transform 0.3s;
        }

        &.open {
            &:after {
                transform: rotate(180deg) translateY(50%);
            }
        }
    }

    .dropdown-item > a {
        display: block;
        background: #fff;
        font-size: 16px;
        font-weight: 600;
        height: 82px;
        line-height: 82px;
        color: #12282d;
        padding: 0 32px;
        margin: 0;
        border-radius: 0;
        border-bottom: 1px solid #f5f5f5;

        &:after {
            // position: absolute;
            // top: 50%;
            right: 34px;
            // content: '';
            // width: 0;
            // height: 0;
            // border-style: solid;
            // border-width: 6px 4px 0 4px;
            // border-color: #12282d transparent transparent transparent;
            // transform: translateY(-50%);
        }
    }

    .dropdown-item .dropdown {
        flex-wrap: wrap;
        position: static;
        background: #f5f5f5;
        padding: 15px 27px;

        &:after {
            content: '';
            line-height: 0;
            display: table;
            clear: both;
        }
    }

    .dropdown-item.dropdown-open .dropdown {
        /*display: flex !important;*/
    }

    .dropdown-item .dropdown li {
        margin: 10px 5px;
        display: inline-block;
        vertical-align: middle;
        float: left;
        overflow: hidden;
        &.active{
            a{
                color: #007286;
                background: #f5f5f5;
                border: 1px solid #007286;
            }
        }
    }
    .dropdown-item .dropdown a {
        line-height: 35px;
        height: 35px;
        background: #fff;
        color: #6b6b6b;
        font-weight: 600;
        font-size: 12px;
        padding: 0 16px;
        text-transform: capitalize;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &.selected {
            background: #8dced9;
            color: #fff;
        }
    }

    .dropdown-item .dropdown li:last-of-type {
        border-radius: 0;
    }

    ul {
        list-style-type: none;
        margin-left: 0;
    }

    .nav__inner > ul {
        overflow: scroll;
    }

    .list-filter-selected {
        li {
            // padding: 0 10px;
            // display: flex;
            // align-items: center;
            // svg {
            //     margin-left: 5px;
            // }
            .delete-cross{
                margin-left: 15px;
                font-weight: 700;
            }
        }

        .btn-close {
            display: inline-block;
            padding: 0;
            margin-left: 5px;
            background: url(/images/svg/svg-close.svg) 0 0 no-repeat;
            background-size: 9px 9px;
            width: 9px;
            height: 9px;
        }

        span {
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
        }
    }

    .nav-filter__actions {
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 24px 20px;
        margin-bottom: 70px;
        background: white ;
        li {
            text-align: center;
        }
    }

    .filter-actions-btns {
        li + li {
            margin-top: 16px;
        }
    }

    .reset-filters {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: none;
        padding: 0;
        text-decoration: none;
        line-height: 38px;
        font-size: 0;
        color: #50b7c9;

        span {
            font-size: 14px;
            font-weight: 600;
        }

        svg {
            margin-left: 10px;
            fill: $light-blue;
        }

        &:hover {
            color: $black;
        }
    }
}

.nav-filter-alt .dropdown-item .dropdown a.selected {
    background: #8dced9;
}

.nav-filter-holder {
    position: relative;
    margin: 0 -10px;
    .nav-filter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 64px;
        z-index: 4;
        transition: all 0.8s ease;
        &.open{
            bottom: calc(100% - 135px);
            transition: all 0.8s ease;
            .nav__inner{
                overflow: auto;
            }
        }
        .nav__inner {
            height: calc(100vh - 113px);
            background-color: $white;
        }
    }

    .nav-filter.nav-expanded {
        height: 100%;
        position: fixed;
        z-index: 100;

    }

    &.stick {
        .nav-filter {
            position: absolute;
        }

        .nav-filter.nav-expanded {
            height: 100vh;
            position: fixed;
        }
    }

    &--alt {
        margin: 0;
    }
}

.nav-filter-holder-static {
    height: auto;
    margin: 0;

    .nav-filter {
        position: static;
        height: auto;
        background: none;
    }

    .link-expander {
        border-radius: 32px;
    }

    &.stick .nav-filter.nav-expanded {
        height: auto;
        position: static;
    }

    &.stick .nav-filter {
        position: static;
    }
}

//diagnostics-resources-index
.diagnostics-resources-index{
    .nav-filter{
        .nav__inner{
            margin-bottom: 10px;
        }
    }
    .nav-filter-single-filter{
        margin: 0;
    }

    .breadcrumbs {
        position: absolute;
    }
}



// --TABLET
@media screen and #{breakpoint(medium up)} {
    .nav-filter {
        .nav__inner {
            display: block !important;
            & > ul {
                overflow: visible;
            }
            .dropdown-item{
                position: relative;
                display: inline-block;
                width: calc(100% - 20px);
                margin-bottom: 15px;
            }
        }
        .dropdown-item{
            .dropdown{
                li{
                    &.active{
                        a{
                            border: none
                        }
                    }
                }
            }
        }
        .filter-title {
            display: block;
            margin-bottom: 20px;
            font-weight: bold;
        }
    }

    .nav-filter-holder {
        position: relative;
        height: auto;
        margin-left: 0;
        margin-right: 0;
        .nav-filter{
            .nav__inner{
                height: auto;
            }
        }

    }

    .nav-filter .nav__inner > ul {
        max-height: 100%;
        margin-bottom: 50px;
    }

    .nav-filter-holder .nav-filter,
    .nav-filter-holder.stick .nav-filter {
        position: static;
        height: auto;
    }

    .nav-filter .nav-filter__actions {
        position: static;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-top: 60px;
    }

    .nav-filter .list-filter-selected {
        // display: flex !important;
        // max-width: 70%;
        // flex-wrap: wrap;

        li {
            margin: 5px 0;
        }
    }

    .nav-filter .dropdown-item > a,
    .nav-filter .dropdown-item > select{
        background: #f5f5f5;
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
        padding: 0 30px 0 14px;
        margin: 0;
        min-width: 166px;
        width: calc(100% + 20px);
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .nav-filter .dropdown-item > a:after {
        right: 20px;
    }

    .nav-filter .dropdown-item .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 0;
        border: 1px solid #f5f5f5;
    }

    .nav-filter .dropdown-item .dropdown li {
        margin: 0;
        width: 100%;
        float: none;
    }
    .nav-filter .dropdown-item .dropdown li + li {
        border-top: 1px solid #fff;
    }

    .nav-filter .dropdown-item.dropdown-open > a {
        border-radius: 20px 20px 0 0;
    }

    .nav-filter-single-filter {
        margin: 30px 0 90px;
        padding: 0 15px;

        .dropdown-item a {
            padding: 0 41px 0 24px;
        }
        .nav__inner {
            display: flex !important;
            align-items: center;
            .diagnostics-resources-index &{
                display: block!important;
                .nav-filter__actions{
                    padding-left: 0;
                }
            }
        }
        .nav__inner > ul {
            flex-shrink: 0;
        }

        .nav-filter__actions {
            padding-left: 20px;
            flex-grow: 1;
            width: auto;
            margin-top: 0;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .nav-filter {
        border-radius: 0;
        overflow: visible;

        .list-filter-selected {
            // margin: 0 -20px;
            // display: flex;
            // align-items: center;
            // flex-wrap: wrap;
            // max-width: 70%;

            li {
                padding: 10px 20px;
                background-color: $dark-blue;
                border-radius: 30px;

                a {
                    color: #ffffff;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                }
            }
        }

        .nav-filter__actions {
            margin-top: 60px;
            // display: flex;
            // flex-wrap: wrap;
            // justify-content: space-between;
        }

        .filter-actions-btns {
        }

        .nav__inner {
            margin-bottom: 64px;
        }


        .dropdown-item a {
            border-radius: 20px;
            padding: 0 30px 0 24px;
            width: calc(100% + 20px);
        }
        .dropdown-item {
            position: relative;
        }

        .dropdown-item > a:after {
            right: 25px;
        }

        .dropdown-item.dropdown-open > a {
            border-radius: 20px 20px 0 0;
        }

        .dropdown-item .dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            width: calc(100% + 20px);
            padding-top: 10px;
            background: white;
            z-index: 3;
            a {
                border-radius: 0;
            }
        }
    }

    .nav-filter-single-filter {
        margin: 30px 0 90px;
        padding: 0 0;

        .dropdown-item a {
            padding: 0 45px 0 24px;
        }

        .nav-filter__actions {
            padding-left: 20px;

            margin-top: 0;
        }
    }
    .classic-cat {
        .tabs-navigation{
            .tabs__nav{
                padding-top: 37px;
                padding-bottom: 32px;
            }
        }
        .level1 {
            & > span {
                display: block;
            }
        }
        .infos {
            display: block!important;
            padding: 0;
            column-count: 4;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {


}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
