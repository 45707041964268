// Component : Section Offers Single
.section-offers-single {
    padding: 0;

    .section__head {
    }
    .section__title {
        font-weight: 700;

        text-align: center;
        margin-bottom: 24px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-offers-single {
        padding: 30px 0 240px;
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
