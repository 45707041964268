// Component : Section Applications
.section-applications {
    // overflow: hidden;
    padding: 64px 0;
    .section__body {
        // overflow: hidden;
    }

    .section__title {
        margin-bottom: 64px;
        font-weight: 700;
    }
}

.application {
    text-align: center;
    .application__image {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 40px;
        height: 107px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .application__title {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        line-height: 1.65;
        min-height: 52px;
    }
}

.slider-applications {
    overflow: hidden;
    // padding-left: 8.32%;
    // padding-left: 100px;
    margin-left: -10px;
    margin-right: -10px;
    padding-bottom: 80px;
    .slider__slides {
        padding-bottom: 80px;
        &.no-slider{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            overflow: hidden;
        }
    }

    .slick-slide {
        padding: 0 5px;
    }

    .slider__slide {
        width: 160px !important;
        // max-width: calc(100vw - 130px) !important;
    }

    .slick-dots {
        bottom: 0;
    }

    .slick-list {
        overflow: visible;
        padding-left: calc(50vw - 90px);
    }

    .slick-dots button {
        width: 30px;
    }
    .application__image img{
        max-height: 100px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-applications {
        padding: 80px 0;
        .slider__slides {
            padding-bottom: 40px;
        }
    }

    .slider-applications {
        .slick-list {
            padding-left: 0;
        }
        .slider__slide{
            width: auto!important;
            min-width: 160px;
        }
        .slick-slide{
            box-sizing: border-box;
        }

    }

    .application {
        .application__title {
            font-size: 16px;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-applications {
        padding: 80px 0;
    }
    .slider-applications{
        .slick-track{
            left: 30px;
        }
        .slider__slides {
            padding-bottom: 40px;
        }
        .slick-slide{
            width: 160px!important;

        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-applications {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-applications {
    }
}
