// Component : Section team-map
.section-team-map {
	margin: 40px 0 60px;
	.section__image {
		img {
			width: 100%;
			height: auto;
		}
	}
	.section__foot{
		margin-top: 40px;
	}
	&__btn {
		margin-top: 40px;
	}
}

.section-team-map + .section-news {
	margin-top: 40px;
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {

	.section-team-map + .section-news {
		margin-top: 100px;
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
