// Component : Article Default
.article-default {
    padding: 40px 0;
    & > div{
        margin: 0 auto;
    }
    h4, h2 {
        font-size: 18px;
        line-height: 1.67;
        font-weight: 700;
        margin-bottom: 16px;
    }

    p {
        font-size: 14px;
        line-height: 2;
    }

    .article__content {
        margin-bottom: 40px;
    }
    .article__image {
        img {
            width: 100%;
            height: auto;
        }
    }
    &:last-of-type {
        padding-bottom: 80px;
    }
}

.section-article-default-single {
    padding: 40px 0 0;
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .article-default {
        p {
            font-size: 16px;
            line-height: 1.88;
        }

        h4, h2 {
            font-size: 24px;
            line-height: 1.5;
            margin-bottom: 24px;
        }
        .article__content {
            &.text-center {
                text-align: center;
            }
            margin-bottom: 0;
        }

        &-reversed {
            .row {
                flex-direction: row-reverse;
            }
        }

        &-wide {
            .article__content {
                margin-bottom: 64px;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .article-default {
        padding: 50px 0;

        h4, h2 {
            font-size: 32px;
        }

        &:last-of-type {
            padding-bottom: 140px;
        }

        .article__content {
            padding-right: 50px;
        }

        &-reversed {
            .article__content {
                padding-right: 0;
                padding-left: 50px;
            }
        }

        &-wide {
            .article__content {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    .section-article-default-single {
        padding: 90px 0 0;
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .article-default {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .article-default {
    }
}
