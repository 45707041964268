// Component : Chat Bot
.chat-bot {
	position: fixed;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	visibility: hidden;
	opacity: 0;
	transform: translateY(30px);
	transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
	z-index: 101;

	.btn-close {
		position: absolute;
		top: 12px;
		right: 12px;
		z-index: 3;
	}

	&.show {
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
	}

	.chat-container {
		padding: 26px 17px 120px;
		overflow-y: auto;
		max-height: calc(100% - 74px);
	}
}

.message {
	display: flex;
	align-items: flex-start;
	.message__avatar {
		background: #e5e5e5;
		width: 31px;
		height: 31px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		margin-right: 15px;

		i {
			font-size: 0;
		}
	}

	.message__content {
		flex-grow: 1;
	}

	.message__author {
		display: block;
		font-size: 12px;
		line-height: 1.3;
		color: #8b9ca0;

		margin-bottom: 5px;
	}
	.message__entry {
		display: inline-block;
		font-size: 14px;
		line-height: 1.43;
		padding: 8px 14px;
		background: #f5f5f5;
		border-radius: 0 15px 15px 15px;
	}

	.message__entry + .message__entry {
		margin-top: 6px;
	}

	&--alt {
		.message__content {
			text-align: right;
		}
		.message__entry {
			text-align: left;
			background: #8dced9;
			color: #fff;
			border-radius: 15px 0 15px 15px;
		}
	}

	& + .message {
		margin-top: 20px;
	}
}

.form-chat {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;

	.form__inner {
		position: relative;

		background: #f5f5f5;
		padding: 22px 15px;
		z-index: 2;
	}

	.form-chat__decoration {
		position: absolute;
		bottom: 35px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
	}
	.form-chat__decoration-circle {
		position: absolute;
		z-index: 3;
		top: -16px;

		&--left {
			left: 50%;
			transform: translateX(-84px);
		}
		&--right {
			right: 50%;
			transform: translateX(84px);
		}
	}

	.form__controls {
		position: relative;

		i {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			font-size: 0;

			svg {
				width: 26px;
				height: 26px;
			}
		}

		.field {
			width: 100%;
			height: 30px;
			line-height: 30px;
			border: none;
			background: none;
			box-shadow: none;
			margin: 0;
			padding: 0 42px;
			color: #12282d;
			font-size: 14px;
			font-weight: 700;
			&::placeholder {
				color: #12282d;
			}
		}

		.form__btn {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			border: none;
			padding: 0;
			font-size: 0;
			svg {
				width: 32px;
				height: 27px;
			}
		}
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.chat-bot {
		bottom: 85px;
		right: 30px;
		width: 283px;
		height: 424px;
		box-shadow: 0 2px 4px 0 rgba(139, 156, 160, 0.15);
		border-radius: 10px;
		overflow: hidden;

		.btn-close {
			display: none;
		}

		.chat-container {
			padding: 13px 15px 90px;
			max-height: calc(100% - 50px);
		}
	}

	.message {
		.message__avatar {
			width: 24px;
			height: 24px;
			margin-right: 9px;

			i {
				svg {
					width: 14px;
					height: 14px;
				}
			}
		}

		.message__author {
			display: block;
			font-size: 10px;
			line-height: 1.3;
			margin-bottom: 5px;
		}
		.message__entry {
			font-size: 11px;
			line-height: 1.43;
			padding: 7px 12px;
			border-radius: 0 12px 12px 12px;
		}

		.message__entry + .message__entry {
			margin-top: 4px;
		}

		&--alt {
			.message__entry {
				border-radius: 12px 0 12px 12px;
			}
		}

		& + .message {
			margin-top: 14px;
		}
	}

	.form-chat {
		.form__inner {
			padding: 10px 15px;
		}

		.form-chat__decoration {
			bottom: 22px;
			svg {
				width: 62px;
				height: 96px;
			}
		}
		.form-chat__decoration-circle {
			top: -9px;

			svg {
				width: 20px;
				height: 20px;
			}

			&--left {
				transform: translateX(-64px);
			}
			&--right {
				transform: translateX(64px);
			}
		}

		.form__controls {
			position: relative;

			i {
				svg {
					width: 20px;
					height: 20px;
				}
			}

			.field {
				padding: 0 30px;
				font-size: 12px;
			}

			.form__btn {
				svg {
					width: 19px;
					height: 16px;
				}
			}
		}
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.chat-bot {
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
	.chat-bot {
	}
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
	.chat-bot {
	}
}
