// Component : article-excerpt-video
.article-excerpt-video {
    .article__media {
        //height: 257px;
        padding-top: 0;
        margin-bottom: 0;
    }
    .video-wrapper {
        margin-bottom: 0;
        padding-top: 84%;
    }

    .video-wrapper:before,
    .video-wrapper:after {
        display: none;
    }

    .article__content {
        text-align: center;
        background: #fff;
        a {
            display: block;
            color: #000;
            font-size: 16px;
            line-height: 1.88;
            padding: 25px 10px;
            font-weight: 700;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .article-excerpt-video {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .article-excerpt-video {
    }
    .article-excerpt-video .article__content a {
        font-size: 19px;
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .article-excerpt-video {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .article-excerpt-video {
    }
}
