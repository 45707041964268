// Component : section-timeline
.section-timeline {
    background: #ffca67;
    margin-bottom: 60px;

    .section__head {
        margin: 64px 0 56px;
    }

    .section__body {
    }

    .section__decoration {
        text-align: left;
        svg {
            margin-top: 0px;
            margin-bottom: -49px;
            width: 123px;
            height: 207px;
        }
    }
}

.slider-years {
    overflow: hidden;
    margin: 0 -10px;
    padding-left: 10px;

    h3 {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 700;
        margin: 0;
    }
    .slider__slide {
        position: relative;
        padding: 0 0 41px;
        border-bottom: 2px solid #fff;
        text-align: center;
        &:hover{
            &:before{
                width: 17px;
                height: 17px;
                bottom: -9px;
                transition: 0.4s ease;
                cursor: pointer;
            }
        }
        &:before {
            position: absolute;
            bottom: -8px;
            left: 50%;
            content: '';
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 2px solid #fff;
            transform: translateX(-50%);
            background: #ffca67;
            transition: 0.4s ease;
        }

        &.selected {
            &:after {
                position: absolute;
                bottom: -16px;
                left: 50%;
                content: '';
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 2px solid #fff;
                transform: translateX(-50%);
            }

            &:before {
                background: #fff;
            }
        }
    }
    .slider__slide {
    }

    .slick-list {
        overflow: visible;
    }

    .slick-dots {
        position: static;
        width: 100%;
        transform: none;
        justify-content: center;
        margin: 31px 0 40px;

        li {
            padding: 0 8px;
            button {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 1px solid #fff;
                background: none;
            }


            &.slick-active {
                button {
                    background: #fff;
                }
            }
        }
    }
}

.slider-years-content {
    .slider__slide {
        padding: 0 70px;
    }

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 2;
        max-width: 680px;
        margin: 0 auto;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-timeline {
    }

    .slider-years {
        padding-bottom: 30px;
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-timeline {
        margin:100px 0 130px;
        .section__head {
            margin: 80px 0 64px;
        }

        .section__body {
            position: relative;
        }

        .section__decoration {
            position: absolute;
            right: 100%;
            bottom: -87px;
            transform: translateX(100px);
            svg {
                width: 240px;
                height: 404px;
                margin: 0;
            }
        }
    }

    .slider-years {
        margin: 0;
        padding-bottom: 56px;
        h3 {
            font-size: 32px;
        }

        .slider__slide {
            padding-bottom: 32px;
        }
    }

    .slider-years-content {
        padding-bottom: 80px;
        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.88;
        }

        .slider__slide {
            margin: 0 auto;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-timeline {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-timeline {
    }
}
