// Component : section-product-info
.section-product-info {
    padding: 40px 0;

    h2 {
        font-size: 18px;
        line-height: 1.67;
        margin: 0 0 16px;
        font-weight: 700;
    }

    p {
        line-height: 2;
        margin-bottom: 0;
    }

    .section__group + .section__group {
        margin-top: 40px;
    }

    .section__aside {
        position: relative;
        text-align: center;
        padding: 40px 38px 56px;
        background: #50b7c9;
        color: #fff;

        h3 {
            font-size: 18px;
            font-weight: 700;
            line-height: 1.67;
            margin-bottom: 24px;
        }

        p {
            font-size: 14px;
            line-height: 2;
            margin-bottom: 24px;
        }

        .decoration {
            position: absolute;

            &--head {
                top: 121px;
                left: 0;
                margin-left: -102px;
                z-index: -1;
            }

            &--hand {
                top: 321px;
                left: 0;
                margin-left: -9px;
                z-index: 2;
            }
        }
    }

    .section__content {
        padding-bottom: 80px;

        /*.btn {
            display: block;
            width: 100%;
            padding: 0 10px;
        }*/
    }
    .list-contacts-alt {
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        padding: 23px 0;
        max-width: 100%;

        li {
            max-width: 200px;
            margin: 0 auto;
        }
    }
}

.list-btns-square {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;

    li {
        padding: 0 12px;
        margin-bottom: 24px;
        width: auto;
    }

    .btn {
        width: auto;
        min-width: 0;

        &:focus {
            background-color: #f08576;
            color: #ffffff;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-product-info {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-product-info {
        padding: 80px 0 40px;

        h2 {
            font-size: 32px;
            line-height: 1.16;
            margin: 0 0 24px;
        }

        p {
            font-size: 16px;
            line-height: 1.88;
        }

        .section__group + .section__group {
            margin-top: 48px;
        }

        .section__content {
            padding-bottom: 0;
        }

        .section__aside {
            text-align: center;
            padding: 56px 50px 56px;

            h3 {
                font-size: 28px;
            }

            p {
                font-size: 16px;
                line-height: 1.88;
                margin-bottom: 32px;
            }

            .btn {
                padding: 0 10px;
                min-width: 211px;
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-product-info {
        .section__aside {
            .btn {
                padding: 0 30px;
                min-width: 236px;
            }
        }
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-product-info {
    }
}
