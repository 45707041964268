// Component : Section Philosophy
.section-philosophy {
    padding-bottom: 40px;
    .section__head {
        margin: 80px 0 40px;
    }

    .section__body {
        margin-bottom: 40px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-philosophy {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-philosophy {
        padding-bottom: 40px;

        .section__head {
            margin-top: 140px;
            margin-bottom: 72px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-philosophy {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-philosophy {
    }
}
