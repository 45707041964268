// Component : products-recent
.section-products-recent {
	margin: 40px 0 60px;
}

.products-recent {
	.product-recent {
		margin-bottom: 40px;
		&:hover{
			img{
				transform: scale(1.2);
				transition: .4s;
			}
		}
	}
}

.product-recent {
	a {
		display: flex;
		align-items: center;
		color: #12282d;
		background: #ffffff;
		box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);

		&:hover {
			text-decoration: none;
		}
	}
	.product__image {
		display: block;
		flex-shrink: 0;
		width: 37%;
		overflow: hidden;
		img {
			width: 100%;
			height: 130px;
			object-fit: cover;
			transition: .4s;
		}
	}

	.product__entry {
		position: relative;
		display: block;
		flex-grow: 1;
		padding: 10px 30px 10px 21px;

		:after {
			position: absolute;
			top: 50%;
			right: 10px;
			content: '';
			line-height: 0;
			display: table;
			clear: both;
			background: url(/images/svg/svg-right-arrow.svg) 0 0 no-repeat;
			background-size: 100% 100%;
			width: 17px;
			height: 13px;
			transform: translateY(-50%);
		}
	}

	strong, .product-recent-title {
		display: block;
		font-size: 16px;
		font-weight: 700;
		line-height: 1.5;
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.product-recent {
		.product__entry {
			padding: 10px 30px 0 10px;
		}
		strong, h3 {
			font-size: 14px;
		}
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.product-recent {
		.product__entry {
			padding: 10px 30px 0 22px;
		}
		strong, .product-recent-title {
			font-size: 16px;
		}
	}

	.products-recent {
		.product-recent {
			margin-bottom: 40px;
		}
	}

	.section-products-recent {
		.section__head {
			margin-bottom: 67px;
		}
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
