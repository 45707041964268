// Component : Chat Bot
.chatbot-trigger {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	background: #12282d;
	box-shadow: 0 2px 4px 0 rgba(0, 114, 134, 0.15);
	transition: background 0.4s;

	position: fixed;
	z-index: 9;
	bottom: 15px;
	right: 15px;

	.close-icon {
		display: none;
	}

	.open-icon {
		display: block;
	}

	&:hover {
		background: rgba(18, 40, 45, 0.8);
	}

	&.active {
		.close-icon {
			display: block;
		}

		.open-icon {
			display: none;
		}
	}
}

.toolbox {
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.chatbot-trigger {
		right: 30px;
		bottom: 30px;
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.chatbot-trigger {
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
	.chatbot-trigger {
	}
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
	.chatbot-trigger {
	}
}
