// Component : section technology
.section-technology {
    padding: 108px 0 80px;

    p {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 28px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .section__title {
        margin: 0 0 40px;
        font-weight: 700;
        font-size: 30px;
    }

    .section__content {
        margin-bottom: 40px;
    }

    .section__image {
        position: relative;
        width: 259px;
        padding-right: 64px;

        margin-left: 7px;
        padding-top: 24px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 67px;

        img {
            position: relative;
            z-index: 2;
        }
    }

    .section__image__decoration {
        position: absolute;
        &--left {
            z-index: 3;
            left: -7px;
            top: 85px;

            svg {
                width: 16px;
                height: 19px;
            }
        }
        &--right {
            z-index: 1;
            top: 0;
            right: 0;

            svg {
                width: 100px;
                height: 308px;
            }
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-technology {
        .section__inner {
            display: flex;
        }

        .section__content {
            flex-grow: 1;
            padding-right: 99px;
        }
        .section__image {
            flex-shrink: 0;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-technology {
        padding: 201px 0 140px;

        p {
            font-size: 16px;
            line-height: 1.88;
            margin-bottom: 32px;
        }

        .section__image {
            width: 372px;
            padding-right: 84px;
            margin-left: 7px;
            padding-top: 32px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 90px;

            img {
                position: relative;
                z-index: 2;
            }
        }

        .section__image__decoration {
            &--left {
                left: -10px;
                top: 114px;

                svg {
                    width: 23px;
                    height: 29px;
                }
            }
            &--right {
                top: 0;
                right: 0;

                svg {
                    width: 132px;
                    height: 408px;
                }
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-technology {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-technology {
    }
}
