// Component : section-contact-main
.section-contact-main {
    padding: 40px 0 0px;

    .section__aside {
        margin: 0 -10px;
    }

    .section__title {
        margin-bottom: 32px;
        font-size: 24px;
        font-weight: 700;
    }

    .section__head {
        margin-bottom: 40px;
        p {
            font-size: 14px;
            line-height: 2;
            margin-bottom: 0;
        }
    }

    .form-contact .form__controls {
        margin-bottom: 48px;
    }
}

.sidebar-box {
    background: #8dced9;
    padding: 64px 0;

    h4 {
        color: #fff;
        margin-bottom: 40px;
        font-size: 24px;
        line-height: 1.33;
        font-weight: 700;
        text-align: center;
        margin-bottom: 40px;
    }

    .socials {
        margin-top: 40px;
        ul {
            justify-content: space-around;
        }
    }

    .list-tags-small {
        margin-bottom: 5px;
    }

    .sidebar-box__actions {
        padding-top: 56px;
        .btn {
            width: 100%;
            padding: 0 10px;
        }
    }

    &--primary {
        padding: 60px 85px;
        padding-left: calc(8.32% + 19px);
        padding-right: calc(8.32% + 19px);
    }
}

.sidebar-box + .sidebar-box {
    margin-top: 64px;
}

.sidebar-box--subscribe {
    padding-left: calc(8.32% + 19px);
    padding-right: calc(8.32% + 19px);
}

.location {
    .location-label {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.88;
        margin-bottom: 16px;
        color: #fff;
    }
    .location-label i {
        margin-right: 24px;
        font-size: 0;
    }

    a {
        display: block;
        padding: 0 10px;
        text-align: center;
        height: 57px;
        line-height: 57px;
        background: #fff;
        border-radius: 29px;
        font-size: 16px;
        font-weight: 700;
        color: #12282d;

        &:hover {
            background: #dae3e4;
            color: #007286;
        }
    }

    small {
        color: #fff;
        display: block;
        text-align: center;
        font-size: 16px;
        line-height: 1.88;
        font-weight: 400;
        width: 100%;
    }
}

.slider-locations {
    overflow: hidden;
    padding-left: 8.32%;
    .slider__slides {
        padding-bottom: 40px;
    }

    .slick-slide {
        padding: 0 19px;
    }

    .slider__slide {
        width: 268px !important;
        max-width: calc(100vw - 70px) !important;
    }

    .slick-dots {
        bottom: 0;
    }

    .slick-list {
        overflow: visible;
    }

    .slick-dots .slick-active button {
        background: #fff;
        background-clip: content-box;
    }
}

.subscribe-form {
    .subscribe__field {
        background: #fff;
        height: 57px;
        line-height: 57px;
        border: none;
        border-radius: 29px;
        padding: 0 50px 0 30px;
        color: #8b9ca0;
        font-size: 13px;
        font-weight: 600;
        font-style: italic;
        box-shadow: none;
        margin-bottom: 0;
    }
    .subscribe__btn {
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 13px;
        font-weight: 600;
        background: none;
        border: none;
    }
    .form__controls {
        position: relative;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-contact-main {
        .section__aside {
            margin: 0;
        }
    }

    .slider-locations {
        overflow: hidden;
        padding-left: 0;
        .slider__slides {
            padding-bottom: 0;
        }

        .slick-slide {
            padding: 0 19px;
        }

        .slider__slide {
            width: 100% !important;
            max-width: 100% !important;
        }

        .slick-dots {
            bottom: 0;
        }

        .slick-list {
            overflow: visible;
        }

        .slick-track {
            width: 100% !important;
        }

        .slick-slide {
            width: 100%;
            padding: 0;
        }
        .slick-slide + .slick-slide {
            margin-top: 40px;
        }
    }

    .sidebar-box {
        padding: 42px 10px 48px;

        h4 {
            font-size: 28px;
        }

        &--primary {
            padding: 60px 35px;
            padding-left: calc(7% + 19px);
            padding-right: calc(7% + 19px);
        }
    }

    .sidebar-box .sidebar-box__actions .btn {
        max-width: 100%;
        min-width: 200px;
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-contact-main {
        padding: 70px 0 100px;
        .section__head {
            margin-bottom: 80px;

            p {
                font-size: 16px;
                line-height: 1.88;
            }
        }
        .section__title {
            margin-bottom: 40px;
            font-size: 40px;
        }

        h3.section__title {
            margin-bottom: 40px;
            font-size: 32px;
        }
        .form-contact .form__controls {
            margin-bottom: 64px;
        }
    }

    .sidebar-box {
        padding: 42px 50px 48px;

        .location {
            max-width: 220px;
            margin-left: auto;
            margin-right: auto;
        }
        &--primary {
            padding: 60px 85px;
            padding-left: calc(7.5% + 19px);
            padding-right: calc(7.5% + 19px);
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-contact-main {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-contact-main {
    }
}
