// Component : section docs
.section-docs {
    .document__entry{
        .title {
            text-transform: uppercase;
        }
    }
}

.section-docs {
    padding: 40px 0 8px;

    &--large-paddings {
        padding: 40px 0;
        .catalog-category-view &{
            padding: 0;
        }
    }

    .section__title {
        font-weight: 700;
        width: 100%;
        margin-bottom: 40px;
    }

    .document__entry p:not(.title) {
        font-size: 13px !important;
        margin-bottom: 0 !important;

        & + p {
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
             -webkit-line-clamp: 3;
             -webkit-box-orient: vertical;

            & + p {
                height: auto;
            }
        }
    }
}

.section-docs--alt {
    padding: 40px 0 48px;

    .section__head {
        margin-bottom: 32px;
    }
}

.section-docs--guide {
    padding: 80px 0 8px;

    .section__title {
        margin-bottom: 32px;
        font-size: 24px;
    }

    .section__head {
        margin-bottom: 40px;
        p {
            font-size: 14px;
            line-height: 2;
        }
    }
}

.document {
    position: relative;
    margin-bottom: 32px;
    display: flex;
    overflow: hidden;
    align-items: center;
    color: #12282d;
    background: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);

    &:hover{
        .document__image {
            img{
                transform: scale(1.2);
                transition: .4s;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    transform: scale(1.2) translateY(-50%);
                }
                &.doc-svg{
                    transform: scale(1);
                }
            }
        }
    }
    &.docs-top{
        .row{
            align-items: center;
        }
    }
    .title,
    .product.name{
        font-size: 16px;
        line-height: 1.19;
        margin: 0 0 5px;
        font-weight: 700;
        color: black;
    }
    p {
        font-size: 13px;
        line-height: 1.69;
        margin: 0;
        color: black;
    }
    a{
        display: flex;
        align-items: center;
        width: 100%;
    }
    .price {
        font-size: 16px;
        color: #50b7c9;
    }

    .label {
        position: absolute;
        top: 6px;
        right: 6px;
        display: inline-block;
        background: #50b7c9;
        border-radius: 11px;
        line-height: 22px;
        height: 22px;
        padding: 0 10px;
        font-size: 11px;
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
    }
    .product-image-wrapper{
        height: auto;
        padding: 0!important;
        display: initial;
    }
    .row{
        width: 100%;
    }
    .document__image {
        display: inline-block;
        position: relative;
        // flex-shrink: 0;
        // width: 37%;
        // position: relative;
        overflow: hidden;
        &__container{
            max-width: 132px;
            overflow: hidden;
            text-align: center;
            display: flex;
            justify-content: center;
            align-content: center;
            min-height: 133px;
            //IE HACK
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				display: block
			}
            &.svg{
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        img {
            // height: 135px;
            object-fit: cover;
            transition: .4s;
            display: initial;
            transform: scale(1);
            max-height: 133px;
            &.doc-svg{
                transform: scale(0.8);
            }
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                position: absolute;
                top: 50%;
                transform: scale(1) translateY(-50%);
			}
        }
        a {
            display: block;
            position: relative;
            overflow: hidden;
            min-height: 135px;
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: 50px;
            height: 50px;
            background: #fff;

            svg {
            }
        }
    }

    .document__entry {
        // position: absolute;
        // top: 0;
        // right: 0;
        // width: 63%;
        // height: 100%;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // flex-grow: 1;
        display: inline-block;
        padding: 20px 26px 10px 16px;
        a{
            color: black;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-docs {
    }

    .document {
        // .document__image {
        //     width: 37%;
        // }
        .document__entry {
            padding-left: 5px;
        }
        .title {
            margin-bottom: 3px;
        }
        p {
            font-size: 12px;
            height: 60px;
            overflow: hidden;
        }
        .label {
            font-size: 8px;
            line-height: 16px;
            height: 16px;
            padding: 0 5px;
        }
        &.docs-top{
            p{
                height: auto;
                line-height: 20px;
            }
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-docs {
        padding: 70px 0 38px;

        &--large-paddings {
            padding: 30px 0;
            .catalog-product-view{
                padding: 140px 0 110px;
            }
            .catalog-category-view &{
                padding: 0;
            }
        }
        .section__title {
            font-weight: 700;
            width: 100%;
        }
    }

    .section-docs--alt {
        padding-top: 40px;
        padding-bottom: 108px;

        .section__head {
            margin-bottom: 69px;
        }
    }

    .section-docs--guide {
        padding: 140px 0 38px;

        .section__head {
            margin-bottom: 80px;

            p {
                font-size: 16px;
                line-height: 1.88;
            }
        }

        .section__title {
            font-size: 40px;
            margin-bottom: 40px;
        }
    }

    .document {
        .document__image__container {
            // width: 37%;
        }
        .document__entry {
            padding: 20px 27px 10px 10px;
        }

        .title {
            margin-bottom: 8px;
        }

        p {
            font-size: 13px;
            height: auto;
        }
        .label {
            top: 9px;
            right: 9px;
            line-height: 22px;
            height: 22px;
            padding: 0 10px;
            font-size: 11px;
        }
    }
    .products.wrapper {
        &~ .toolbar
        .pages{
            float: none!important;
            .item{
                font-size: 16px;
            }
            a.page{
                font-size: 16px;
            }
            li{
                &.current{

                }
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-docs {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-docs {
    }
}
