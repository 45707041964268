// Component : Search Overlay
.search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    transform: translateY(-100%);
    transition: transform 0.3s;
    &:before{
        content:'';
        position: absolute;
        top: 0;
        top: -100vh;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(black, 0.8);
        z-index: -1;
        transition: all 0.5s ease;
    }
    .btn-close {
        position: absolute;
        top: 15px;
        right: 20px;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    &.show {
        transform: translateY(0);
        &:before{
            top: 0;
            transition: all 0.3s ease;
        }
    }
}

.search-main {
    background: #f5f5f5;
    padding: 50px 30px 25px;

    .form__controls {
        position: relative;
    }

    .search__field {
        border: none;
        background: none;
        box-shadow: none;
        border-bottom: 2px solid #9ec6cd;
        padding: 0 30px;
        height: 38px;
        line-height: 38px;
        width: 100%;
        margin: 0;
        box-sizing: border-box;
        color: #8dbdc6;
    }
    .search__btn {
        width: auto;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #007286;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 28px;
        font-size: 16px;
    }
    i {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0;

        svg {
            width: 15px;
            height: 15px;
        }
    }
    i svg {
    }
}

// Specific search page result
.catalogsearch-result-index {
    .intro {
        &__background {
            display: flex;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: 50%;
            text-align: center;
            color: white;
            min-height: 415px;
        }
    }

    .message {
        text-align: center;
        padding: 20px 0;
        display: inherit;
    }

}

.search-results {
    background: #fff;
    padding: 0;
    overflow-y: auto;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: height 0.3s, opacity 0.3s, visibility 0.3s;

    .margin-auto {
        margin: 0 auto;
    }

    &.show {
        visibility: visible;
        opacity: 1;
        padding: 40px 0;
    }
    .price{
        display: none;
    }
    .document{
        .search-doc-typo{
            text-transform: uppercase;
        }
    }
}

.section-suggestion {
    .section__head {
        margin-bottom: 40px;
    }
    .section__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.55;
        margin-bottom: 0;
    }

    .section__foot {
        padding-top: 8px;
        text-align: center;
    }

    .article-excerpt {
        margin-bottom: 32px;
        box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);
        .article__content{
            padding: 27px 33px 46px;
            min-height: 255px;
        }
    }
}
.section-suggestion + .section-suggestion {
    margin-top: 40px;
}

.widget-category {
    margin-bottom: 32px;
    h4 {
        color: #12282d;
        font-size: 28px;
        line-height: 1.18;
        margin-bottom: 32px;
        font-weight: 700;
    }

    ul {
        list-style-type: none;
    }
    li + li {
        margin-top: 24px;
    }
    ul a {
        font-size: 16px;
        text-decoration: none;
        line-height: 1.19;
        color: #12282d;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .search-overlay {
        .btn-close {
            top: 13px;
            right: 30px;

            svg {
                width: 26px;
                height: 26px;
            }
        }
    }
    .search-results.show {
        padding: 80px 0 0;
    }

    .section-suggestion {
        .section__title {
            font-size: 40px;
        }

        .section__foot {
            text-align: left;
        }
        .article-excerpt{
            .article__content{
                padding: 27px 33px 46px;
            }
            p{
                min-height: 40px;
            }
        }
    }
    .section-suggestion + .section-suggestion {
        margin-top: 100px;
    }
    .section-suggestion:last-of-type {
        padding-bottom: 80px;
    }

    .search-main {
        padding: 56px 30px 56px;

        .search__field {
            border: none;
            padding: 0 74px;
            height: 50px;
            line-height: 50px;
            color: #8b9ca0;
            font-size: 28px;
            font-weight: 300;
        }
        .search__btn {
            width: auto;
            font-size: 28px;
        }
        i {
            svg {
                width: 29px;
                height: 29px;
            }
        }
        i svg {
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .catalogsearch-result-index {
        .intro {
            margin-top: -50px;
        }
    }
    
    .search-overlay {
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .search-overlay {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .search-overlay {
    }
}
