// for mobile display


.e-notice {
   margin-top: -50px;
   h2 {
      font-family: "Raleway", sans-serif;
      font-size: 28px;
      color: #12282D;
      font-weight: bold;
      margin-bottom: 45px;
      margin-top:40px;
   }

   select {
      border: 2px solid #007286;
      color: #007286;
      border-radius: 29px;
      width: 179px;
      min-height: 57px;
      padding-left: 50px;
      font-family: "Raleway", sans-serif;
      background-position: right 0rem center;
      background: #f4f4f4;
   }
   input[type=text] {
      border: 2px solid #007286;
      color: #007286;
      border-radius: 29px;
      font-size: 16px;
      font-family: "Raleway", sans-serif;
      padding-left: 30px;
      line-height: 42px;
      min-height: 57px;
      width: calc(100% - 50px);
      float: right;
      background: #f4f4f4;
      &::placeholder {
         color: #007286;
      }
   }
   #notice-information{
     position: relative;
     top: 30px;
   }

   .e-notice__wrapper_full{
      background: #f4f4f4;
   }
   .icon-info {
      font-size: 27px;
      color: #007286;
      position: relative;
      top: 14px;
      margin-right: 31px;
      &:hover{
         cursor: pointer;
      }
   }
   &__header{
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background-position: 50%;
      text-align: center;
      color: white;
      min-height: 160px;
      h1{
         color: white;
         font-weight: 700;
         line-height: 1.27;

      }
   }

   &__wrapper {
      p {
         font-family: "Raleway", sans-serif;
         color: #8B9CA0;
         font-size: 16px;
      }
   }
   &__instruction{
      width: 100%;
      overflow: hidden;
   }
   &__col {
      display: inline-block;
      vertical-align: top;
      .select-container,
      .button-container{
         width: 100%;
         margin-bottom: 20px;
         select,
         button{
            width: 100%;
         }
      }
      .select-container{
         position: relative;
         padding-right: 10px;
         &:after{
            content: '';
            position: absolute;
            right: 40px;
            top: 50%;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 7px solid #007286;
            transform: translateY(-50%);
         }
      }
      .input-container{
         float: right;
      }
   }
   .notice-container{
      width: 100%;
      text-align: center;

      &:hover {
         cursor: pointer;
      }
   }

   #notice-contact {
      position: relative;
      top: 3px;
      width: 100%;
      display: block;
      margin-bottom: 50px;
   }

   .disko-asset-autocomplete-result {
      margin-bottom: 80px;
      
      tbody, td, tr, th {
         border: 0px solid #ffffff;
         background-color: #ffffff;
         text-align: left;
         font-family: "Raleway", sans-serif;
         font-size: 16px;
         padding: 0;
      }

      thead{
         display: none;
      }
      .notice{
         display: block;
      }
      tr {
         padding-bottom: 5px;
         td{
            display: block;
            position: relative;
            padding-left: 110px;
            min-height: 45px;
            width: 100%;
            &:before{
               content: attr(data-title);
               position: absolute;
               top: 6px;
               left: 6px;
               width: 45%;
               padding-right: 10px;
               white-space: nowrap;
               text-align:left;
               font-weight: bold;
            }
         }
      }
   }
}
.dl-acrobatReader{
   display: block;
   margin-bottom: 80px;
   line-height: 30px;
   color: #53B7CF;
   font-weight: 700;
   text-decoration: underline;
   &:hover{
      color: #53B7CF;
   }
   .ico-container{
      position: relative;
      width: 20px;
      height: 20px;
      margin-left: 16px;
      border-radius: 3px;
      display: inline-block;
      background-color: #53B7CF;
      svg{
         fill: white;
         position: absolute;
         width: 80%;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }
}
.notice {
   &__btn {
      display: block;
      background-color: #007286;
      color: #ffffff;
      font-size: 20px;
      border-radius: 50%;
      text-align: center;
      width: 38px;
      height: 38px;
      line-height: 38px;
      margin-bottom: 35px;
      &:hover{
         color: #ffffff;
      }
   }

   p {
      margin-bottom: 0px;
   }
}

.notice-popin {
   position: fixed;
   background: rgba(0, 0, 0, 0.5);
   width: 100%;
   height: 100%;
   margin: 0!important;
   padding: 78px 18px 48px;
   top: 0;
   left: 0;
   z-index: 1000;
   display: none;
   overflow: auto;
   .phone-picto{
      display: inline-block;
      width: 50px;
      height: 33px;
      vertical-align: sub;
      svg{
         height: 100%;
         fill: #8B9CA0;
      }
   }

   &__title {
      font-family: "Raleway", sans-serif;
      font-size: 30px;
      font-weight: bold;
      display: inline-block;
   }
   &__sub-title {
      color: #8B9CA0;
      font-family: "Raleway", sans-serif;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 25px;
      display: block;
   }
   tr{
      font-weight: bold;
      td,
      th{
         float: left;
         &:first-of-type{
            display: inline-block;
            text-align: left;
            width: 100px;
         }
      }
   }
   .mfp-close {
      border: none;
      position: absolute;
      top: 85px;
      right: 35px;
      opacity: 1;
      font-size: 0;
      line-height: 0;
      padding: 5px;
      z-index: 3;
      background: url(../images/svg/ico-close.svg) 0 0 no-repeat;
      width: 18px;
      height: 18px;
      margin-top: 20px;
      margin-right: 0;
      cursor: pointer;
   }

   &__content {
      background-color: #ffffff;
      padding-bottom: 20px;
   }
  &__item{
     padding: 15px;
  }
}

.diagnostics-pi-index .modal-header .action-close {
   display: none;
}

@media screen and #{breakpoint(medium up)} {
  .e-notice{
     &__header{
         min-height: 320px;
     }
     &__col{
        .input-container{
           float: none;
           padding-right: 0;
           width: 100%;
        }
     }
     input[type=text] {
        float: none;
        margin-left: 40px;
     }
     .notice-container{
        margin-bottom: 30px;
     }
     #notice-contact{
        margin-bottom: 50px;
     }
     .dl-acrobatReader{
         line-height: 57px;
      }
     .disko-asset-autocomplete-result{
        margin-bottom: 80px;
        thead{
           display: block;
        }
        .notice{
           display: block;
           overflow: hidden;
        }
        tr {
           padding-bottom: 5px;
           td{
              display: block;
              position: relative;
              padding-left: 0;
              min-height: 45px;
              width: 100%;
              &:first-child {
                 padding-left: 0;
                 width: 100%;
                 float: left;
                 &:before {
                    display: none;
                 }
              }
              &:before{
                 display: none;
              }
           }
        }
        thead{
           tr{
              width: 100%;
              display: block;
              overflow: hidden;
              padding-bottom: 35px;
           }
           th{
              display: inline-block;
              min-width: 90px;
              &:first-of-type(){
                 width:50%;
              }
              &:last-of-type(){
                 float: right;
                 text-align: right;
              }
           }
        }
        tbody{
           tr{
              td{
                 display: inline-block;
                 min-width: 90px;
                 min-height: 40px;
                 width: auto;
                 &:first-of-type(){
                    width: 50%;
                 }
                 &:last-of-type(){
                    float: right;
                    text-align: right;
                 }
              }
           }
        }
     }
  }
   .notice-popin{
      tr{
         td,
         th{
            &:first-of-type{
               width: 250px;
            }
         }
      }
   }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {

   .e-notice{
      h2{
         margin-top: 75px;
         margin-bottom: 48px;
      }
      &__wrapper{
         padding-top: 64px;
         margin-bottom: 0;
         padding-bottom: 20px;
         background: hsl(0, 0%, 96%);
      }
      &__header{
         min-height: 350px;
         h1{
            font-size: 48px;
         }
      }
      &__instruction{
        position: absolute;
      }
      .e-notice__wrapper_full{
         background: #f4f4f4;
      }
      #notice-information{
         top: 0;
      }
      input[type=text] {
         width: 340px;
         margin-right: 0;
         margin-left: 0;
         display: inline-block;
      }
      &__col{
         .select-container{
            width: 200px;
            &:after{
               right: 45px;
            }
            select{
               padding-left: 30px;
               margin-right: 40px;
            }
         }
        .input-container{
           width: auto;
           padding-right: 15px;
        }
         .button-container{
            width: 255px;
            padding: 0 20px;
         }
      }
      .notice-container{
         width: auto;
      }
   }
   #notice-contact {
      margin-left: 0;
      display: inline-block;
   }

   #notice-information-popin {
      .notice-popin__content {
         text-align: center;
      }
   }

   .notice{
      &__btn{
         margin-bottom: 5px;
      }
   }
   .notice-popin {
      .mfp-close {
         right: 40px;
         top: 40px;
         width: 40px;
         height: 40px;
         background-size: 100% 100%;
         margin-top: 0;
      }

      &__title {
         font-family: "Raleway", sans-serif;
         font-size: 30px;
         font-weight: bold;

         &.mt-big {
            margin-bottom: 30px;
         }
      }

      &__sub-title {
         color: #8B9CA0;
         font-family: "Raleway", sans-serif;
         font-size: 16px;
         font-weight: bold;
         margin-bottom: 25px;
         display: block;
      }

      // &__item {
      //    margin-bottom: 50px;
      // }

      &__content {
         width: 864px;
         @include absolute-center;
         padding: 70px;

         tbody, td, tr, th {
            border: 0px solid #ffffff;
            background-color: #ffffff;
            text-align: left;
            font-family: "Raleway", sans-serif;
            font-size: 13px;
            padding: 0;
            font-weight: normal;
            line-height: 30px;
         }

         tr {
            td:first-child, th:first-child {
               font-weight: bold;
            }
         }
      }
      tr{
         td,
         th{
            display: table-cell;
            float: none;
            &:first-child{
               width: auto;
               display: table-cell;
            }
         }
      }
   }
   .disko-asset-autocomplete-result{
      thead{
         tr{
            width: 100%;
         }
         th{
            min-width: 90px;
            &:first-of-type(){
               width:58%;
            }
            &:last-of-type(){
               float: right;
               text-align: right;
            }
         }
      }
      tbody{
         tr{
            td{
               &:first-of-type(){
                  width: 58%;
               }
               &:last-of-type(){
                  float: right;
                  text-align: right;
               }
            }
         }
      }
   }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
   .e-notice{
      &__header{
         min-height: 400px;
      }
      input[type=text] {
         width: 509px;
      }
   }
}

/** Autocomplete **/
.diagnostics-pi-index {
   .ui-autocomplete {
      position: absolute;
      cursor: default;
   }

   .ui-autocomplete.ui-menu {
      list-style: none;
      padding: 2px;
      margin: 0;
      display: block;
      float: left;
      color: #fff;
      background-color: #007286;
      border: 1px solid #007286;
      border-radius: 3px 3px 3px 3px;
   }

   .ui-autocomplete.ui-menu .ui-menu {
      margin-top: -3px;
   }

   .ui-autocomplete.ui-menu .ui-menu-item {
      margin: 0;
      padding: 0;
      zoom: 1;
      float: left;
      clear: left;
      width: 100%;
      font-family: "Raleway", sans-serif;
   }

   .ui-autocomplete.ui-menu .ui-menu-item a {
      text-decoration: none;
      display: block;
      padding: .1em .3em;
      line-height: 1.5;
      zoom: 1;
      color: #fff;
   }

   .ui-autocomplete.ui-menu .ui-menu-item a.ui-state-hover, .ui-menu .ui-menu-item a.ui-state-active {
      font-weight: bold;
   }

   .e-notice .ui-helper-hidden-accessible {
      display: none;
   }
}