// LIST
.list {
    .products {
        &-push {
            display: none;
        }

        &-item {
            background-image: none !important;

            &-content {
                width: 100%;
                display: flex;
                align-items: center;

                .media-button {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: -100%;
                    transform: translate3d(-100%, 0, 0);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 15px;
                    padding: 1em;
                    text-align: center;
                    line-height: 16px;
                }

                .media {
                    width: 110px;
                    display: flex;
                    align-items: center;
                }

                .media,
                .main,
                .main-link {
                    height: 60px;
                }

                .main {
                    padding: 0 1em;
                    width: 100%;
                    
                    &-link {
                        display: block;
                        padding: 0 1em;
                    }

                    .title,
                    .footer {
                        position: absolute;
                        transition: all .2s ease-in-out;
                    }

                    .header {
                        width: calc(100% - 4em);
                    }

                    .title {
                        top: 50%;
                        transform: translate3d(0, -50%, 0);
                    }

                    .footer {
                        display: none;
                        bottom: 0;
                        transform: translate3d(0, 100%, 0);
                    }
                }


                .action {
                    display: none;
                }
            }
        }
    }
}

// GRID
.grid {
    .products {
        &-push,
        &-item {
            position: relative;
            background-size: cover;
            background-position: bottom center;
            min-height: 190px;
        }

        &-push {
            &-content {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgba($black, 0.3);
                color: $white;
                padding: 10px;
                font-weight: bold;
                text-align: center;
                display: flex;
                align-items: center;

                &-inner {
                    width: 100%;
                }

                .title {
                    font-size: 21px;
                    line-height: 1.2em;
                    margin-bottom: 0.3em;
                }

                .desc {
                    font-size: 16px;
                    line-height: 1.2;
                }

                .btn {
                    border-radius: 30px;
                }
            }
        }

        &-item {
            transition: all .4s;
            background-position-y: 190px;

            &-content {
                position: absolute;
                width: 100%;
                height: 40%;
                bottom: 0;
                padding: 1em;
                margin: 0;
                background: $white;
                transition: all .4s;

                .title {
                    font-size: 16px;
                }

                .media {
                    display: none;
                }

                .header {
                    position: relative;
                    height: 30px;
                }

                .labels {
                    top: 0;
                    transform: translate3d(0, 0, 0);
                }

                .description {
                    margin-top: 15px;
                    position: relative;
                    max-height: 75px;
                }

                .action {
                    margin-top: 0.5em;
                }

                .description,
                .action {
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity .4s;
                }
            }
        }
    }
}

// COMMON
.products-wrapper {
    transition: .4s ease-in-out;
}

.products {
    margin-bottom: 140px;
    
    &-item,
    &-push {
        margin-bottom: 30px;
    }
    
    &-item {
        &-content {
            overflow: hidden;
            box-shadow: 0 2px 5px 0 rgba(0, 114, 134, 0.15);

            .title {
                font-size: 13px;
                font-weight: 800;
                margin-bottom: 0;
                color: $black;
                display: block;
            }

            .labels {
                top: 50%;
                right: -100%;
                transform: translate3d(0, -50%, 0);
                transition: .4s;

                &-unit {
                    background: none;
                    border: 1px solid $light-blue;
                    padding: 0.3em 0.5em;
                    border-radius: 20px;
                    color: $light-blue;
                    font-size: 0.8rem;

                    &:not(:last-child) {
                        margin-right: 4px;
                    }
                }
            }

            .description,
            .description p {
                font-size: 13px;
                line-height: 1.44;
                color: $black;
            }

            .action {
                border-radius: 20px;
                padding: 4px 28px;
                display: inline-block;
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    // COMMON
    .products {
        &-item {
            &:hover {
                cursor: pointer;
            }
        }
    }
    
    // LIST
    .list {
        .products {
            &-item {
                .main {
                    padding: 0;

                    &-link {
                        padding: 0 2em;
                    }

                    .footer {
                        display: block;
                    }
                }

                &:hover {
                    .media-button {
                        left: 0;
                        transform: translate3d(0, 0, 0);
                    }

                    .main {
                        .title {
                            top: -100%;
                            transform: translate3d(0, 100%, 0);
                        }

                        .labels {
                            right: 2%;
                        }

                        .footer {
                            bottom: 50%;
                            transform: translate3d(0, 50%, 0);
                            max-width: 65%;
                        }
                    }
                }
            }
        }
    }
    
    // GRID
    .grid {
        .products {
            &-item {
                &:hover {
                    background-position: 100% 110%;
                    cursor: pointer;

                    .products-item-content {
                        height: 100%;

                        .title {
                            display: none;
                        }

                        .labels {
                            right: 0;
                        }

                        .description,
                        .action {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}

