// Component : Section News-featured
.section-news-featured {
	margin: 0 0 100px;
	padding: 64px 0 80px;
	.product-ivd &{
		background-color: #a06590!important;
	}

	.article-excerpt {
		background: #fff;
		max-width: 360px;
		margin-left: auto;
		margin-right: auto;
		&:hover{
			cursor: pointer;
			.article__image__image {
				transform: scale(1.2);
				transition: .4s;
			}
			h6, h3 {
				&:after {
					width: 0;
				}
			}
		}
	}

	.section__content {
		margin-bottom: 40px;
		& > .columns{
			margin: 0 auto;
		}
	}

	.section__title {
		margin-bottom: 40px;
	}

	p {
		font-size: 14px;
		line-height: 2;
		color: #fff;
		margin-bottom: 20px;
	}

	p + .btn {
		margin-top: 20px;
	}
	&.push-4{

		.push-4__description{
			color: black;
			margin-bottom: 30px;
		}
		.js-slider-articles{
			padding-bottom: 0;

			.slider__slide{
				margin: 0 10px;
			}
		}
		.article-excerpt.article-excerpt--small .article__content{
			height: 96px;
			min-height: 96px;
		}
		.article-excerpt.article-excerpt--small h6, .article-excerpt.article-excerpt--small h3{
			margin-top: 10px;
			text-align: center;
		}
	}
}

.section-news-featured--alt {
	overflow: hidden;
	padding: 40px 0;

	.section__head {
		margin: 0;
		display: block;
		& > .columns{

			margin: 0 auto;
		}
	}
	.section__title {
		margin-bottom: 60px;
	}
	.slider-bio-modal{
		padding: 10%;
		.mfp-close{
			right: 120px;
			margin-top: -60px;
		}
		.slider-bio{
			background-color: transparent;
		}
		.section-bio{
			padding: 0;
		}
		iframe{
			width: 100%;
			min-height: 580px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

		}
	}
}

.section-news-featured--alt2 {
	overflow: hidden;
	padding: 0 0 80px;

	.section__head {
		margin-top: 40px;
	}
}

.slider-articles {
	.js-slider-articles-2 {
		margin: 0 -15px;

		.slick-slide {
			padding: 0 15px;
		}

		.slick-dots {
			bottom: -30px;
			button {
				background: #8dced9;
				background-clip: content-box;
			}

			.slick-active {
				button {
					background: #fff;
					background-clip: content-box;
				}
			}
		}
		.article-excerpt {
			max-width: 100%;
		}
	}
}

$font-size: 13px;
$line-height: 1.69;
$lines-to-show: 2;

.article-excerpt.article-excerpt--small {
	&:hover{
		.article__image{
			transform: scale(1.2);
			transition: .4s;
		}
	}
	.article__image_container {
		overflow: hidden;
	}

	.article__image {
		height: 0;
		padding-top: 75%;
		transition: .4s;
	}
	p:not(.title) {
		height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
		margin: 0 auto;
		font-size: $font-size;
		line-height: $line-height;
		-webkit-line-clamp: $lines-to-show;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	h6, h3 {
		margin-bottom: 2px;
	}
	.article__content {
		padding: 20px;
		height: 150px;
		min-height: 150px;
	}
}

.slider-articles--alt {
	/* REMOVED FOR HTRF
	.slick-dots {
		display: none !important;
	}*/
	slider-video-modal
	.js-slider-articles {
		overflow: visible;
		padding-bottom: 40px;
	}

	.js-slider-articles .slider__slide {
		width: 290px !important;
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.section-news-featured {
		.row-reverse {
			flex-direction: row-reverse;
		}
	}

	.slider-articles {
		.js-slider-articles-2 {
			margin: 0 -15px;

			.slick-slide {
				padding: 0 15px;
			}
		}
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.section-news-featured {

		.slider-bio-modal {
			.mfp-close {
				margin-top: -100px;
			}
		}
		.article-excerpt {
			margin-left: 0;
		}

		p {
			font-size: 16px;
			line-height: 1.88
		}
		p + .btn {
			margin-top: 30px;
		}
		.slick-dots{
			display: none;
		}
	}
	.section-news-featured--alt {
		padding: 64px 0;
		.section__head {
			margin-bottom: 70px;
		}
	}

	.section-news-featured--alt2 {
		padding: 40px 0 140px;

		.section__head {
			margin-top: 0;
			margin-bottom: 64px;
		}
	}

	.slider-articles {
		.js-slider-articles-2 {
			margin: 0 -15px;

			.slick-slide {
				padding: 0 15px;
			}
		}
	}

	.slider-articles--alt {
		.js-slider-articles {
			padding-bottom: 80px;
		}
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
