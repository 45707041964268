// Component : Section About
.section-about {
	margin-top: 20px;
	margin-bottom: 40px;
	.section__body {
		margin: 0 -10px;
		padding-left: 15px;
	}
	.article__label{
		height: 24px;
		padding:5px 10px;
		color: #007286;
		font-size: 11px;
		text-transform: uppercase;
		background: white;
		border-radius: 30px;
		display: inline-block;
		position: absolute;
		top: 16px;
		left: 50%;
		transform: translate(-50%, 0);
	}
}

.js-slider-3-items {
	padding-bottom: 82px;
	overflow: hidden;

	.slick-slide {
		padding: 0 15px;
	}

	.slider__slide {
		width: 280px !important;
		max-width: calc(100vw - 70px) !important;
		box-shadow: 0 2px 20px 0 rgba(0, 114, 134, 0.15);
	}

	.slick-list {
		overflow: visible;
	}
}

.slick-dots {
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	list-style-type: none;
	z-index: 1;
	display: flex;
	align-items: center;
	margin-left: 0;

	li {
		padding: 0 5px;
		font-size: 0;
		line-height: 1;
		float: left;
	}

	button {
		border: none;
		appearance: none;
		border-radius: 0;
		background: #8b9ca0;
		height: 4px;
		padding: 1px 0;
		width: 40px;
		font-size: 0;
		line-height: 0;
		text-indent: -4004px;
		background-clip: content-box;

		&:focus {
			outline: none;
		}
	}

	.slick-active button {
		background: #007286;
		background-clip: content-box;
	}
}

.list-tags-small {
	display: flex;
	justify-content: center;
	align-items: center;
	list-style-type: none;
	margin: 0 -5px;

	li {
		padding: 0 5px 10px;
		span,
		a{
			color: $blue-teal;
			display: inline-block;
			font-size: 11px;
			text-transform: uppercase;
			line-height: 22px;
			height: 22px;
			background: #fff;
			border-radius: 11px;
			padding: 0 9px;
			margin-right: 5px;
			margin-bottom: 6px;
			float: left;
		}
	}

	&--left {
		justify-content: flex-start;
	}

	&--right {
		justify-content: flex-end;
	}

	&--dark {
		span {
			background: #007286;
			color: #fff;
		}
	}
}

.tile-callout {
	height: 286px;
	position: relative;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	overflow: hidden;
	.tile-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center center;
		transition: transform 0.3s;
	}

	.list-tags-small {
		position: absolute;
		top: 20px;
		left: 0;
		width: 100%;
		z-index: 3;
	}

	.slide__content {
		position: relative;
		z-index: 1;
	}

	&:hover {
		.tile-bg {
			transform: scale(1.1);
		}
	}

	h6, h3 {
		position: relative;
		font-size: 16px;
		line-height: 1.5;
		font-weight: 700;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		margin: 0;
		padding-bottom: 10px;

		&:after {
			position: absolute;
			bottom: 0;
			left: 50%;
			content: '';
			background: #ffca67;
			width: 40px;
			height: 3px;
			transform: translateX(-50%);
		}
	}

	.slide__content--decoration-green-riptide {
		h6:after, h3:after {
			background: $green-riptide;
		}
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.section-about {
		padding-left: 30px;
		padding-right: 30px;
	}

	.js-slider-3-items {
		padding-bottom: 64px;
		.slick-slide {
			padding: 0 10px;
		}

		.slider__slide {
			width: 181px !important;
			max-width: 181px !important;
		}

		.slick-track,
		.slick-list {
			display: flex !important;
			justify-content: center;
		}
		.slick-track {
			transform: none !important;
		}
	}

	.tile-callout {
		height: 177px;
		padding: 0 10px;
	}

	.slick-dots {
		bottom: 32px;
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.section-about {
		padding-left: 0;
		padding-right: 0;

		.slick-dots{
			display: none;
		}
	}

	.js-slider-3-items {
		padding-bottom: 80px;
		.slider__slide {
			width: 301px !important;
			max-width: 301px !important;
		}
	}

	.tile-callout {
		height: 296px;
	}
}

// JUICER
.juicer {
	margin-bottom: 100px;
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
