// Component : Section Offers Single Alt
.section-offers-single-alt {
    padding: 0 0;

    .section-news {
        overflow: visible !important;
    }

    .offer__meta {
      display: none;
    }

    .is-blog & {
        .offer__meta {
            display: block;
        }
    }

    h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 1.67;
        margin-bottom: 16px;
    }
    p {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    ul:not(.slick-dots) {
        list-style-type: none;
        height: 67px;
        overflow: hidden;
        li {
            position: relative;
            font-size: 14px;
            line-height: 2;
            padding-left: 25px;
            &:before {
                position: absolute;
                top: 10px;
                left: 0;
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #007286;

            }
            .wordpress-post-view &{
                &:before{
                    display: none;
                }
            }
        }
    }

    .section__image {
        margin-top: 40px;
        width: 100%;
        height: auto;
    }

    .section__group + .section__group {
        margin-top: 40px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-offers-single-alt {
        padding: 30px 0;
        p {
            font-size: 16px;
            line-height: 1.88;
        }

        h3 {
            font-size: 32px;
            line-height: 1.94;
            margin-bottom: 13px;
        }

        ul li {
            font-size: 16px;
        }

        .section__image {
            margin-top: 0;
        }

        .section__group + .section__group {
            margin-top: 80px;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
