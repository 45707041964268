// Component : Tabs Checkout
.tabs-checkout {
	padding: 40px 0;
	.tab {
		display: none;

		&.current {
			display: block;
		}
	}

	.tabs__head {
		margin-bottom: 40px;
	}

	.tabs__nav {
		ul {
			display: flex;
			align-items: center;
			justify-content: center;
			list-style-type: none;
		}
		li {
			padding: 0 10px;
			a {
				position: relative;
				font-size: 16px;
				font-weight: 700;
				color: #8b9ca0;
				text-transform: uppercase;
				transition: color 0.3s;
			}
			svg {
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				visibility: hidden;
				opacity: 0;
			}
		}

		.current {
			a {
				color: #12282d;
			}
			svg {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.tabs-checkout {
		padding: 80px 0 140px;
		.tabs__head {
			margin-bottom: 80px;
		}
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
	.tabs-checkout {
		.tabs__nav {
			li {
				padding: 0 32px;
			}
		}
	}
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
