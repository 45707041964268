body{
	transition: all 0.4s;
	&.fixe{
		overflow: hidden;
		height: 100%;
		max-height: calc(100vh - var(--vh-offset, 0px));

	}
	&.move{
		// transform: translateX(265px);
		// transform: translateX(calc(100vw - 60px));
		// transition: all 0.4s;
	}
}

html{
	transition: all 0.4s;
	&.fixe{
		overflow: hidden;
		height: 80vh;
	}
}

// Component : header
.cp-header {
	position: fixed;
	background: #ffffff;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	height: 70px;
	top: 0;
	left: 0;
	z-index: 20;
	transition: all 0.3s ease-in-out;

	// + section,
	// + div {
	// 	margin-top: 50px;
	// }

	width: 100%;
	a{
		&.menu-item-active{
			& > span{
				text-decoration: none;
				color: #8dced9;
			}
		}
	}
	.header__actions {
		height: 50px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 10px;

		.customer-login,
		.search-ico {
			display: none;
		}

		// cause customer-login is hidden
		.minicart-wrapper {
			margin-left: 0;
		}

		> ul {
			list-style-type: none;
			display: flex;
			align-items: center;
			margin-top: 20px;
		}

		li + li {
			margin-left: 20px;
		}

		a:not(.btn-header):not(.showcart) {
			// display: block;
			line-height: 1;
			transition: opacity 0.3s;

			&:hover {
				opacity: 0.8;
				-webkit-filter: blur(0);
			}
		}

		a:hover,
		a:focus {
			outline: none;
		}

		.minicart-wrapper {
			position: relative;

			.counter {
				width: 15px;
				height: 15px;
				background: #50b7c9;
				border-radius: 50%;
				position: absolute;
				right: -9px;
				top: -5px;
				color: #ffffff;
				text-align: center;
				line-height: 14px;
				font-size: 13px;

				&.empty {
					display: none;
				}
			}
		}
	}

	.header__inner {
		width: 100vw;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		background: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
		z-index: 99;
		padding: 40px 12px 20px;
		padding-top: 0;
		max-height: calc(100vh);
		max-height: calc(100vh - var(--vh-offset, 0px));



		&.show {
			.header__actions {
				transform: translateX(265px);
				transform: translateX(calc(100vw - 60px));
				transition: all 0s;
			}
		}

		.nav {
			position: absolute;
			border-bottom: 1px solid #f5f5f5;
			margin-bottom: 30px;
			background: white;
			margin-left: -12px;
			padding: 0 25px;
			padding-top: 6px;
			right: 100vw;
			top: 0;
			width: calc(100vw - 60px);
			height: 100vh;
			height: calc(100vh - var(--vh-offset, 0px));
			box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
			transition: 0.4s;
			left: -100vw;
			overflow: scroll;
			&.show{
				top: 0;
				left: 12px;
				position: fixed;
				transition: all 0.4s;
			}
			&.fixed-dropdown{
				.dropdown-open{
					overflow: initial;
				}
			}
			&.tab-open {
				height: 100%;
			}
			.dropdown{
				margin-left: 0;
				.accordion__section{
					a{
						padding: 0;
					}
				}
				.accordion__body{
					& >span{
						display: none;
					}
					ul{
						margin: 0;
						display: block;
						
						&.submenu {
							column-count: 2;
							column-fill: auto;
							column-gap: 1rem;
							height: 440px;
						}

						li{
							display: block;
							margin-bottom: 2px;
							a{
								font-size: 16px;
								line-height: 35px;
								&:hover{
									background: transparent;
								}
							}
						}
					}
				}
			}
			.dropdown li + li{
				border: none;
			}
		}

	}

	.block-search{
		position: absolute;
		width: 100%;
		left: -100%;
		top: 0;
		transition: 0.4s ease;
		&.visible{
			left: 0;
			transition: 0.4s ease;

		}
		input{
			background-color: #F5F5F5;
			height: 50px;
			border: none;
			padding-left: 62px;
			// background-image: url(../images/svg/ico-search.svg);
			background-position: 22px 17px;
			background-size: 18px;
			background-repeat: no-repeat;
		}
		.action.search{
			display: none;
		}
	}

	.logo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 0;
		z-index: 999;
		.fixe &{
			z-index: 0;
		}
		svg {
			height: 44px;
			width: 130px;
		}
	}

	.language {
		a {
			color: #12282d;
			font-size: 13px;
			font-weight: 600;
		}

		> a {
			display: inline-block;
			position: relative;
			padding-right: 15px;

			&:after {
				position: absolute;
				top: 50%;
				right: 0;
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 6px 4px 0 4px;
				border-color: #12282d transparent transparent transparent;
				transform: translateY(-50%);
			}
		}

		ul {
			margin-left: 0;
			list-style-type: none;
			z-index: 99;
		}
	}

	.btn-menu {
		display: block;

		span {
			display: block;
			background: #12282d;
			height: 2px;
			width: 17px;
			border-radius: 1px;
			transition: all 0.4s linear;
		}

		span + span {
			margin-top: 6px;
		}

		&.active {
			span {
				width: 22px;
			}

			span:nth-child(2) {
				background-color: transparent;
			}

			span:nth-child(1) {
				transform: rotate(45deg) translate(5px, 6px);
			}

			span:nth-child(3) {
				transform: rotate(-45deg) translate(5px, -6px);
			}
		}
	}

	.svg-ico-user {
		path {
			fill: #12282d;
		}
	}

	&.sticky {
		position: absolute;
	}

	.header__inner.sticky {
		position: absolute;
		height: 100vh;
		height: calc(100vh - var(--vh-offset, 0px));
	}
	//BLACK BIRD CUSTOM
	.accordion{
		padding-bottom: 22px;
		background: #f5f5f5;

		.level2{
			& + span{
				display: none
			}
			& ~ span{
				display: none
			}
		}
	}

	.dropdown-inner{
		display: flex;
		.dropdown__aside{
			display: none;
		}
		.tabs__body{
			.tab{
				height: 100vh;
				height: calc(100vh - var(--vh-offset, 0px));
				height: auto;
				padding-bottom: 100px;
			}
		}
	}

}

.nav-lang {
	ul {
		list-style-type: none;
	}
}

.navigation {
	ul {
		list-style-type: none;
	}

	> ul > li > a {
		text-transform: uppercase;
		line-height: 68px;
	}

	li {
		margin-top: 3px;
		overflow: hidden;
	}

	li + li {
		border-top: 1px solid #f5f5f5;
	}

	a {
		display: block;
		line-height: 43px;
		font-size: 16px;
		color: #12282d;
		font-weight: 600;
	}

	.level0.parent > a {
		color: #8dced9;
		position: relative;
	}

	.dropdown {
		/*position: relative;*/
		padding: 5px 0;
		border-top: 1px solid #f5f5f5;

		/*&:after,
		&:before {
			position: absolute;
			left: 50%;
			content: '';
			width: 100%;
			height: 1px;
			background: #f5f5f5;
			transform: translateX(-50%);
		}*/

		&:before {
			top: 0;
		}

		&:after {
			bottom: 0;
		}

		li + li {
			border-top: 1px solid #f5f5f5;
		}
	}
	/*.parent.dropdown-open {
		> a {
			background: #8dced9;
			color: #fff;
		}
		> a:after {
			border-color: #fff transparent transparent transparent;
			// transform: rotate(180deg) translateY(50%);
		}
	}*/
}
.parent {
	position: relative;
	.dropdown {
		display: none;
	}
	&.dropdown-open {
		> a {
			/*background: #8dced9;*/
			/*color: #fff;*/
			color: #8dced9;
		}
		> a:after {
			transform: rotate(180deg) translateY(50%);
		}
	}
}

.level-top.parent {
	&:first-child {
		.submenu .tabs-navigation .tabs__nav li .level-top span {
			color: $light-blue;
		}
	}
	&:nth-child(2) {
		.submenu .tabs-navigation .tabs__nav li .level-top span {
			color: $medium-blue;
		}
	}
	&:nth-child(3) {
		.submenu .tabs-navigation .tabs__nav li .level-top span {
			color: $dark-blue;
		}
	}
}

/*.level-top{
	&:nth-child(5) {
		.wordpress-term-view &,
		.wordpress-post-view & {
			background: #8dced9;
			color: #fff;
			border-radius: 16px;
          	span{
				padding: 0 15px;
				color: #fff;
			}
			a{
				color: #fff;
			}
		}
	}
}*/

.tabs-navigation {
	.tabs__nav {
		background: none;
		height: auto;
		padding: 16px 0;
		ul {
			margin-left: 0;
		}
		a {
			display: block;
			color: #12282d;
			font-size: 16px;
			font-weight: 700;
			height: 40px;
			line-height: 40px;
			background: none;
			text-align: left;

			svg {
				display: none;
			}
		}
		li{
			.level-top{
				span{
					color: #8dced9;
					font-weight: 600;
					text-transform: uppercase;
				}
			}
		}
		li + li {
			border: none;
			margin-top: 0;
		}
		.current a,
		li a:hover {
			background: none;
			color: #12282d;
		}
		.submenu__close{
			display: none;
		}
	}

	.tab {
		background: #fff;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100vh;
		height: calc(100vh - var(--vh-offset, 0px));
		width: calc(100% - 60px);
		z-index: 99;
		transform: translateX(-100%);
		transition: all 0.3s;
		padding-top: 0px;
		overflow-y: auto;

		&.current {
			transform: translateX(0);
			overflow-y: scroll !important;
			transition: transform 0.3s;
			overflow: auto;
		}

		.tab-label {
			margin-top: 32px;
			margin-bottom: 32px;
		}
	}

	.link-back {
		display: block;
		position: relative;
		color: #8b9ca0;
		font-weight: 600;
		font-size: 16px;
		line-height: 1.19;
		height: auto;
		padding: 24px 31px;
		margin: 0 10px;
		border-bottom: 1px solid #f5f5f5;

		&:before {
			position: absolute;
			top: 50%;
			left: 0;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 3.5px 7px 3.5px 0;
			border-color: transparent #8b9ca0 transparent transparent;
			transform: translateY(-50%);
		}
	}

	.tab-label,
	.tab-title{
		font-size: 16px;
		font-weight: 700;
		line-height: 1.19;
		text-transform: uppercase;
		text-align: center;

		strong {
			display: inline-block;
			position: relative;
			font-weight: 600;

			svg {
				position: absolute;
				top: 14px;
				left: 50%;
				transform: translateX(-50%);
				width: 100%;
				z-index: -1;
			}
		}
	}
	.tab-title{
		height: 84px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 90%;
		margin: 0 auto;
		text-transform: none;
		border-top: 1px solid #f5f5f5;
		strong{
			svg{
				width: auto;
			}
		}
	}
	.back-tab-title{
		position: relative;
		color: #8B9CA0;
		font-size: 16px;
		height: 84px;
		line-height: 25px;
		padding: 0 40px;
		padding-right: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		&:before{
			content: '';
			position: absolute;
			top: 50%;
			left: 20px;
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-right: 6px solid #8dced9;
			transform: translateY(-50%);
		}
		&:hover{
			cursor: pointer;
		}
	}
}


.accordion {
	.accordion__head {
		&+.accordion__body {
			display: none;
		}
	}

	.accordion__head {
		border-top: 1px solid #f5f5f5;
		padding: 19px 20px;
		padding-right: 0;
		margin: 0 10px;
		&:hover{
			cursor: pointer;
		}
		&.active{
			h3{
				&:after{
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}

		&-title {
			font-weight: bold;
		}
		
		h3 {
			position: relative;
			font-size: 16px;
			color: #12282d;
			font-weight: 700;
			margin-bottom: 0;

			&:after {
				position: absolute;
				top: 50%;
				right: 0;
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 6px 4px 0 4px;
				border-color: #12282d transparent transparent transparent;
				transform: translateY(-50%);
				transition: transform 0.3s;
			}
		}
	}

	.accordion__section {
		&.grouped{
			&:first-of-type(){
				.accordion__body{
					padding-bottom: 0;
				}
			}
			&:not(:first-child){
				.accordion__body{
					padding-top: 0;
					padding-bottom: 0;
				}
			}
		}
	}
	.accordion__expanded {
		.accordion__head h3:after {
			transform: translateY(-50%) rotate(180deg);
		}
	}

	.accordion__body {
		background: #f5f5f5;
		padding: 22px 32px;

		.list-links {
			margin-left: 0;

			li {
			}

			li + li {
				margin-top: 0;
				border-top: none;
			}

			a {
				display: block;
				font-size: 16px;
				line-height: 1.19;
				padding: 10px 0;
				font-weight: 400;
				height: auto;
			}
		}
	}
}

.wrapper {
	.wrapper__inner {
		transition: transform 0.4s;
		transform: translateX(0);
		padding-top: 50px;
		.cms-index-defaultnoroute &,
		.customer-account-login &,
		.amlocator-index-index &,
		.customer-account-createpassword &{
			padding-top: 0;
		}
	}

	&.move {
		overflow: hidden;
	}
}

.cms-index-defaultnoroute{
	.wrapper {
		.wrapper__inner {
			padding-top: 0;
		}
	}
}


.homepage, .is-blog {
	.wrapper {
		.wrapper__inner {
			padding-top: 0px;

		}
	}
}

.callout-step {
	background: #f5f5f5;
	padding: 24px 13px;
	margin: 64px -12px 0;

	h5 {
		color: #12282d;
		font-size: 14px;
		font-weight: 700;
		margin: 0;

		a {
			display: flex;
			align-items: center;
			text-decoration: none;
			color: inherit;
		}

		svg {
			margin-right: 16px;
			width: 26px;
			height: 26px;
			flex-shrink: 0;
		}
	}
}

.header__overlay {
	display: none;
}


// --TABLET
@media screen and #{breakpoint(medium up)} {
	.cp-header {
		.header__actions {
			padding: 0 15px;
		}
		.dropdown-inner{
			.tabs__body{
				.tab{
				}
			}
		}
	}

	.nav {
		.dropdown:after,
		.dropdown:before {
			display: none;
		}
	}
	.search{
		padding: 0;
	}
	.tabs-navigation{
		.accordion{
			max-height: none;
			overflow: hidden;
		}
	}
}

// --DESKTOP
@media screen and (min-width: 1280px) {
	body {
		transform: none;
	}

	.cp-header {
		padding: 27px 15px 18px;
		height: auto;
		transition: 0.4s;

		.logo {
			position: static;
			transform: none;

			svg {
				width: 160px;
				height: 50px;
				margin-right: 50px;
			}
		}
		.btn-menu-container-mobile{
			display: none!important;
		}
		.dropdown-inner{
			.tabs__body{
				.tab{
					height: 0;
					padding-bottom: 0;
				}
			}
			.dropdown__aside{
				display: block;
			}
		}

		.flex-container{
			position: relative;
		}
		.header__inner {
			padding: 10px 0 0;
			position: static;
			transform: none;
			box-shadow: none;
			width: auto;
			overflow: visible;
			.header__actions{
				position: absolute;
				right: 30px;
				padding: 0;
				top: 0;

			}
			.nav {
				position: relative;
				border: none;
				margin-bottom: 0;
				padding-bottom: 0;
				padding: 0 25px;
				right: 0;
				height: auto;
				min-height: 0;
				width: auto;
				box-shadow: none;
				transition: 0.4s;
				left: auto;
				overflow: hidden;
				.dropdown{
					.accordion__section{
						// padding-right: 60px;
					}
				}

				.parent {
					&:first-child {
						.dropdown {
							a {
								&:hover {
									color: $light-blue;
								}
							}
						}
					}
					&:nth-child(2) {
						.dropdown {
							a {
								&:hover {
									color: $medium-blue;
								}
							}
						}
					}
					&:nth-child(3) {
						.dropdown {
							a {
								&:hover {
									color: $dark-blue;
								}
							}
						}
					}
				}
				.parent .dropdown {
					margin-top: 0;
					margin-left: 0;
					padding: 0;
					position: fixed;
					z-index: 10;
					left: 0;
					top: 95px;
					background: #fff;
					border-radius: 0;
					box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
					max-height: calc(100vh - 95px);
					min-height: 660px;
					overflow: hidden;
					width: 100vw;
					a {
						font-size: 16px;
						transition: background 0.3s, color 0.3s;
						&:hover {
							color: #8dced9;
							opacity: 1;
						}
						&.btn-lightgreen-alt{
							&:hover{
								color: white;
							}
						}
					}

					li + li {
						margin-top: 0;
					}
				}
			}
			&+.header__actions{
				li{
					display: none;
				}
			}

			&.tab-open {
				height: 100%;
				overflow: visible;
			}
		}

		.block-search{
			top: -50px;
			left: -100vw;
		}


		.header__actions {
			width: auto;
			margin-left: auto;
			padding: 8px 0 0;
			.search-ico {
				display: block;
			}
		}
		.language {
			position: relative;

			ul {
				margin-top: 5px;
				margin-left: 0;
				padding: 5px;
				position: absolute;
				left: 0;
				top: 100%;
				width: 100%;
				background: #fff;
				border-radius: 5px;
				box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
			}

			li + li {
				margin-left: 0;
				margin-top: 2px;
			}
		}
	}

	.accordion {
		.accordio__head {
			padding: 16px 20px 25px;
		}

		.accordion__section {
			&.grouped {
				&:not(:first-child) {
					.accordion__body {
						padding-bottom: 0;
					}
				}
			}
		}
	}

	.nav {
		padding-left: 20px;

		&:first-child{
			padding-left: 0;
		}
		li:not(.parent) > a:hover {
			opacity: 0.8;
		}

		ul {
			display: flex;
			align-items: center;
		}

		> ul > li + li {
			margin-left: 20px;
			border-top: none;
		}

		> ul > li > a {
			height: 31px;
			line-height: 31px;
			padding: 0;
			font-size: 13px;
		}

		.level0.parent > a {
			border-radius: 16px;
			padding: 0 35px 0 12px;
			background: #f5f5f5;

			&:after {
				right: 12px;
			}
		}

		.level0.parent.menu-item-active {
			> a {
				background: #8dced9;
				color: #fff;
				border-radius: 16px;
				span{
					color: #fff;
					padding: 0;
				}
			}

			> a:after {
				border-color: #fff transparent transparent transparent;
				// transform: rotate(180deg) translateY(50%);
			}
		}

		.level0.menu-item-active {
			> a {
				background: #8dced9;
				color: #fff;
				border-radius: 16px;
				span{
					color: #fff;
					padding: 0 12px;
				}
			}
		}

		.parent {
			position: relative;
			&:before{
				content: '';
				position: absolute;
				top: 50%;
				right: 15px;
				border-top: 4px solid transparent;
				border-bottom: 4px solid transparent;
				border-right: 6px solid #8dced9;
				transform: translateY(-50%) rotate(-90deg);
				z-index: 99;
				pointer-events: none;
			}
		}

		.parent.menu-item-active,
		.parent.dropdown-open {
			&:before{
				content: '';
				position: absolute;
				top: 50%;
				right: 15px;
				border-top: 4px solid transparent;
				border-bottom: 4px solid transparent;
				border-right: 6px solid #FFF;
				transform: translateY(-50%) rotate(-90deg);
				z-index: 99;
				pointer-events: none;
			}

			&:first-child {
				> a {
					background-color: $light-blue;
				}
			}
			&:nth-child(2) {
				> a {
					background-color: $medium-blue;
				}
			}
			&:nth-child(3) {
				> a {
					background-color: $dark-blue;
				}
			}
			> a {
				// background: #8dced9;
				color: #fff;
			}
			> a:after {
				border-color: #fff transparent transparent transparent;
				transform: rotate(180deg) translateY(50%);
			}
		}

		.parent {
			// &.dropdown-open {
			// 	.dropdown {
			// 	}
			// }

			.dropdown__inner {
				display: flex !important;
				box-shadow: none;
				box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
			}
			.dropdown__content {
				flex-grow: 1;
				display: flex;
				flex-direction: column;

				.callout-step {
					margin-top: auto;
					margin-left: 0;
					margin-right: 0;
				}
			}
			.submenu{
				&.ls-nav{
					.submenu__close {
						display: none;
					}
					.dropdown__aside {
						display: block;
						.submenu__close {
							display: block;
						}
					}
				}
				&.ivd-nav{
					.tabs__nav {
						li{
							&.current{
								a{
									svg {
										use {
											fill: #A06590;
										}
									}
								}
							}
						}
					}
				}
			}

			.cisbio{
				.tabs__nav {
					li{
						&.current{
							a{
								svg {
									use {
										fill: #ffca67;
									}
								}
							}
						}
					}
				}
			}
			.submenu__close {
				position: absolute;
				right: 40px;
				top: 20px;
				color: black;
				cursor: pointer;
				display: block;
				transition: .3s ease;
				&:hover {
					opacity: .9;
				}
			}
			.dropdown__aside {
				width: 350px;
				flex-shrink: 0;
				background: #8dced9;
				padding: 78px 55px 20px;
				height: 660px;
				position: relative;
				display: none;

				.title {
					color: #ffffff;
					font-size: 24px;
					line-height: 36px;
				}

				.slogan {
					font-size: 24px;
					line-height: 36px;
					font-weight: 700;
					color: #fff;
					margin: 30px 0;
					column-count: 2;
					display: inline-block;
				}

				p {
					font-size: 16px;
					line-height: 1.88;
					margin-bottom: 40px;
					color: #fff;
				}
				.btn {
					height: 57px;
					line-height: 57px;
					width: auto;
					display: inline-block;
					font-size: 16px;
					color: #8dced9;
					min-width: 224px;
					position: relative;
					font-weight: 700;
					z-index: 2;
				}
				.image-decoration {
					position: absolute;
					bottom: 0;
					right: 0;
					max-width: 200px;
					z-index: 1;
				}
				.submenu__close {
					color: $white;
					&:hover {
						opacity: .9;
					}
				}
			}

			.dropdown-logo {
				display: inline-block;
				margin-bottom: 32px;
				height: auto !important;
				line-height: 1;
				&:hover{
					background: none;
				}
			}
		}

		.callout-step {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 24px 20px 24px 20px;

			h5 {
				font-size: 16px;
			}

			h5 > a {
				padding-left: 0;
				padding-right: 0;
				font-size: 16px !important;
			}

			h5 svg {
				width: 33px;
				height: 33px;
				margin-right: 24px;
			}

			.btn {
				height: 57px !important;
				line-height: 53px !important;
				display: inline-block;
				font-size: 16px !important;
				font-weight: 700;
				border: 2px solid #8dced9;
				color: #8dced9;
				min-width: 242px;
				font-size: 16px !important;
			}
		}
		.parent .callout-step h5 a {
			padding-left: 0;
			padding-right: 0;
		}
	}


	.tabs-navigation {
		padding-left: 20px;
		padding-right: 20px;
		.tab-label{
			strong{
				font-weight: bold;
				text-transform: uppercase;
			}
		}
		.tabs__nav {
			padding-top: 37px;
			padding-bottom: 32px;
			border-bottom: 1px solid #f5f5f5;
			a {
				color: #8b9ca0;
				min-width: 0;
				font-size: 16px !important;
				height: 51px;
				line-height: 51px;
				svg {
					left: 50%;
					top: 30px;
					transform: translateX(-50%);
				}
			}

			li.current {
				a {
					color: #12282d;
					svg {
						display: block;
					}
				}
			}

			li a:hover {
				background: none !important;
				color: #12282d !important;
			}

			li {
				width: auto;
				&:first-of-type(){
					a{
						padding-left: 0;
						padding-right: 34px;
					}
				}
			}
		}

		.tab {
			position: static;
			transform: none;
			height: 0;
			width: 265px;
			overflow: hidden;
			width: 100%;
			padding: 0;
			&.flex{
				.accordion{
					display: flex;
					display: flex;
					justify-content: left;
					overflow: auto;
				}
			}
			&.current {
				position: static;
				transform: none;
				overflow: visible !important;
			}
		}
		.tabs__body {
			padding: 20px 0;
		}
		.accordion {
			background: none;
			padding-bottom: 0;
			padding-top: 17px;
			column-count: 3;
			.accordion__body {
				display: block !important;
				background: none;
				padding: 0;
			}

			.accordion__head {
				pointer-events: none;
				margin: 0;
				margin-bottom: 18px;
				padding: 0;
				border: none;

				h3:after {
					display: none;
				}
			}

			.list-links {
				display: block;

				li a {
					font-size: 16px;
					line-height: 1;
					padding: 8px 0;
					height: auto;

					&:hover {
						background: none;
						color: #12282d;
						opacity: 0.7;
					}
				}
			}
		}
	}

	.cp-header .header__actions .profile-link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: #8dced9;

		svg {
			path {
				fill: #fff;
			}
		}
	}

	.cp-header .language ul {
		width: 40px;
		padding: 5px 10px;
	}
	.cp-header .language a {
		line-height: 1.3;
	}

	.header__overlay {
		&.visible {
			width: 100vw;
			height: 100vh;
			display: block;
			left: 0;
			top: 100px;
			position: fixed;
			z-index: -1;
		}
	}
}
// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
	.nav {
		padding-left: 57px;
		&:first-child{
			padding-left: 0;
		}
	}

	.dropdown:after,
	.dropdown:before {
		display: none;
	}
	.nav .parent .dropdown__aside {
		width: 487px;

		.image-decoration {
			max-width: 322px;
		}
	}

	.nav .callout-step {
		padding: 24px 64px 24px 50px;
		padding-left: calc(50vw - 37.5rem + 10px);
	}

	.tabs-navigation {
		padding-left: calc(50vw - 37.5rem + 10px);
		padding-right: 80px;
	}
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
