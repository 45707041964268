// Component : Services Slider
.section-services {
	margin: 40px 0 60px;
	padding-top: 50px;
	&.decoration-white{
		background-color: $white;
		.slider-applications{
			.application .application__title{
				color: $black;
			}
		}
	}
	&.decoration-yellow{
		background-color: $yellow;
	}
	&.decoration-coral{
		background-color: $light-coral;
	}
	&.decoration-violet{
		background-color: $violet;
	}
	.section__body {
		margin: 0 -10px;
		overflow: hidden;
	}
	.js-slider-services .slider__slide{
		margin-bottom: 50px;
		float: left;
		img{
			width: auto;
		}
	}
	&.background-illustration{
		padding: 70px 0;
		background: #A06590;
		position: relative;
		margin-top: 160px;
		.service{
			p{
				color: white!important;
			}
		}
		.bi-character{

			.hands{
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: -10px;
			}
			.head{
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: -84px;
				z-index: -1;
				transition: .4s;
			}
			&.mobile{
				.head{
					top: -96px;
				}
			}
		}
	}
	&.section__title--decoration-white{
		background: #fff;
	}
	&.section__title--decoration-yellow{
		background: #ffca67;
	}
	&.section__title--decoration-coral{
		background: #f08576;
	}
	.slider-default{
		overflow: hidden;
	}
	.section__foot{
		.btn{
			margin-bottom: 50px;
		}
	}
}

.js-slider-services {
	padding-bottom: 82px;
	overflow: visible;

	.slick-slide {
		padding: 0 33px;

		img{
			width: 100%;
		}
	}

	.slider__slide {
		width: 100%;
		&__title{
			font-size: 24px;
		}
	}

	.slick-list {
		overflow: visible;
	}
}

.service {
	text-align: center;

	.service__image {
		display: flex;
		min-height: 116px;
		align-items: flex-end;
		justify-content: center;
		margin-bottom: 24px;
	}

	h3 {
		font-size: 14px;
		line-height: 1.5;
		color: #12282d;
	}
	.slider__slide__title{
		font-weight: 700;
		margin-bottom: 8px;
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.section-services {

		.section__head {
			margin-bottom: 55px;
		}
		.section__body {
			padding-left: 0;
			padding-right: 0;
		}
		.js-slider-services{
			padding-bottom: 0;

			.slider__slide{
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
	.js-slider-services {
		padding-bottom: 64px;
		margin: 0 auto;

		.slick-slide {
			padding: 0 10px;
		}

		.slider__slide {
			margin: 0 auto;
		}
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.section-services {
		.js-slider-services{
			max-width: 1180px;
			.slider__slide{
				margin-bottom: 50px;
				img{
					width: auto;
				}
			}
		}
		.slick-dots{
			display: none;
		}
		&.background-illustration{
			&:hover{
				.bi-character{
					.head{
						top: -122px;
						transition: .4s;
					}
				}
			}
		}
	}

	.js-slider-services {
		padding-bottom: 80px;

		.slick-slide {
			padding: 0 40px;
			position: relative;
			img{
				width: auto;
				max-width: 65%;
			}
		}

		.slider__slide + .slider__slide:before {
			position: absolute;
			top: 110px;
			left: 0;
			content: '';
			background: url(../images/svg/line-separator.svg) 0 0 no-repeat;
			width: 48px;
			height: 159px;
			background-size: cover;
			transform: translate(-50%, -50%);
		}

		.slick-track,
		.slick-list {
			display: flex !important;
			justify-content: center;
		}
		.slick-track {
			transform: none !important;
		}
	}

	.service {
		.slider__slide__title{
			font-weight: 700;
		}
		h3 {
			font-size: 16px;
			max-width: 75%;
			margin: 0 auto;
		}

		.service__image {
			margin-bottom: 36px;
		}
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
