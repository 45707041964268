// Component : Section Video Articles
.section-video-articles {
    padding: 64px 0;

    .section__title {
        font-weight: 700;
        margin-bottom: 32px;
        font-size: 24px;
    }

    .section__head {
        margin-bottom: 40px;
        p {
            color: #fff;
            font-size: 14px;
            line-height: 2;
            margin-bottom: 0;
        }
    }

    .section__body {
        margin: 0 -10px;
    }

    .slider-video-article {
        margin-top: 0;
    }

    .slider-video-article .slider__slides {
        padding-bottom: 0;
    }
    .slider-video-article .slick-dots {
        display: none !important;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-video-articles {
        padding: 80px 0;

        .section__head {
            margin-bottom: 80px;

            p {
                font-size: 16px;
            }
        }

        .section__title {
            font-size: 40px;
            margin-bottom: 24px;
        }

        .slider-video-article {
            padding-bottom: 0;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-video-articles {
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-video-articles {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-video-articles {
    }
}
