.messages.callout {
  display: none;
  background-color: #c6524c;
  color: #fff;

  & .close-button {
    right: 2rem;
    color: #fff;
  }

  & .message {
    display: inline-table;
    width: 100%;
    height: 100%;

    & .message-content {
      display: table-cell;
      text-align: center;
      vertical-align: middle;

      em {
        font-weight: bold;
      }

      a {
        color: #fefefe;
        background: hsla(3, 52%, 64%, 1);
        padding: 4px 12px;
        border-radius: 100px;
        margin: 0 0.4em;
        font-weight: 600;
        transition: .3s;
      }

      a:hover {
        color: #c6524c;
        background-color: white;
        transition: .3s;
      }
    }

    & .close-button {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      width: 100%;
      height: 100%;
      padding-left: 15px;
      position: inherit;
    }
  }
}