// Component : Form Contact

.form-container {

}
.spontaneous{
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    padding-top: 60px;
    z-index: 9;
    .form-title{
        color: black;
        margin-bottom: 40px;
    }
    .form-container{
        position: relative;
        display: block;
        overflow: auto;
    }
    .form-contact{
        position: relative;
    }
    .icon-close{
        position: absolute;
        top: -10px;
        font-size: 20px;
        right: -10px;
        color: #007286;
        &:hover{
            cursor: pointer;
        }
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .form-container {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 90px;
        fieldset.form-columns-1 .hs-input {
            width: 100%;
        }
    }
    .spontaneous{
        padding-top: 90px;
        .form-title{
            color: black;
            margin-bottom: 60px;
        }
        .icon-close{
            position: absolute;
            right: -90px;
            top: -20px;
            font-size: 30px;
            color: #007286;
        }
        .form-container{
            position: relative;
            display: block;
            overflow: auto;
            height: 100%;
        }
        .hbspt-form .hs-form .field.hs-fieldtype-file{
            max-width: 100%;
        }
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .form-container {

    }
    .spontaneous{
      padding-top: 95px;
        .form-container{
            padding-top: 40px;
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}


//HUBSPOT FORM STYLE
.hs-form{
    fieldset.form-columns-1 .hs-input{
        width: 100%!important;
    }
    a{
        color: #007286;
    }
    .field{
        position: relative;
        overflow: hidden;
        min-height: 73px;

        label{
            display: block;
            font-size: 14px;
            color: #8b9ca0;
            line-height: 1.14;
            margin-bottom: 0;
            padding-left: 8px;
            font-weight: normal!important;
        }
        input{
            font-size: 16px;
            font-weight: 500;
            color: #12282d;
            box-shadow: none;
            border: none;
            background: none;
            border-bottom: 1px solid #e5e5e5;
            height: 48px;
            line-height: 48px;
            padding: 0 20px 0 8px;
            margin-bottom: 0;
            &::placeholder {
                color: $black;
            }
            &.error{
                box-shadow: none;
                border: none;
            }
        }
        textarea {
            font-size: 16px;
            font-weight: 700;
            color: #12282d;
            box-shadow: none;
            border: none;
            background: #f4f4f4;
            display: block;
            padding: 24px 24px;
            height: 240px;
            resize: none;
        }
        select{
            background-color: #f5f5f5;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.14;
            padding: 15px 30px 15px 14px;
            color: #12282d;
            height: auto;
            border-radius: 24px;
            border: none;
            width: 100%!important;
            max-width: 550px;
        }
        select,
        textarea,
        input{
            &:focus{
                text-shadow: none;
                border: 0 solid transparent;
                box-shadow: none;
            }
        }
        select,
        input{
            &:focus{
                border-bottom: 1px solid #e5e5e5;
            }
        }
    }
    .hs_submit.hs-submit{
        .actions{
            padding: 0;
            margin: 0;
        }
        input[type='submit']{
            background: #007286;
            color: #fff;
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            font-size: 16px;
            font-weight: 700;
            padding: 0;
            width: 100%;
            transition: background 0.3s, color 0.3s;
            line-height: 57px;
            height: 57px;
            border-radius: 29px;
            border: none;
            &:hover{
                background: #0b97af;
                color: #fff;
                box-shadow: none;
                text-shadow: none;
            }
        }
    }
}

@media screen and #{breakpoint(medium up)} {
    .hs-form {
        .hs_submit.hs-submit {
            input[type='submit']{
                padding: 0 48px;
                min-width: 179px;
                width: auto;
            }
        }
    }

}
