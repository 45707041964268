// Component : Section Distribution
.section-distribution {
    .section__head {
        margin: 80px 0 42px;

        p {
            color: #12282d;
            font-size: 14px;
            font-weight: 400;
            line-height: 2;
        }
    }

    .section__title {
        margin-bottom: 32px;
    }

    .section-map {
        overflow: hidden;
        .googlemap {
            width: 100%;
            height: 374px;
        }
    }
    .section-map__actions {
        padding: 42px 0 80px;
        display: flex;
        justify-content: center;

        .btn {
            padding: 0 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 294px;

            span.icon {
                font-size: 0;
                line-height: 1;
            }

            span + span {
                margin-left: 8px;
            }
        }
    }
}

.search-map {
    .search__field {
        height: 47px;
        line-height: 47px;
    }
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
    .section-distribution {
    }
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
    .section-distribution {
        .section__head {
            margin: 0 0 64px;

            p {
                font-size: 16px;
                line-height: 1.88;
            }
        }

        .section__title {
            margin-bottom: 40px;
        }
        .section__body {
            position: relative;
        }

        .section-map {
            .googlemap {
                z-index: 1;
            }
        }
        .section-map__actions {
            padding: 0;
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 773px;
            width: 100%;

            .btn {
                line-height: 100px;
                height: 100px;
                max-width: 773px;
                font-size: 28px;
                border-radius: 50px;

                span.icon {
                    font-size: 0;
                    line-height: 1;
                    svg {
                        width: 37px;
                        height: 50px;
                    }
                }

                span + span {
                    margin-left: 20px;
                }
            }
        }
    }
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
    .section-distribution {
    }
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
    .section-distribution {
    }
}
