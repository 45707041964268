.hubspot-title-container {
  h1 {
    color: #12282D;
    font-family: "Raleway", sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 64px;
    text-align: center;
    margin-bottom: 80px;
  }
}

.hubspot-quote-form {
  .submitted-message {
    text-align: center;
  }
}

#hubspot-quote-form {
  margin-bottom: 80px;

  fieldset {
    max-width: 100%;
  }

  input[type=text], input[type=tel], input[type=email], input[type=number], select {
    width: 430px !important;
  }

  .hs-form .field input.error {
    border-bottom: 1px solid #e5e5e5;
  }

  .hs-form .hs_submit {
    text-align: center;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.hubspot-quote-form {

  .quote-summary {
    background-color: #f5f5f5;
    padding: 40px;
    margin-bottom: 25px;
    width: 100%;
  }

  .quote-summary h3 {
    color: #12282D;
    font-family: "Raleway", sans-serif;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .items-counter {
    color: #12282D;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 30px;
  }

  .items-container {
    overflow: hidden;

    & .hidden-item {
      display: none;
      &.show-item {
        display: list-item;
      }
    }

    &.open {
      overflow: auto;

      -webkit-animation: animationOpen 0.5s normal forwards ease;
      -moz-animation: animationOpen 0.5s normal forwards ease;
      animation: animationOpen 0.5s normal forwards ease;
      -webkit-transform-origin: 100% 0;
      -moz-transform-origin: 100% 0;
      transform-origin: 100% 0;
    }
  }

  .item-container {
    display: table;
    width: 100%;

    + .item-container {
      margin-top: 25px;
    }
  }

  .item-img-container {
    display: table-cell;
    height: 90px;
    width: 90px;
    text-align: center;
    vertical-align: middle;

    & > img {
      height: 100%;
    }
  }

  .item-informations-container {
    display: table-cell;
  }

  .item-information-sub-container {
    list-style: none;
    padding-left: 15px;

    li {
      font-weight: bold;
    }

    .references {
      font-size: 14px;
      font-weight: 600;
      color: #12282d;
    }

    .qty,
    .price
    {
      color: #12282d;
    }

    .title strong {
      color: #007286;
      word-break: break-word;
    }

    .qty-label,
    .price-label
    {
      color: #8b9ca0;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .display-more-cta {
    margin-top: 25px;
    text-align: center;
  }
}

@-webkit-keyframes animationOpen {
  from { transform: scaleY(0) }
  to { transform: scaleY(1) }
}
@-moz-keyframes animationOpen {
  from { transform: scaleY(0) }
  to { transform: scaleY(1) }
}
@keyframes animationOpen {
  from { transform: scaleY(0) }
  to { transform: scaleY(1) }
}

.force-refresh {
 color: red;
}
