// Component : Section Order Information
.section-order-information {
	h1 {
		font-size: 36px;
		line-height: 1.33;
		margin-bottom: 0;
		font-weight: 700;
		color: #12282d;
	}
	.section__head {
		margin-bottom: 7px;
	}
}

.section-order-information--not-in-tabs {
	padding: 40px 0;
}

.shipping-infromation {
	h3 {
		font-size: 24px;

		margin-bottom: 20px;
		font-weight: 700;
	}
	h5 {
		font-size: 14px;
		line-height: 1.14;
		color: #8b9ca0;

		margin-bottom: 20px;
	}

	.shipping-infromation-foot {
		padding-top: 64px;
		padding-top: 0;
	}
}

.shipping-address {
	p {
		font-size: 14px;
		font-weight: 700;
		line-height: 2;
		color: #12282d;
		margin-bottom: 20px;
	}
	p + .btn {
		margin-top: 0;
		margin-bottom: 20px;
	}
	.btn {
		padding: 0 10px;
		min-width: 200px;
	}

	&:not(.active) {
		p {
			color: #8b9ca0;
		}
	}
}

.order-sidebar {
	padding-top: 20px;
}

.order-box {
	background: #f5f5f5;
	padding: 20px 10px;

	h3 {
		text-align: center;
		font-size: 24px;
		font-weight: 700;
		margin-bottom: 15px;
	}

	&--total {
		background: #007286;
	}
	&--callout {
		background: #007286;
		color: #fff;

		padding: 40px;
		text-align: center;

		h4 {
			font-size: 28px;
			line-height: 1.18;
			margin-bottom: 16px;
			font-weight: 700;
		}

		p {
			font-size: 16px;
			line-height: 1.88;
			margin-bottom: 32px;
		}
	}
}

.summary-box {
	.price-total {
		margin-top: 20px;
		border-top: 1px solid #e5e5e5;
		padding-top: 20px;
		padding-bottom: 20px;
		color: #12282d;

		span {
			color: #12282d;
		}
	}

	.summary-box__foot {
		text-align: center;
	}
}

.price-total {
	display: flex;
	justify-content: space-between;

	span {
		font-size: 16px;
		line-height: 1.19;
		font-weight: 700;
		color: #fff;
	}
}

.order-box + .order-box {
	margin-top: 24px;
}

.accordion.accordion-order {
	background: none;
	.accordion__head {
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		position: relative;
		margin-bottom: 20px;

		h6 {
			font-size: 16px;
			font-weight: 700;
			padding-right: 30px;
		}

		&:after {
			position: absolute;
			top: 50%;
			right: 0;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 7px 5px 0 5px;
			border-color: #12282d transparent transparent transparent;
			transform: translateY(-50%);
			transition: transform 0.3s;
		}
	}

	.accordion__expanded .accordion__head:after {
		transform: rotate(180deg) translateY(50%);
	}

	.accordion__body {
		padding: 0;
		background: none;
	}
}

.ship-address-box {
	background: #f4f4f4;
	padding: 10px 10px;
	/*padding: 32px;*/

	h4 {
		font-size: 22px;
		line-height: 1.18;
		font-weight: 700;
	}
	p {
		font-size: 16px;
		line-height: 2;
		font-weight: 700;
		margin-bottom: 0;
	}

	.title {
		position: relative;
		margin-bottom: 20px;
		padding-right: 40px;
	}

	.title .btn-edit {
		position: absolute;
		right: 0;
		top: 0;

		svg path {
			transition: fill 0.3s;
		}
	}
	.title .btn-edit:hover {
		svg path {
			fill: #12282d;
		}
	}
}

.payment-options {
	margin-bottom: 40px;
	.radio {
		margin-bottom: 20px;
	}

	[type='radio']:not(:checked) + label + .ship-address {
		display: none;
	}

	[type='radio']:checked + label + .ship-address {
		display: block;
	}
	[type='radio']:checked + label,
	[type='radio']:not(:checked) + label {
		padding-left: 30px;
	}
}

.ship-address {
	margin-top: 20px;
	padding-left: 20px;

	[type='checkbox']:checked + label,
	[type='checkbox']:not(:checked) + label,
	p {
		padding-left: 30px;
	}

	[type='checkbox']:checked + label,
	[type='checkbox']:not(:checked) + label {
		margin-bottom: 15px;
	}

	p {
		font-size: 14px;
		color: #12282d;
		line-height: 2;
		margin-bottom: 0;
	}

	.place-order__actions {
		text-align: center;
		padding-top: 20px;
	}
}

.product-cart {
	h4 {
		font-size: 16px;
		line-height: 1.88;
		font-weight: 400;
		color: #12282d;
		margin: 0 0 6px;
	}

	h5 {
		font-size: 14px;
		line-height: 1.14;
		font-weight: 600;
		color: #007286;
		margin: 0 0 10px;
	}

	.product__quantity {
		display: block;
		color: #12282d;
		font-size: 14px;
		line-height: 1.14;
		font-weight: 400;
		margin-bottom: 10px;
	}

	.product__price {
		display: block;
		font-size: 18px;
		line-height: 1.17;
		font-weight: 700;
	}

	.product__image {
		margin-bottom: 15px;

		img {
			width: 100%;
			height: auto;
		}
	}
	.product__content {
	}

	&--alt {
		h4 {
			font-weight: 700;
		}

		h5 {
			color: #8b9ca0;
			font-weight: 400;
		}
		.product__content {
			width: 100%;
			padding-left: 0;
		}
		.product__price {
		}
	}
}

.product-cart-actions {
	li {
		padding: 0 5px;
	}
	ul {
		display: flex;
		align-items: center;
		list-style-type: none;
		margin: 0 -5px;
	}
}

.product-cart + .product-cart {
	margin-top: 20px;
}

.table-products {
	th {
		display: none;
	}

	tr {
		display: block;
	}

	tr + tr {
		margin-top: 10px;
	}
	td {
		width: 100%;
		padding: 0;
		display: block;
	}

	td + td {
		margin-top: 10px;
	}

	td {
		position: relative;
	}

	.field {
		text-align: center;
		font-weight: 700;
		color: #12282d;
		font-size: 16px;
	}

	.product-price {
		font-size: 18px;
		font-weight: 700;
	}

	td[data-title] {
		&:before {
			display: block;
			content: attr(data-title);
			font-size: 16px;
			margin-bottom: 5px;
			font-weight: 700;
			text-align: left;
		}
	}

	table tbody tr:nth-child(even) {
		background: none;
	}

	table tbody,
	table tfoot,
	table thead {
		border: none;
	}
}

// --TABLET
@media screen and #{breakpoint(medium up)} {
	.section-order-information {
		padding: 120px 0 140px;
		h1 {
			font-size: 48px;
		}
		.section__head {
			margin-bottom: 7px;
		}

		.section-actions {
			text-align: right;
		}
	}
	.product-cart {
		display: flex;
		align-items: center;
		position: relative;

		h4 {
			margin: 0 0 7px;
		}

		h5 {
			margin: 0 0 18px;
		}

		.product__quantity {
			margin-bottom: 0;
		}

		.product__price {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		.product__image {
			flex-shrink: 0;
			width: 94px;
			margin-bottom: 0;
		}
		.product__content {
			flex-grow: 1;
			padding: 0 45px 0 10px;
		}

		&--alt {
			h5 {
				margin-bottom: 0;
			}

			.product__content {
				padding-left: 0;
			}
		}
	}

	.accordion-order {
		.accordion__body {
			padding: 0;
			background: none;
		}
	}

	.payment-options {
		margin-bottom: 80px;
		.radio {
			margin-bottom: 32px;
		}

		[type='radio']:not(:checked) + label + .ship-address {
			display: none;
		}

		[type='radio']:checked + label + .ship-address {
			display: block;
		}

		[type='radio']:checked + label,
		[type='radio']:not(:checked) + label {
			padding-left: 49px;
		}
	}

	.ship-address {
		margin-top: 32px;
		padding-left: 50px;

		[type='checkbox']:checked + label,
		[type='checkbox']:not(:checked) + label,
		p {
			padding-left: 50px;
		}

		[type='checkbox']:checked + label,
		[type='checkbox']:not(:checked) + label {
			margin-bottom: 18px;
		}
		p {
			font-size: 16px;
		}

		.place-order__actions {
			text-align: right;
			padding-top: 40px;
		}
	}

	.ship-address-box {
		padding: 32px;

		h4 {
			font-size: 28px;
		}
		p {
			font-size: 16px;
		}

		.title {
			margin-bottom: 40px;
			padding-right: 40px;
		}
	}

	.summary-box {
		.price-total {
			margin-top: 40px;
			border-top: 1px solid #e5e5e5;
			padding-top: 40px;
			padding-bottom: 40px;
			color: #12282d;

			span {
				color: #12282d;
			}
		}
		.summary-box__foot {
			text-align: center;
		}
	}

	.table-products {
		padding-top: 33px;
	}

	.table-products {
		th {
			display: table-cell;
		}

		tr {
			display: table-row;
		}

		tr + tr {
			margin-top: 0;
		}

		td {
			padding: 0 5px 64px 0;
			display: table-cell;
			vertical-align: top;
		}

		td + td {
			margin-top: 0;
		}

		td {
			position: relative;
		}

		th {
			text-align: left;
			color: #8b9ca0;
			font-weight: 400;
			font-size: 14px;
			line-height: 1.14;
			padding: 0 5px 20px 0;
		}

		td[data-title='Subtotal'] {
			padding-right: 10px;
		}

		.field {
			text-align: center;
			width: 50px;
			margin-right: 10px;
		}

		td[data-title] {
			&:before {
				display: none;
			}
		}

		.product-cart .product__price {
			position: static;
			transform: none;
		}

		.product-cart .product__content {
			padding-right: 20px;
		}

		.product-cart .product__image {
			width: 120px;
		}
	}
}

// --DESKTOP
@media screen and #{breakpoint(large up)} {
	.accordion-order {
		.accordion__body {
			padding: 0;
			background: none;
		}
	}

	.accordion.accordion-order .accordion__body {
		padding-top: 20px;
	}
	.product-cart + .product-cart {
		margin-top: 40px;
	}

	.shipping-infromation {
		h3 {
			font-size: 32px;
			margin-bottom: 40px;
		}

		h5 {
			margin-bottom: 37px;
		}

		.shipping-infromation-foot {
			padding-top: 64px;
		}
	}

	.shipping-address {
		p {
			font-size: 16px;
		}
		p + .btn {
			margin-top: 20px;
			margin-bottom: 0;
		}
	}

	.order-sidebar {
		padding-top: 0;
	}
	.order-box {
		padding: 40px;

		h3 {
			margin-bottom: 32px;
			font-size: 28px;
		}

		&--taxes {
			padding: 32px 40px;
		}
	}

	.table-products {
		td {
			padding: 0 5px 64px 0;
		}

		th {
			padding: 0 5px 40px 0;
		}

		td[data-title='Subtotal'] {
			padding-right: 50px;
		}

		.field {
			text-align: center;
			width: 50px;
			margin-right: 50px;
		}
		.product-cart {
			align-items: flex-start;
		}

		.product-cart .product__price {
			position: static;
			transform: none;
		}

		.product-cart .product__content {
			padding-left: 32px;
		}
		.product-cart .product__image {
			width: 140px;
		}

		.product-cart-actions li {
			padding: 0 10px;
		}
	}

	.payment-options {
		.radio {
			margin-bottom: 52px;
		}
	}
	.ship-address .place-order__actions {
		padding-bottom: 12px;
	}
}

// --XL DESKTOP
@media screen and #{breakpoint(xlarge up)} {
}

// --XXL DESKTOP
@media screen and #{breakpoint(xxlarge up)} {
}
